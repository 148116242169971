import "./styles.css";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useNavigate } from "react-router-dom";
import userSessionStorage from "../../utils/userSessionStorage";
import { useMemo, useState, useEffect, useRef, useCallback } from "react";
import { LiaGlobeAmericasSolid } from "react-icons/lia";
import { RxDividerVertical } from "react-icons/rx";
import { BiSolidHelpCircle } from "react-icons/bi";
import { GoPasskeyFill } from "react-icons/go";
import { IoMdNotifications, IoMdNotificationsOff } from "react-icons/io";
import logoMosaic from "../../assets/brand/logo2.png";
import fotoPadrao from "../../assets/images/userFoto.png";
import Api, { linkBackend } from "../../config/api";

function formatarData(dataString: string) {
    const data = new Date(dataString);

    const dia = String(data.getUTCDate()).padStart(2, "0");
    const mes = String(data.getUTCMonth() + 1).padStart(2, "0");
    const ano = data.getUTCFullYear();

    return `${dia}/${mes}/${ano}`;
}

function Cabecalho() {
    const [fotoSelecionada, setFotoSelecionada] = useState(null);
    const [fotoCadastrada, setFotoCadastrada] = useState<string>("");
    const [portalLogado, setPortalLogado] = useState("");
    const [showNotifications, setShowNotifications] = useState(false);
    const [atendimentos, setAtendimentos] = useState([]);
    const [language, setLanguage] = useState<string>("");

    const [notificacoes, setNotificacoes] = useState<any[]>([]);

    const [NotificacoesBanco, setNotificacoesBanco] = useState<any[]>([]);

    const [notifiVisualizada, setNotifiVisualizada] = useState<boolean>(false);

    const userLogged = useMemo(userSessionStorage, []);

    const handleNotificationClick = () => {
        setShowNotifications(!showNotifications);
        setNotifiVisualizada(true);
    };

    useEffect(() => {
        const portal = sessionStorage.getItem("portalLogado");
        if (portal) setPortalLogado(portal);
    }, []);

    let userStorage = sessionStorage.getItem("loginUsuario") as string;
    let user = JSON.parse(userStorage);

    const tokenUsuario = sessionStorage.getItem("tokenUsuario");
    let config = { headers: { authorization: tokenUsuario } };

    const navegar = useNavigate();

    const { t, i18n } = useTranslation();

    const changeLanguage = (lng: any) => {
        i18n.changeLanguage(lng);
    };

    const getApiAtendimentos = async () => {
        const AtendimentosUser = await Api.GetAtendimentosByName(
            user.nome,
            config
        );
        if (AtendimentosUser) {
            setAtendimentos(AtendimentosUser);
        }

        const NotificacoesAtendimentosUser =
            await Api.getNotificacoesAtendimentos(user.id);

        if (NotificacoesAtendimentosUser) {
            setNotificacoesBanco(NotificacoesAtendimentosUser);
        }
    };

    useEffect(() => {
        getApiAtendimentos();
    }, []);

    useEffect(() => {
        const uniqueNotificationsPrioritario = new Set(
            notificacoes
                .filter((atendimento) => atendimento.tipo === "prioritario")
                .map((atendimento) => atendimento.id)
        );

        const uniqueNotificationsPadrao = new Set(
            notificacoes
                .filter((atendimento) => atendimento.tipo === "padrao")
                .map((atendimento) => atendimento.id)
        );

        const uniqueNotificationsFilantropia = new Set(
            notificacoes
                .filter((atendimento) => atendimento.tipo === "filantropia")
                .map((atendimento) => atendimento.id)
        );

        const postNotificacao = async (data: any) => {
            await Api.postNotificacoesAtendimentos(data);
        };

        const postNotificacaoFilantropica = async (data: any) => {
            await Api.postNotificacoesAtendimentos(data);
        };

        atendimentos?.forEach(async (atendimento: any) => {
            const prazoPrioritario = new Date(
                atendimento.dt_prazo_resposta_prioritario
            ).getTime();

            const prazoPadrao = new Date(
                atendimento.dt_prazo_conclusao_padrao
            ).getTime();

            const prazoFilantropia = new Date(
                atendimento.dt_prazo_conclusao_filantropia
            ).getTime();

            const respostaDoAtendimento = new Date(
                atendimento.dt_resposta
            ).getTime();

            const conclusaoDoAtendimento = new Date(
                atendimento.dt_conclusao
            ).getTime();

            const dtHoje = new Date().getTime();

            const validateCasoPrioritarioSejaNull =
                prazoPrioritario < dtHoje &&
                atendimento.dt_resposta === null &&
                prazoPrioritario !== 0 &&
                atendimento.notificacao_enviada !== true;
            const validateCasoPadraoSejaNull =
                prazoPadrao < dtHoje &&
                atendimento.dt_conclusao === null &&
                prazoPadrao !== 0 &&
                atendimento.notificacao_enviada !== true;
            const validateCasoFilantropiaSejaNull =
                prazoFilantropia < dtHoje &&
                atendimento.dt_conclusao === null &&
                prazoFilantropia !== 0 &&
                atendimento.notificacao_enviada !== true;

            if (
                (respostaDoAtendimento > prazoPrioritario &&
                    respostaDoAtendimento !== 0 &&
                    prazoPrioritario !== 0 &&
                    atendimento.notificacao_enviada !== true) ||
                validateCasoPrioritarioSejaNull
            ) {
                if (!uniqueNotificationsPrioritario.has(atendimento.id)) {
                    uniqueNotificationsPrioritario.add(atendimento.id);
                    setNotificacoes((prevNotificacoes) => [
                        ...prevNotificacoes,
                        { ...atendimento, tipo: "prioritario" },
                    ]);

                    const stakeholder =
                        atendimento.stakeholder === "undefined"
                            ? "(Não inserido)"
                            : atendimento.stakeholder;

                    const corpoEmail = {
                        idAtendimento: atendimento.id,
                        email: `${user.email}`,
                        titulo: `Atendimento em atraso!`,
                        mensagem: `Prazo de resposta de atendimento prioritário vencido! Atendimento demandado por ${stakeholder}, o prazo de resposta erá até ${formatarData(
                            atendimento.dt_prazo_resposta_prioritario
                        )}.`,
                    };

                    Api.sendEmailAnalista(corpoEmail);

                    const data = {
                        mensagem: `Prazo de resposta de atendimento <b style="color: #f00000;">prioritário</b> vencido! Atendimento demandado por ${stakeholder}, o prazo de resposta erá até <b>${formatarData(
                            atendimento.dt_prazo_resposta_prioritario
                        )}.</b>`,
                        idUser: user.id,
                        email: `${user.email}`,
                        idAtendimento: atendimento.id,
                    };

                    postNotificacao(data);
                }
            }

            if (
                (conclusaoDoAtendimento > prazoPadrao &&
                    conclusaoDoAtendimento !== 0 &&
                    prazoPadrao !== 0 &&
                    atendimento.notificacao_enviada !== true) ||
                validateCasoPadraoSejaNull
            ) {
                if (!uniqueNotificationsPadrao.has(atendimento.id)) {
                    uniqueNotificationsPadrao.add(atendimento.id);
                    setNotificacoes((prevNotificacoes) => [
                        ...prevNotificacoes,
                        { ...atendimento, tipo: "padrao" },
                    ]);

                    const stakeholder =
                        atendimento.stakeholder === "undefined"
                            ? "(Não inserido)"
                            : atendimento.stakeholder;

                    const corpoEmail = {
                        idAtendimento: atendimento.id,
                        email: `${user.email}`,
                        titulo: `Atendimento em atraso!`,
                        mensagem: `Prazo de conclusão de atendimento padrão vencido! Atendimento demandado por ${stakeholder}, o prazo de resposta erá até ${formatarData(
                            atendimento.dt_prazo_conclusao_padrao
                        )}.`,
                    };

                    Api.sendEmailAnalista(corpoEmail);

                    const data = {
                        mensagem: `Prazo de conclusão de atendimento <b style="color:#D2691E;">padrão</b> vencido! Atendimento demandado por ${stakeholder}, o prazo de resposta erá até <b>${formatarData(
                            atendimento.dt_prazo_conclusao_padrao
                        )}.</b>`,
                        idUser: user.id,
                        email: `${user.email}`,
                        idAtendimento: atendimento.id,
                    };

                    postNotificacao(data);
                }
            }

            if (
                (conclusaoDoAtendimento > prazoFilantropia &&
                    conclusaoDoAtendimento !== 0 &&
                    prazoFilantropia !== 0 &&
                    atendimento.notificacao_enviada !== true) ||
                validateCasoFilantropiaSejaNull
            ) {
                if (!uniqueNotificationsFilantropia.has(atendimento.id)) {
                    uniqueNotificationsFilantropia.add(atendimento.id);
                    setNotificacoes((prevNotificacoes) => [
                        ...prevNotificacoes,
                        { ...atendimento, tipo: "filantropia" },
                    ]);

                    const stakeholder =
                        atendimento.stakeholder === "undefined"
                            ? "(Não inserido)"
                            : atendimento.stakeholder;

                    const corpoEmail = {
                        idAtendimento: atendimento.id,
                        email: `${user.email}`,
                        titulo: `Atendimento em atraso!`,
                        mensagem: `Prazo de conclusão de atendimento filantropico vencido! Atendimento demandado por ${stakeholder}, o prazo de resposta erá até ${formatarData(
                            atendimento.dt_prazo_conclusao_filantropia
                        )}.`,
                    };

                    Api.sendEmailAnalista(corpoEmail);

                    const data = {
                        mensagem: `Prazo de conclusão de atendimento <b style="color: #DAA520;">filantropico</b> vencido! Atendimento demandado por ${stakeholder}, o prazo de resposta erá até <b>${formatarData(
                            atendimento.dt_prazo_conclusao_filantropia
                        )}.</b>`,
                        idUser: user.id,
                        email: `${user.email}`,
                        idAtendimento: atendimento.id,
                    };

                    postNotificacaoFilantropica(data);
                }
            }

            if (atendimento.dt_conclusao) {
                try {
                    const notificacao = await getNotificacoesByAtendimento(
                        atendimento.id
                    );
                    if (notificacao && notificacao.id) {
                        await deleteNotification(notificacao.id);
                    }
                } catch (err) {
                    console.error("Erro ao obter ou deletar notificação", err);
                }
            }
        });
    }, [atendimentos, notificacoes]);

    useEffect(() => {
        Api.GetFotoUsuario(user.id, config).then((data) => {
            setFotoCadastrada(data.foto);
        });
    }, []);

    const notificationContainerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                notificationContainerRef.current &&
                event.target instanceof Node &&
                !notificationContainerRef.current.contains(event.target)
            ) {
                setShowNotifications(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [notificationContainerRef]);

    const formatPicture = (foto: string) => {
        const fotoFormatada = foto ? foto.replace(/\\/g, "/") : "";
        return fotoFormatada;
    };

    const NotificacoesBancoOrdenado = [...NotificacoesBanco].sort(
        (a, b) => b.id - a.id
    );

    const deleteNotification = async (id: any) => {
        await Api.deleteNotificacoesAtendimentos(id);
    };

    const getNotificacoesByAtendimento = async (id: any) => {
        return await Api.getNotificacoesAtendimentosByIdAtendimento(id);
    };

    return (
        <div className="d-flex">
            <img
                className={"mosaicLogoCabecalho"}
                src={logoMosaic}
                alt="Mosaic Logo"
            />
            <div className="title-performance col-12 col-sm-4">
                <span className="texto-cabecalho">
                    {t("Performance Social")}&nbsp;
                </span>
                {portalLogado === "portalComunidades" && (
                    <span className="portal-logado">
                        -&nbsp;{t("Portal Comunidades")}
                    </span>
                )}
                {portalLogado === "portalInstitucional" && (
                    <span className="portal-logado">
                        {" "}
                        - {t("Portal Institucional")}
                    </span>
                )}
                {portalLogado === "portalGovernamental" && (
                    <span className="portal-logado">
                        {" "}
                        - {t("Portal Governamental")}
                    </span>
                )}
            </div>

            <div id="cabecalhoComponent">
                <div
                    className="notification-container"
                    ref={notificationContainerRef}
                >
                    {NotificacoesBanco.length > 0 &&
                    notifiVisualizada === false ? (
                        <span className="number-notification">
                            {NotificacoesBanco.length}
                        </span>
                    ) : (
                        <></>
                    )}

                    <button onClick={handleNotificationClick}>
                        {showNotifications ? (
                            <IoMdNotificationsOff color="#ff0000" size={18} />
                        ) : (
                            <IoMdNotifications color="#165788" size={18} />
                        )}
                        <span className="col-7  userName">
                            {t("Notificações")}
                        </span>
                    </button>
                    {showNotifications && (
                        <div className="notification-popup">
                            {NotificacoesBancoOrdenado.length > 0 ? (
                                NotificacoesBancoOrdenado.map(
                                    (prioritaria, index) => (
                                        <div
                                            key={index}
                                            className="notification-item"
                                            dangerouslySetInnerHTML={{
                                                __html: prioritaria.mensagem,
                                            }}
                                        ></div>
                                    )
                                )
                            ) : (
                                <span className="notification-item">
                                    Não há notificações
                                </span>
                            )}
                        </div>
                    )}
                </div>

                <RxDividerVertical
                    className="barra-cabecalho"
                    size={30}
                    color="#f1f4f9"
                />

                <div className="languagesHolder">
                    <LiaGlobeAmericasSolid color="#165788" />
                    <select
                        className="selectLanguage"
                        value={language}
                        onChange={(e) => {
                            setLanguage(e.target.value);
                            changeLanguage(e.target.value);
                        }}
                    >
                        <option value={"pt"}>Português</option>
                        <option value={"en"}>English</option>
                    </select>

                    <RxDividerVertical
                        className="barra-cabecalho"
                        size={30}
                        color="#f1f4f9"
                    />

                    <div>
                        <NavLink
                            className="links-cabecalho"
                            to={"https://online.fliphtml5.com/oxhzi/wmjb/#p=1"}
                            target="_blank"
                        >
                            <div className="row">
                                <BiSolidHelpCircle
                                    size={15}
                                    className="col-5 icons-help"
                                />
                                <span className="col-7 userName">
                                    {t("Ajuda")}
                                </span>
                            </div>
                        </NavLink>
                    </div>

                    <RxDividerVertical
                        className="barra-cabecalho"
                        size={30}
                        color="#f1f4f9"
                    />
                    <div>
                        <NavLink className="links-cabecalho" to={"/permissoes"}>
                            <div className="row">
                                <GoPasskeyFill
                                    size={15}
                                    className="col-4 icons-cabecalho"
                                />
                                <span className="col-8 userName">
                                    {t("Permissões")}
                                </span>
                            </div>
                        </NavLink>
                    </div>

                    <RxDividerVertical
                        className="barra-cabecalho"
                        size={30}
                        color="#f1f4f9"
                    />
                </div>

                <div className="perfilHolder">
                    <Link to={"/perfil"} className="linkPerfil">
                        {fotoCadastrada ? (
                            <img
                                src={
                                    fotoSelecionada
                                        ? URL.createObjectURL(fotoSelecionada)
                                        : new URL(
                                              formatPicture(fotoCadastrada),
                                              linkBackend
                                          ).href
                                }
                                className="userPicture"
                                alt="Foto de perfil"
                            />
                        ) : (
                            <img
                                src={fotoPadrao}
                                className="userPicture"
                                alt="Foto de perfil"
                            />
                        )}
                    </Link>
                    <NavLink className="links-cabecalho" to={"/perfil"}>
                        <div className="userInformations">
                            <span className="userName">{userLogged.nome}</span>
                            <span className="userType">{t("Analista")}</span>
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>
    );
}

export default Cabecalho;

import React, { useState } from "react";
import { Col, Container } from "react-bootstrap";
import { useEffect } from "react";
import { ClearSessionStorage } from "../../../config/api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BotaoVoltar from "../../../components/buttonReturnPage";

function IndicadoresGovernamental() {
    const navegar = useNavigate();
    const { t } = useTranslation();
    const [activeDashboard, setActiveDashboard] =
        useState<string>("governamental");

    sessionStorage.setItem("portalLogado", "portalIndicadores");

    useEffect(() => {
        const expirationUsuario = sessionStorage.getItem("expirationUsuario");
        if (!expirationUsuario || new Date() > new Date(expirationUsuario)) {
            ClearSessionStorage();
            navegar("/login");
        }
    }, []);

    const handleDashboardChange = (dashboard: string) => {
        setActiveDashboard(dashboard);
    };

    function getDashboardId(dashboard: string): string {
        const dashboardIds: Record<string, string> = {
            governamental: t(
                "eyJrIjoiM2NmNjk1OTEtNmM1MC00NjJjLTlkNTktNmNjNWJhNmIwYWM5IiwidCI6ImJhMzg5NTgyLTA0M2ItNDBhYS04ZWJlLWNmYjBhZTI4MjgzMyJ9"
            ),
        };

        return dashboardIds[dashboard] || "";
    }

    return (
        <Col xs={12} id="indicadores">
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginLeft: "3%",
                    marginBottom: "1%",
                }}
            >
                <BotaoVoltar />
            </div>
            <h3>{t("Governamental")}</h3>
            <Container className="container-indicadores mb-3">
                <iframe
                    title="Report Section"
                    src={`https://app.powerbi.com/view?r=${getDashboardId(
                        activeDashboard
                    )}`}
                    className="dashboard-instituto mb-5"
                ></iframe>
            </Container>
        </Col>
    );
}

export default IndicadoresGovernamental;

import Axios from "axios";
// import { useHistory } from "react-router-dom";

// export const linkBackend = "http://localhost:60007/"; //Backend local
export const linkBackend = "https://performancesocialbackend.directy.com.br/"; //Backend online

Axios.defaults.baseURL = linkBackend;

Axios.defaults.headers.common["Content-Type"] = "application/json";

// Configuração padrão de token
const tokenUsuario = sessionStorage.getItem("tokenUsuario");
Axios.defaults.headers.common["Authorization"] = tokenUsuario
    ? `${tokenUsuario}`
    : null;

const LOCALES_STORAGE_KEY = "localesStorage";

export function ClearSessionStorage() {
    sessionStorage.removeItem("tokenUsuario");
    sessionStorage.removeItem("loginUsuario");
    sessionStorage.removeItem("expirationUsuario");
}

// Adiciona um interceptor para capturar erros de resposta
Axios.interceptors.response.use(
    (response) => {
        // Caso a resposta seja bem-sucedida, simplesmente retorna a resposta
        return response;
    },
    (error) => {
        // Se ocorrer um erro com status 401, trata aqui
        if (error.response && error.response.status === 401) {
            ClearSessionStorage();
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

Axios.interceptors.request.use(
    (config) => {
        if (config.data instanceof FormData) {
            config.headers["Content-Type"] = "multipart/form-data";
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default class Api {
    static async PostLogin(obj: any) {
        const link = "/login/portal";
        return await Axios.post(link, obj)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    static async GetLocales() {
        const link = "/localidades";
        const localesData = await Axios.get(link).then((res) => {
            return res.data;
        });

        sessionStorage.setItem(
            LOCALES_STORAGE_KEY,
            JSON.stringify(localesData)
        );
        sessionStorage.setItem(
            LOCALES_STORAGE_KEY,
            JSON.stringify(localesData)
        );
        return localesData;
    }

    static async GetUsuarios(config: any) {
        const link = "/usuarios";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async GetAnalistas(config: any) {
        const link = "/usuarios/analistas";
        return await Axios.get(link, config)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err;
            });
    }

    static async GetFotoUsuario(id: any, config: any) {
        const link = `/usuarios/foto/${id}`;
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async PutSenhaUsuario(obj: any, config: any) {
        const link = "/usuarios/editar/senha";
        return await Axios.put(link, obj, config).then((res) => {
            return res.data;
        });
    }

    static async PutPerfilUsuario(obj: any, config: any) {
        const link = "/usuarios/editar/perfil";
        return await Axios.put(link, obj, config).then((res) => {
            return res.data;
        });
    }

    static async PutEsqueceuSenha(obj: any) {
        const link = "/usuarios/esqueceu_senha";
        return await Axios.put(link, obj).then((res) => {
            return res.data;
        });
    }

    static async PutFotoUsuario(formData: any, config: any) {
        const link = "/usuarios/editar/foto";
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";
        return await Axios.put(link, formData, configData).then((res) => {
            return res.data;
        });
    }

    static async PostUsuario(data: any, config: any) {
        const link = "/usuarios";
        return await Axios.post(link, data, config).then((res) => {
            return res.data;
        });
    }

    static async GetAtendimentos(config: any) {
        const link = "/atendimentos";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async GetAtendimentosByLocation() {
        const link = "/atendimentos/locations";
        return await Axios.get(link).then((res) => {
            return res.data;
        });
    }

    static async PostAtendimento(formData: any, config: any) {
        const link = "/atendimentos";
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";

        return await Axios.post(link, formData, configData).then((res) => {
            return res.data;
        });
    }

    static async PutAtendimento(formData: any, config: any) {
        const link = "/atendimentos";
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";
        return await Axios.put(link, formData, configData).then((res) => {
            return res.data;
        });
    }

    static async DeleteAtendimento(id: number, config: any) {
        const link = `/atendimentos/${id}`;
        return await Axios.delete(link, config).then((res) => {
            return res.data;
        });
    }

    static async GetEngajamentos(config: any) {
        const link = "/engajamentos";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async PostEngajamento(obj: FormData, config: any) {
        const link = "/engajamentos";
        return await Axios.post(link, obj, config)
            .then((res) => res.data)
            .catch((error) => {
                console.error("Erro no upload de engajamento:", error);
                throw error;
            });
    }

    static async PutEngajamento(obj: any, config: any) {
        const link = "/engajamentos";
        return await Axios.put(link, obj, config)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err;
            });
    }

    static async DeleteEngajamento(id: number, config: any) {
        const link = `/engajamentos/${id}`;
        return await Axios.delete(link, config).then((res) => {
            return res.data;
        });
    }

    static async GetVisitas(config: any) {
        const link = "/visitas";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async PostVisita(formData: any, config: any) {
        const link = "/visitas";
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";
        return await Axios.post(link, formData, configData).then((res) => {
            return res.data;
        });
    }

    static async PutVisita(formData: any, config: any) {
        const link = "/visitas";
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";
        return await Axios.put(link, formData, configData).then((res) => {
            return res.data;
        });
    }

    static async DeleteVisita(id: number, config: any) {
        const link = `/visitas/${id}`;
        return await Axios.delete(link, config).then((res) => {
            return res.data;
        });
    }

    static async GetNoticias(config: any) {
        const link = "/noticias";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async PostNoticia(formData: any, config: any) {
        const link = "/noticias";
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";
        return await Axios.post(link, formData, configData).then((res) => {
            return res.data;
        });
    }

    static async PutNoticia(formData: any, config: any) {
        const link = "/noticias";
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";
        return await Axios.put(link, formData, configData).then((res) => {
            return res.data;
        });
    }

    static async DeleteNoticia(id: number, config: any) {
        const link = `/noticias/${id}`;
        return await Axios.delete(link, config).then((res) => {
            return res.data;
        });
    }

    static async GetNotificacoes(config: any) {
        const link = "/notificacoes";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async PostNotificacao(obj: any, config: any) {
        const link = "/notificacoes";
        return await Axios.post(link, obj, config).then((res) => {
            return res.data;
        });
    }

    static async PutNotificacao(obj: any, config: any) {
        const link = "/notificacoes";
        return await Axios.put(link, obj, config).then((res) => {
            return res.data;
        });
    }

    static async DeleteNotificacao(id: number, config: any) {
        const link = `/notificacoes/${id}`;
        return await Axios.delete(link, config).then((res) => {
            return res.data;
        });
    }

    static async GetSms(config: any) {
        const link = "/sms";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async PostSms(obj: any, config: any) {
        const link = "/sms";
        return await Axios.post(link, obj, config).then((res) => {
            return res.data;
        });
    }

    static async GetStakeholders(config: any) {
        const link = "/stakeholders";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async GetStakeholdersRI(config: any) {
        const link = "/stakeholders/RI";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async GetStakeholdersRC(config: any) {
        const link = "/stakeholders/RC";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async PostStakeholder(formData: any, config: any) {
        const link = "/stakeholders";
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";
        return await Axios.post(link, formData, configData).then((res) => {
            return res.data;
        });
    }

    static async PutStakeholder(formData: any, config: any) {
        const link = "/stakeholders";
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";
        return await Axios.put(link, formData, configData).then((res) => {
            return res.data;
        });
    }

    static async DeleteStakeholder(id: number, config: any) {
        const link = `/stakeholders/${id}`;
        return await Axios.delete(link, config).then((res) => {
            return res.data;
        });
    }

    static async GetSolicitacoes(config: any) {
        const link = "/solicitacoes";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async PutSolicitacaoResposta(obj: any, config: any) {
        const link = "/solicitacoes/resposta";
        return await Axios.put(link, obj, config).then((res) => {
            return res.data;
        });
    }

    static async GetDemandaExterna(config: any) {
        const link = "/demandas/externa";
        return await Axios.get(link, config)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err;
            });
    }

    static async PostDemandaExterna(data: any, config: any) {
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";
        const link = "/demandas/externa";
        return await Axios.post(link, data, configData)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err;
            });
    }

    static async GetDemandaInterna(config: any) {
        const link = "/demandas/interna";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async PostDemandaInterna(data: {}, config: any) {
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";
        const link = "/demandas/interna";

        return await Axios.post(link, data, configData).then((res) => {
            return res.data;
        });
    }

    static async PutPermissoes(data: {}, config: any) {
        const link = "/permissoes";
        return await Axios.put(link, data, config)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err;
            });
    }

    static async GetPermissoesById(idUser: number, config: any) {
        const link = `/permissoes/${idUser}`;
        return await Axios.get(link, config)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err;
            });
    }

    static async GetAllPermissoes(config: any) {
        const link = `/permissoes`;
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async GetContadoresComunidade(config: any) {
        const link = "/contadores/comunidade";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async GetContadoresInstitucional(config: any) {
        const link = "/contadores/institucional";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async PutDemandaExterna(data: any, config: any) {
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";
        const link = "/demandas/externa";

        return await Axios.put(link, data, configData)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.error("Erro ao enviar requisição:", error);
                throw error;
            });
    }

    static async PutDemandaInterna(data: any, config: any) {
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";
        const link = "/demandas/interna";
        return await Axios.put(link, data, configData).then((res) => {
            return res.data;
        });
    }

    static async GetPaineisSociais(config: any) {
        const link = "/painelSocial";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async GetPaineisSociaisUnidade(config: any) {
        const link = "/painelSocial/unidade";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async PostPainelSocial(formData: any, config: any) {
        const link = "/painelSocial";
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";

        return await Axios.post(link, formData, configData).then((res) => {
            return res.data;
        });
    }

    static async PutPainelSocial(formData: any, config: any) {
        const link = "/painelSocial";
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";
        return await Axios.put(link, formData, configData).then((res) => {
            return res.data;
        });
    }

    static async DeletePainelSocial(id: number, config: any) {
        const link = `/painelSocial/${id}`;
        return await Axios.delete(link, config).then((res) => {
            return res.data;
        });
    }

    static async GetAtendimentosDate(config: any) {
        const link = "/atendimentos/tipo_data";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async GetAtendimentosByName(nome: string, config: any) {
        const link = `/atendimentos/nome/${nome}`;
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async sendEmailAnalista(data: any) {
        const link = `atendimentos/notificacao/email`;
        return await Axios.post(link, data).then((res) => {
            return res.data;
        });
    }

    static async getNotificacoesAtendimentos(id: any) {
        const link = `/notificacoes/atendimentos/${id}`;
        return await Axios.get(link).then((res) => {
            return res.data;
        });
    }

    static async getNotificacoesAtendimentosByIdAtendimento(id: any) {
        const link = `/notificacoes/atendimentos/byId/${id}`;
        return await Axios.get(link).then((res) => {
            return res.data;
        });
    }

    static async postNotificacoesAtendimentos(data: any) {
        const link = `/notificacoes/atendimentos`;
        return await Axios.post(link, data).then((res) => {
            return res.data;
        });
    }

    static async deleteNotificacoesAtendimentos(id: any) {
        const link = `/notificacoes/atendimentos/${id}`;
        return await Axios.delete(link).then((res) => {
            return res.data;
        });
    }

    static async getAllMunicipiosLocations() {
        const link = "/municipios";
        return await Axios.get(link).then((res) => {
            return res.data;
        });
    }

    static async getMunicipioByName(name: string) {
        const link = `/municipios/${name}`;
        return await Axios.get(link).then((res) => {
            return res.data;
        });
    }

    static async deleteDemandaExterna(id: any) {
        const link = `/demandas/externa/${id}`;
        return await Axios.delete(link).then((res) => {
            return res.data;
        });
    }

    static async deleteDemandaInterna(id: any) {
        const link = `/demandas/interna/${id}`;
        return await Axios.delete(link).then((res) => {
            return res.data;
        });
    }

    static async DeleteUsuario(id: any) {
        const link = `/usuarios/delete/${id}`;
        return await Axios.delete(link)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err;
            });
    }

    static async getEngajamentosByDemanda(id: any) {
        const link = `/engajamentos/by_demanda/${id}`;
        return await Axios.get(link).then((res) => {
            return res.data;
        });
    }

    static async getOficiosById(id: any, demanda: string) {
        const link = `/demandas/oficio/${id}`;
        return await Axios.get(link, { params: { demanda } })
            .then((res) => {
                return res.data;
            })
            .catch((err) => console.log("err", err));
    }

    static async GetAllOficios() {
        const link = `/demandas/oficio`;
        return await Axios.get(link)
            .then((res) => {
                return res.data;
            })
            .catch((err) => console.log("err", err));
    }

    static async PostOficio(oficio: any) {
        const link = `/demandas/oficio`;
        return await Axios.post(link, oficio)
            .then((res) => {
                return res.data;
            })
            .catch((err) => console.log("err", err));
    }

    static async PostMapCoordenadaComunidade(body: any) {
        const link = `/map_coordinates`;
        return await Axios.post(link, body)
            .then((res) => {})
            .catch((err) => console.log("err", err));
    }

    static async GetMapCoordenadaComunidade() {
        const link = `/map_coordinates`;
        return await Axios.get(link)
            .then((res) => {
                return res.data;
            })
            .catch((err) => console.log("err", err));
    }

    static async DeleteMapCoordenadaComunidade(id: number) {
        const link = `/map_coordinates/${id}`;
        return await Axios.delete(link)
            .then((res) => {})
            .catch((err) => console.log("err", err));
    }

    static async DeleteOficio(id: number) {
        const link = `demandas/oficio/${id}`;
        return await Axios.delete(link)
            .then((res) => {})
            .catch((err) => console.log("err", err));
    }

    static async GetDemandaByEngajaExterna(id: number) {
        const link = `engajamentos/by_engajamento_externa/${id}`;
        return await Axios.get(link)
            .then((res) => {
                return res.data;
            })
            .catch((err) => console.log("err", err));
    }

    static async GetDemandaByEngajaInterna(id: number | null) {
        const link = `engajamentos/by_engajamento_interna/${id}`;
        return await Axios.get(link)
            .then((res) => {
                return res.data;
            })
            .catch((err) => console.log("err", err));
    }

    //----------Relações Governamentais----------//

    static async PostDemandaRgInterna(data: {}, config: any) {
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";
        const link = "/demandas_rg/interna";

        return await Axios.post(link, data, configData).then((res) => {
            return res.data;
        });
    }

    static async PostDemandaRgExterna(data: any, config: any) {
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";
        const link = "/demandas_rg/externa";
        return await Axios.post(link, data, configData)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err;
            });
    }

    static async GetDemandaRgInterna(config: any) {
        const link = "/demandas_rg/interna";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async GetDemandaRgExterna(config: any) {
        const link = "/demandas_rg/externa";
        return await Axios.get(link, config)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err;
            });
    }

    static async PutDemandaRgInterna(data: any, config: any) {
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";
        const link = "/demandas_rg/interna";
        return await Axios.put(link, data, configData).then((res) => {
            return res.data;
        });
    }

    static async PutDemandaRgExterna(data: any, config: any) {
        const configData = JSON.parse(JSON.stringify(config));
        configData.headers["Content-Type"] = "multipart/form-data";
        const link = "/demandas_rg/externa";

        return await Axios.put(link, data, configData)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.error("Erro ao enviar requisição:", error);
                throw error;
            });
    }

    static async deleteDemandaRgInterna(id: any) {
        const link = `/demandas_rg/interna/${id}`;
        return await Axios.delete(link).then((res) => {
            return res.data;
        });
    }

    static async deleteDemandaRgExterna(id: any) {
        const link = `/demandas_rg/externa/${id}`;
        return await Axios.delete(link).then((res) => {
            return res.data;
        });
    }

    static async PostEngajamentoRg(obj: FormData, config: any) {
        const link = "/engajamentos_rg";
        return await Axios.post(link, obj, config)
            .then((res) => res.data)
            .catch((error) => {
                console.error("Erro no upload de engajamento:", error);
                throw error;
            });
    }

    static async GetEngajamentosRg(config: any) {
        const link = "/engajamentos_rg";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }

    static async getEngajamentosRgByDemanda(id: any) {
        const link = `/engajamentos_rg/by_demanda/${id}`;
        return await Axios.get(link).then((res) => {
            return res.data;
        });
    }

    static async PutEngajamentoRg(obj: any, config: any) {
        const link = "/engajamentos_rg";
        return await Axios.put(link, obj, config)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err;
            });
    }

    static async DeleteEngajamentoRg(id: number, config: any) {
        const link = `/engajamentos_rg/${id}`;
        return await Axios.delete(link, config).then((res) => {
            return res.data;
        });
    }

    static async GetDemandaRgByEngajaExterna(id: number) {
        const link = `engajamentos_rg/by_engajamento_externa/${id}`;
        return await Axios.get(link)
            .then((res) => {
                return res.data;
            })
            .catch((err) => console.log("err", err));
    }

    static async GetDemandaRgByEngajaInterna(id: number | null) {
        const link = `engajamentos_rg/by_engajamento_interna/${id}`;
        return await Axios.get(link)
            .then((res) => {
                return res.data;
            })
            .catch((err) => console.log("err", err));
    }

    static async PostOficioRg(oficio: any) {
        const link = `/demandas_rg/oficio`;
        return await Axios.post(link, oficio)
            .then((res) => {
                return res.data;
            })
            .catch((err) => console.log("err", err));
    }

    static async GetAllOficiosRg() {
        const link = `/demandas_rg/oficio`;
        return await Axios.get(link)
            .then((res) => {
                return res.data;
            })
            .catch((err) => console.log("err", err));
    }

    static async getOficiosRgById(id: any, demanda: string) {
        const link = `/demandas_rg/oficio/${id}`;
        return await Axios.get(link, { params: { demanda } })
            .then((res) => {
                return res.data;
            })
            .catch((err) => console.log("err", err));
    }

    static async DeleteOficioRg(id: number) {
        const link = `demandas_rg/oficio/${id}`;
        return await Axios.delete(link)
            .then((res) => {})
            .catch((err) => console.log("err", err));
    }

    static async GetContadoresGovernamental(config: any) {
        const link = "/contadores/governamental";
        return await Axios.get(link, config).then((res) => {
            return res.data;
        });
    }
}

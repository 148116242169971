import { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../config/api";
import { Col, Form, Modal, Row, Button } from "react-bootstrap";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { ptBR } from "date-fns/locale";
import { format } from "date-fns";
import { toast } from "react-toastify";
import Select from "react-select";
import "./styles.css";

function Oficios({
    config,
    listaEstados,
    listaMunicipios,
}: {
    config: any;
    listaEstados: any;
    listaMunicipios: any;
}) {
    const { t } = useTranslation();

    const [datagridRows, setDatagridRows] = useState<any[]>([]);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openModalFiltro, setOpenModalFiltro] = useState(false);
    const [showModalRegister, setShowModalRegister] = useState<boolean>(false);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);

    const [oficioEntrada, setOficioEntrada] = useState<any>();
    const [oficioResposta, setCaminhoResposta] = useState<any>();
    const [idDemandaExterna, setIdDemandaExterna] = useState<any>();
    const [idDemandaInterna, setIdDemandaInterna] = useState<any>();
    const [assunto, setAssunto] = useState<any>();
    const [data, setData] = useState<any>();
    const [estado, setEstado] = useState<any>();
    const [municipio, setMunicipio] = useState<any>();
    const [origem, setOrigem] = useState<any>();

    const [listademandaExterna, setListaDemandaExterna] = useState([]);
    const [listademandaInterna, setListaDemandaInterna] = useState([]);

    const [keyForSelect, setKeyForSelect] = useState<any>();
    const [demandaSelecionada, setDemandaSelecionada] = useState(false);
    const [showModalDemandas, setShowModalDemandas] = useState<boolean>(false);
    const [tipoDemanda, setTipoDemanda] = useState("externa");
    const [viewListaExterna, setViewListaExterna] = useState(true);
    const [idDemandaAtrelada, setIdDemandaAtrelada] = useState<any>({
        demanda: "",
        id: 0,
    });

    const handleOpenModalDemandas = () => {
        setShowModalDemandas(true);
    };

    const handleCloseModalDemandas = () => {
        setShowModalDemandas(false);
    };

    const handleClickListaExterna = () => {
        setViewListaExterna(true);
        setTipoDemanda("externa");
    };

    const handleClickListaInterna = () => {
        setViewListaExterna(false);
        setTipoDemanda("interna");
    };

    const handleCloseModalRegister = () => {
        setShowModalRegister(false);
    };

    const handleOpemModalRegister = () => {
        if (!data) {
            toast.error("Data do oficio é obrigatoria!");
        } else if (demandaSelecionada === false) {
            toast.error("Atrele a uma demanda antes de registrar o Ofício!");
        } else {
            setShowModalRegister(true);
        }
    };

    const getApi = async () => {
        await Api.GetDemandaRgExterna(config).then((data) => {
            const sortedData = data.demandas.sort(
                (a: any, b: any) => b.id - a.id
            );
            setListaDemandaExterna(sortedData);
        });

        await Api.GetDemandaRgInterna(config).then((data) => {
            const sortedData = data.sort((a: any, b: any) => b.id - a.id);
            setListaDemandaInterna(sortedData);
        });
    };

    useEffect(() => {
        getApi();
    }, []);

    const datagridColunas: readonly GridColDef<any>[] = [
        {
            field: "dt_entrada",
            headerName: t("Data de Entrada") as string,
            type: "date",
            width: 150,
            valueGetter: ({ row }: { row: any }) => {
                if (!row.dt_entrada) {
                    return null;
                }
                return new Date(row.dt_entrada);
            },
            renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
                return params.value && params.value instanceof Date
                    ? format(params.value, "dd/MM/yyyy", { locale: ptBR })
                    : "";
            },
        },
        {
            field: "estado",
            headerName: t("header_Estado") as string,
            type: "select",
            width: 120,
        },
        {
            field: "municipio",
            headerName: t("header_Municipio") as string,
            type: "string",
            width: 120,
        },
        {
            field: "origem",
            headerName: t("Origem") as string,
            type: "string",
            width: 200,
        },
        {
            field: "prioridade_tema",
            headerName: t("Prioridade") as string,
            type: "string",
            width: 150,
        },
        {
            field: "descricao_tema",
            headerName: t("Descrição") as string,
            type: "string",
            width: 200,
        },
        {
            field: "categoria_tema",
            headerName: t("Categoria") as string,
            type: "string",
            width: 150,
        },
        {
            field: "relevancia",
            headerName: "Relevância" as string,
            type: "text",
            width: 150,
        },
    ];

    const handleRowClick = (params: any) => {
        toast.info("Demanda atrelada com sucesso. Registre o ofício!");
        setSelectedRowData(params.row);
        setEstado(params.row.estado);
        setMunicipio(params.row.municipio);
        setOrigem(params.row.origem);
        setAssunto(params.row.descricao_tema);
        setIdDemandaAtrelada({ demanda: tipoDemanda, id: params.row.id });
        // setIsModalOpen(true);

        handleCloseModalDemandas();
        setDemandaSelecionada(true);
    };

    const handleClearFields = () => {
        setEstado("");
        setMunicipio("");
        setOrigem("");
        setAssunto("");
        setData("");
        setIdDemandaExterna(0);
        setIdDemandaInterna(0);
        setOficioEntrada(null);
        setCaminhoResposta(null);
        setIdDemandaAtrelada({
            demanda: "",
            id: 0,
        });
    };

    const optionsEstados = listaEstados.map((nome: any) => ({
        value: nome.sigla,
        label: nome.nome,
    }));

    const handleSubmitForm = async (event: any) => {
        event.preventDefault();
        setLoadingButton(true);

        const formData = new FormData();
        formData.append("data", String(data));
        formData.append("estado", estado);
        formData.append("municipio", municipio);
        formData.append("origem", origem);
        formData.append("assunto", assunto);
        formData.append("oficio_entrada1", oficioEntrada);
        formData.append("oficio_resposta1", oficioResposta);
        formData.append(
            idDemandaAtrelada.demanda === "externa"
                ? "idDemandaExterna"
                : "idDemandaInterna",
            idDemandaAtrelada.id
        );

        await Api.PostOficioRg(formData)
            .then(() => {
                toast.success("Ofício Criado com sucesso!");
                setLoadingButton(false);
                handleClearFields();
                handleCloseModalRegister();
            })
            .catch(() => {
                toast.error("Erro ao criar Ofício demanda!");
                setLoadingButton(false);
                handleCloseModalRegister();
            });
    };

    return (
        <Col>
            <Row sm={12}>
                <Col sm={4}>
                    <Form.Label htmlFor="data-entrada">{t("Data")}</Form.Label>
                    <Form.Control
                        className="formInput"
                        type="date"
                        value={data}
                        onChange={(e) => {
                            setData(e.target.value);
                        }}
                    />
                </Col>
                <Col sm={4}>
                    <Form.Label>{t("Estado")}</Form.Label>
                    <Form.Control
                        disabled
                        placeholder="Atrele demanda para preencher"
                        type="text"
                        key={keyForSelect}
                        required
                        onChange={(e) => {
                            setEstado(e.target.value);
                        }}
                        value={estado}
                    ></Form.Control>
                </Col>

                <Col sm={4}>
                    <Form.Label>{t("Municipio")}</Form.Label>
                    <Form.Control
                        disabled
                        // as="select"
                        placeholder="Atrele demanda para preencher"
                        type="text"
                        key={keyForSelect}
                        required
                        onChange={(e) => {
                            setMunicipio(e.target.value);
                        }}
                        value={municipio}
                    >
                        {/* {(listaMunicipios[estado] || []).map((municipio) => (
            <option key={municipio} value={municipio}>
              {municipio}
            </option>
          ))} */}
                    </Form.Control>
                </Col>
            </Row>

            <Row sm={12} className="mt-3">
                <Col sm={6}>
                    <Form.Label>{t("Origem")}</Form.Label>
                    <Form.Control
                        disabled
                        placeholder="Atrele demanda para preencher"
                        className="formInput"
                        type="text"
                        value={origem}
                        onChange={(e) => {
                            setOrigem(e.target.value);
                        }}
                    />
                </Col>
                <Col sm={6}>
                    <Form.Label>{t("Assunto")}</Form.Label>
                    <Form.Control
                        disabled
                        placeholder="Atrele demanda para preencher"
                        className="formInput"
                        type="text"
                        value={assunto}
                        onChange={(e) => {
                            setAssunto(e.target.value);
                        }}
                    />
                </Col>
            </Row>

            <Row sm={12} className="mt-4">
                <Col sm={6}>
                    <Form.Label>{t("Ofício de Entrada:")}</Form.Label>
                    <div className="cadastroFotos">
                        <Form.Control
                            className="inputFoto"
                            type="file"
                            // value={CaminhoOficioEntrada}
                            onChange={(e: any) => {
                                setOficioEntrada(
                                    e.target.files[0] ? e.target.files[0] : null
                                );
                            }}
                        />
                    </div>
                </Col>
                <Col sm={6}>
                    <Form.Label>{t("Ofício de Resposta:")}</Form.Label>
                    <div className="cadastroFotos">
                        <Form.Control
                            className="inputFoto"
                            type="file"
                            // value={CaminhoOficioResposta}
                            onChange={(e: any) => {
                                setCaminhoResposta(
                                    e.target.files[0] ? e.target.files[0] : null
                                );
                            }}
                        />
                    </div>
                </Col>
            </Row>

            <Col sm={12}>
                <Row>
                    <Col sm={4}>
                        <button
                            onClick={handleOpenModalDemandas}
                            className="botao-grid"
                            type="button"
                        >
                            {demandaSelecionada
                                ? "Demanda Atrelada"
                                : "Atrelar a demanda"}
                        </button>
                    </Col>
                    <Col sm={4}>
                        <button
                            type="button"
                            className="botao-grid"
                            onClick={handleOpemModalRegister}
                        >
                            {t("Registrar")}
                        </button>
                    </Col>
                    <Col sm={4}>
                        <button
                            type="button"
                            className="botao-grid"
                            onClick={handleClearFields}
                        >
                            {t("Limpar")}
                        </button>
                    </Col>
                </Row>
            </Col>

            {/* MODAL Lista de demandas */}
            <Modal
                show={showModalDemandas}
                onHide={handleCloseModalDemandas}
                centered={true}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Col sm={12}>
                        <Row>
                            <Col sm={6}>
                                <button
                                    className={`btn btn-primary ${
                                        viewListaExterna ? "active" : ""
                                    }`}
                                    onClick={handleClickListaExterna}
                                    style={{ width: "95%" }}
                                >
                                    Demanda Externa
                                </button>
                            </Col>
                            <Col sm={6}>
                                <button
                                    className={`btn btn-primary ${
                                        !viewListaExterna ? "active" : ""
                                    }`}
                                    onClick={handleClickListaInterna}
                                    style={{ width: "95%" }}
                                >
                                    Demanda Interna
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Modal.Header>
                <Modal.Body>
                    {viewListaExterna ? (
                        <DataGrid
                            rows={listademandaExterna}
                            columns={datagridColunas}
                            onRowClick={handleRowClick}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 7,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                        />
                    ) : (
                        <DataGrid
                            rows={listademandaInterna}
                            columns={datagridColunas}
                            onRowClick={handleRowClick}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 7,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                        />
                    )}
                </Modal.Body>
            </Modal>

            {/* MODAL CONFIRMACÇÂO DE REGISTRO */}
            <Modal
                show={showModalRegister}
                onHide={handleCloseModalRegister}
                centered={true}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div id="modalLogin">
                        <form className="form">
                            <p className="titleForgotPassword">
                                {t("Deseja Registrar Ofício?")}
                            </p>

                            <button
                                className="botao-grid"
                                disabled={loadingButton}
                                type="button"
                                onClick={handleSubmitForm}
                            >
                                {loadingButton ? "Cadastrando..." : "Sim"}
                            </button>
                            <button
                                className="botao-grid"
                                type="button"
                                onClick={handleCloseModalRegister}
                            >
                                {t("Não")}
                            </button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </Col>
    );
}

export default Oficios;

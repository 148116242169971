import { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Api from "../../config/api";
import "./styles.css";
import { Col, Form, Modal, Row, Button } from "react-bootstrap";
import { convertTipoAtendimentoPraData, convertFilantropiaDate } from "../../utils/convertTipoAtendimento";
import Select from "react-select";
import MapModal from "../../components/mapModal";
import MapAtendimentos from "../../components/mapaAtendimentos";

function Atendimentos({
  config,
  toggleCard,
  permissoesUser,
}: {
  config: any;
  toggleCard: any;
  permissoesUser: {
    administrador: boolean;
    atendimentos_create: boolean;
    atendimentos_edit: boolean;
    atendimentos_view: boolean;
    demandas_create: boolean;
    demandas_edit: boolean;
    demandas_view: boolean;
    engajamentos_create: boolean;
    engajamentos_edit: boolean;
    engajamentos_view: boolean;
    id: number;
    id_user: number;
    noticias_create: boolean;
    noticias_edit: boolean;
    noticias_view: boolean;
    notificacoes_create: boolean;
    notificacoes_edit: boolean;
    notificacoes_view: boolean;
    stakeholders_create: boolean;
    stakeholders_edit: boolean;
    stakeholders_view: boolean;
    usuario_create: boolean;
    usuario_edit: boolean;
    visitas_create: boolean;
    visitas_edit: boolean;
    visitas_view: boolean;
    sms_view: boolean;
    sms_create: boolean;
    solicitacoes_view: boolean;
    solicitacoes_edit: boolean;
  };
}) {

  const { t } = useTranslation();

  let userStorage = sessionStorage.getItem("loginUsuario") as string;
  let user = JSON.parse(userStorage);

  let atendimentoStorage = sessionStorage.getItem(
    "atendimentoStorage"
  ) as string;
  let atendimentoPendente = JSON.parse(atendimentoStorage);

  const [showModalRegister, setShowModalRegister] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  //input
  const [descricao, setDescricao] = useState<string>(
    atendimentoPendente?.solicitacao || ""
  );
  const [tratativa, setTratativa] = useState<string>("");
  const [demandante, setDemandante] = useState<string>(
    atendimentoPendente?.nome || ""
  );
  const [contato, setContato] = useState<string>(
    atendimentoPendente?.contato || ""
  );
  const [responsavel, setResponsavel] = useState<string>("");
  const [planoAcao, setPlanoAcao] = useState<string>("");

  //Select
  const [categoria, setCategoria] = useState<string>("");
  const [subCategoria, setSubCategoria] = useState<string>("");
  const [estado, setEstado] = useState<string>(
    atendimentoPendente?.estado || ""
  );
  const [municipio, setMunicipio] = useState<string>(
    atendimentoPendente?.municipio || ""
  );
  const [comunidade, setComunidade] = useState<string>(
    atendimentoPendente?.comunidade || ""
  );
  const [complexidade, setComplexidade] = useState<string>("");
  const [tipoAtendimento, setTipoAtendimento] = useState<string>("");
  const [instituicao, setInstituicao] = useState<string>("");


  const [listaEstados, setListaEstados] = useState<any>([]);
  const [listaMunicipios, setListaMunicipios] = useState<any>([]);
  const [listaComunidades, setListaComunidades] = useState<any>([]);

  //Switch
  const [incidentePotencial, setIncidentePotencial] = useState<boolean>(false);
  const [salvarComoStakeHolder, setSalvarComoStakeHolder] =
    useState<boolean>(false);

  //Date
  const [prazo, setPrazo] = useState<string>("");
  const [solicitacao, setSolicitacao] = useState<string>("");
  const [previsao, setPrevisao] = useState<string>("");
  const [resposta, setResposta] = useState<string>("");
  const [conclusao, setConclusao] = useState<string>("");

  const [registroFotografico, setRegistroFotografico] = useState(null);
  const [toastExibido, setToastExibido] = useState(false);

  const [AllStakeholders, setAllStakeholder] = useState<[]>([]);
  const [stakeholder, setStakeholder] = useState<string>("");
  const [stakeholderText, setStakeholderText] = useState<string>("");
  const [stakeholderTipo, setStakeholderTipo] = useState<string>("");


  // MAPS
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCoordinates, setSelectedCoordinates] = useState<{ lat: number; lng: number } | null>(null);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCoordinatesSelected = (coordinates: { lat: number; lng: number }) => {
    setSelectedCoordinates(coordinates);
  };


  useEffect(() => {
    if (!permissoesUser.atendimentos_create && !toastExibido) {
      toast.error("Você não tem permissão para criar um novo Atendimento!");
      setToastExibido(true);
    }
  }, [permissoesUser.atendimentos_create, toastExibido]);

  useEffect(() => {
    Api.GetLocales().then((data) => {
      setListaEstados(data.localidades.estados);
      setListaMunicipios(data.localidades.municipios);
      setListaComunidades(data.comunidades);
    });

    Api.GetStakeholders(config).then((data) => {
      setAllStakeholder(data);
    })
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoadingButton(true)

    const obj = {
      demandante: demandante,
      contato: contato,
      estado: estado,
      municipio: municipio,
    };

    const formData = new FormData();

    const prazoTipoAtendimento = convertTipoAtendimentoPraData(tipoAtendimento);
    const filantropiaDate = convertFilantropiaDate(subCategoria);

    if (prazoTipoAtendimento.prioritario) {
      const formateDate = new Date(prazoTipoAtendimento.prioritario);
      const dateString = String(formateDate);
      formData.append("dtPrazoRespostaPrioritario", dateString);
    }

    if (prazoTipoAtendimento.padrao) {
      const formateDate = new Date(prazoTipoAtendimento.padrao);
      const dateString = String(formateDate);
      formData.append("dtPrazoConclusaoPadrao", dateString);
    }

    if (filantropiaDate) {
      const formateDate = new Date(filantropiaDate);
      const filantropiaDateString = String(formateDate);
      formData.append("dtPrazoConclusaoFilantropia", filantropiaDateString);
    }

    if (registroFotografico !== null) {
      formData.append("avatar", registroFotografico);
    }

    formData.append("estado", estado);
    formData.append("municipio", municipio);
    formData.append("comunidade", comunidade);
    formData.append("autorPedido", user.nome);

    if (stakeholder === "salvar") {
      formData.append("stakeholder", stakeholderText);
      const bodycreateStake = {
        stakeholder: stakeholderText,
        estado: estado,
        municipio: municipio,
        contato: contato,
        tipo: stakeholderTipo,
        comunidade: comunidade,
        relacionamento: "RC"
      }

      await Api.PostStakeholder(bodycreateStake, config);
    } else {
      formData.append("stakeholder", stakeholder); // virou stakeholder
    }

    formData.append("status", "Em Andamento");
    formData.append("descricao", descricao);
    formData.append("subclasse", subCategoria);
    formData.append("tratativa", tratativa);
    formData.append("categoria", categoria);
    formData.append("incidentePotencial", String(incidentePotencial));
    formData.append("solicitacao", solicitacao);
    formData.append("previsao", previsao);
    formData.append("resposta", resposta);
    formData.append("contato", contato);
    formData.append("responsavel", responsavel);
    formData.append("prazo", prazo);
    formData.append("conclusao", conclusao);
    formData.append("complexidade", complexidade);
    formData.append("tipoAtendimento", tipoAtendimento);
    formData.append("planoAcao", planoAcao);

    selectedCoordinates && formData.append("lat", String(selectedCoordinates?.lat));
    selectedCoordinates && formData.append("lng", String(selectedCoordinates?.lng));

    console.log("instituicao -->", instituicao);
    formData.append("instituicao", instituicao);


    await Api.PostAtendimento(formData, config)
      .then(async (res) => {
        handleCloseModalRegister();
        handleClearFields();
        toast.success(t("Cadastro feito com sucesso!"));
        setLoadingButton(false);
        // if (salvarComoStakeHolder) {
        //   sessionStorage.setItem("stakeholderStorage", JSON.stringify(obj));
        //   toggleCard(4);
        //   setLoadingButton(false);
        // }
      })
      .catch((err) => {
        toast.error(t("Não foi possível fazer o cadastro!"));
        setLoadingButton(false);
      });

  };

  const handleClearFields = () => {
    setDescricao("");
    setTratativa("");
    setDemandante("");
    setContato("");
    setResponsavel("");
    setCategoria("");
    setSubCategoria("");
    setEstado("");
    setMunicipio("");
    setComunidade("");
    setComplexidade("");
    setIncidentePotencial(false);
    setSalvarComoStakeHolder(false);
    setPrazo("");
    setSolicitacao("");
    setPrevisao("");
    setResposta("");
    setConclusao("");
    setTipoAtendimento("");
    setPlanoAcao("");
    setRegistroFotografico(null);
    setStakeholderText("");
    setStakeholderTipo("");
    setStakeholder("")
    setInstituicao("");
    sessionStorage.removeItem("atendimentoStorage");
  };

  const handleCloseModalRegister = () => {
    setShowModalRegister(false);
  };

  const handleOpenModalRegister = () => {
    setShowModalRegister(true);
  };

  const generateOptionsStakeholders = useCallback(() => {
    if (Array.isArray(AllStakeholders)) {
      return [
        { value: "", label: "Selecione Stakeholder..." },
        { value: "salvar", label: "Salvar como Stakeholder" },
        ...AllStakeholders.map(
          ({
            stakeholder,
            estado,
            municipio,
          }: {
            stakeholder: any;
            estado: any;
            municipio: any;
          }) => ({
            value: stakeholder,
            label: `${stakeholder} - ${municipio}`,
          })
        ),
      ];
    }

    return [];
  }, [AllStakeholders]);

  const optionsStakeholders = useMemo(
    () => generateOptionsStakeholders(),
    [generateOptionsStakeholders]
  );

  return (
    <div id="atendimentosPage">
      <div id="atendimentosPageControllers">
        <Col sm={12}>
          <Form
            onReset={handleClearFields}
            encType="multipart/form-data"
          >
            <Col sm={12} className="mt-4 mb-4">
              <Row>
                <Col sm={6}>
                  <Form.Label>{t("Tipo de Atendimento")}</Form.Label>

                  <Form.Select
                    required
                    className="formSelect"
                    // value={tipoAtendimento}
                    onChange={(event) => {
                      setTipoAtendimento(event.target.value);
                    }}
                  >
                    {" "}
                    <option value="">{t("Selecione")}</option>
                    <option value="Regular">{t("Regular")}</option>
                    <option value="Prioritário">{t("Prioritário")}</option>
                  </Form.Select>
                </Col>
                <Col sm={6}>
                  <Form.Label>{t("Plano de Ação")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    className="formInput"
                    value={planoAcao}
                    onChange={(e) => {
                      setPlanoAcao(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={12} className="mt-4 mb-4">
              <Row>
                <Col sm={6}>
                  <Form.Label>{t("Categoria")}</Form.Label>

                  <Form.Select
                    className="formSelect"
                    value={categoria}
                    onChange={(event) => {
                      setCategoria(event.target.value);
                    }}
                  >
                    <option value="">{t("Selecione a Categoria")}</option>
                    <option value="Relacionamento">
                      {t("Relacionamento")}
                    </option>
                    <option value="G. da Propriedade">
                      {t("G. da Propriedade")}
                    </option>
                    <option value="Demanda Ambiental">
                      {t("Demanda Ambiental")}
                    </option>
                    <option value="PAEBM">PAEBM</option>
                    <option value="Outros">{t("Outros")}</option>
                  </Form.Select>
                </Col>
                <Col sm={6}>
                  <Form.Label>{t("Sub-Categoria")}</Form.Label>
                  <Form.Select
                    className="formSelect"
                    value={subCategoria}
                    onChange={(event) => {
                      setSubCategoria(event.target.value);
                    }}
                  >
                    <option value="">{t("Selecione a Sub-Categoria")}</option>

                    {categoria === "Relacionamento" ? (
                      <>
                        <option value="Destinação de Resíduos">
                          {t("Destinação de Resíduos")}
                        </option>
                        <option value="Filantropia">{t("Filantropia")}</option>
                        <option value="Patrocínio">{t("Patrocínio")}</option>
                        <option value="Segurança">{t("Segurança")}</option>
                        <option value="Visitas">{t("Visitas")}</option>
                      </>
                    ) : (
                      ""
                    )}

                    {categoria === "G. da Propriedade" ? (
                      <>
                        <option value="Documentação">
                          {t("Documentação")}
                        </option>
                        <option value="Divisas">{t("Divisas")}</option>
                        <option value="Acessos">{t("Acessos")}</option>
                        <option value="Arrendamento / Comodato">
                          {t("Arrendamento / Comodato")}
                        </option>
                        <option value="Invasões de Animais / Terceiros">
                          {t("Invasões de Animais / Terceiros")}
                        </option>
                        <option value="Aquisição de N. Propriedade">
                          {t("Aquisição de N. Propriedade")}
                        </option>
                      </>
                    ) : (
                      ""
                    )}

                    {categoria === "Demanda Ambiental" ? (
                      <>
                        <option value="Água">{t("Água")}</option>
                        <option value="Alagamentos">{t("Alagamentos")}</option>
                        <option value="Queimadas">{t("Queimadas")}</option>
                        <option value="Poeira">{t("Poeira")}</option>
                        <option value="Educação Ambiental">
                          {t("Educação Ambiental")}
                        </option>
                        <option value="Emissões">{t("Emissões")}</option>
                        <option value="Licenciamento">
                          {t("Licenciamento")}
                        </option>
                        <option value="Vegetação">{t("Vegetação")}</option>
                        <option value="Ruídos">{t("Ruídos")}</option>
                      </>
                    ) : (
                      ""
                    )}

                    {categoria === "PAEBM" ? (
                      <>
                        <option value="Acessos">{t("Acessos")}</option>
                        <option value="Rotas de Fuga">
                          {t("Rotas de Fuga")}
                        </option>
                        <option value="Sirenes">{t("Sirenes")}</option>
                        <option value="Água">{t("Água")}</option>
                        <option value="Informações / Documentos">
                          {t("Informações / Documentos")}
                        </option>
                        <option value="Pedidos Diversos">
                          {t("Pedidos Diversos")}
                        </option>
                        <option value="Treinamento / Simulado">
                          {t("Treinamento / Simulado")}
                        </option>
                      </>
                    ) : (
                      ""
                    )}

                    {categoria === "Outros" ? (
                      <>
                        <option value="Danos Materiais">
                          {t("Danos Materiais")}
                        </option>
                        <option value="Empregos">{t("Empregos")}</option>
                        <option value="Outros">{t("Outros")}</option>
                      </>
                    ) : (
                      ""
                    )}
                  </Form.Select>
                </Col>
              </Row>
            </Col>
            <Col sm={12} className="mt-4 mb-4">
              <Row>
                <Col sm={6}>
                  <Form.Label>{t("Estado")}</Form.Label>
                  <Form.Select
                    className="formSelect"
                    required
                    value={estado}
                    onChange={(e) => {
                      setEstado(e.target.value);
                    }}
                  >
                    <option value={""}>{t("Selecione o Estado")}</option>
                    {listaEstados?.map((estado: any) => {
                      return (
                        <option value={estado.sigla}>{estado.nome}</option>
                      );
                    })}
                  </Form.Select>
                </Col>
                <Col sm={6}>
                  <Form.Label>{t("Municipio")}</Form.Label>

                  <Form.Select
                    className="formSelect"
                    value={municipio}
                    onChange={(e) => {
                      setMunicipio(e.target.value);
                    }}
                  >
                    <option value={""}>{t("Selecione o Municipio")}</option>
                    {listaMunicipios[estado]?.map((municipio: any) => {
                      return <option value={municipio}>{municipio}</option>;
                    })}
                  </Form.Select>
                </Col>
              </Row>
            </Col>
            <Col sm={12} className="mt-4 mb-4">
              <Row>
                <Col sm={6}>
                  <Form.Label>{t("Comunidade")}</Form.Label>
                  <Form.Select
                    className="formSelect"
                    value={comunidade}
                    onChange={(e) => {
                      setComunidade(e.target.value);
                    }}
                  >
                    <option value={""}>{t("Selecione a Comunidade")}</option>
                    {listaComunidades[municipio]?.map((comunidade: any) => {
                      return <option value={comunidade}>{comunidade}</option>;
                    })}
                  </Form.Select>
                </Col>
                <Col sm={6}>
                  <Form.Label>{t("Complexidade")}</Form.Label>

                  <Form.Select
                    className="formSelect"
                    value={complexidade}
                    onChange={(event) => {
                      setComplexidade(event.target.value);
                    }}
                  >
                    <option value="">{t("Selecione a Complexidade")}</option>
                    <option value="Alto">{t("Alto")}</option>
                    <option value="Médio">{t("Médio")}</option>
                    <option value="Baixo">{"Baixo"}</option>
                  </Form.Select>
                </Col>
              </Row>
            </Col>
            <Col sm={12} className="mt-4 mb-4">
              <Row>
                <Col sm={6}>
                  <Form.Label>{t("Descrição")}</Form.Label>
                  <Form.Control
                    className="formInput"
                    type={"text"}
                    value={descricao}
                    onChange={(e) => {
                      setDescricao(e.target.value);
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>{t("Tratativa")}</Form.Label>
                  <Form.Control
                    className="formInput"
                    type={"text"}
                    value={tratativa}
                    onChange={(e) => {
                      setTratativa(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={12} className="mt-4 mb-4">
              <Row>
                <Col sm={6} className="mt-4">
                  <Select
                    onChange={(e: any) => {
                      setStakeholder(e.value)
                    }}
                    options={optionsStakeholders}
                    value={optionsStakeholders.find(
                      (option: any) =>
                        option.value === stakeholder
                    )}
                    isSearchable={true}
                    placeholder="Selecione Stakeholder"
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>{t("Contato")}</Form.Label>

                  <Form.Control
                    className="formInput"
                    type={"text"}
                    value={contato}
                    onChange={(e) => {
                      setContato(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            {stakeholder === "salvar" && (
              <Col sm={12} lassName="mt-4 mb-4">
                <Row>
                  <Col sm={6}>
                    <Form.Control
                      className="formInput"
                      type={"text"}
                      required
                      placeholder="Digite o nome do stakeholder"
                      value={stakeholderText}
                      onChange={(e) => {
                        setStakeholderText(e.target.value);
                      }}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Select
                      className="formSelect"
                      value={stakeholderTipo}
                      onChange={(e) => {
                        setStakeholderTipo(e.target.value);
                      }}
                    >
                      <option value={""}>{t("Selecione tipo do stakeholder")}</option>
                      <option value={"Externo"}>{t("Externo")}</option>
                      <option value={"Interno"}>{t("Interno")}</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Col>
            )}

            <Col sm={12} className="mt-4 mb-4">
              <Row>
                <Col sm={6}>
                  <Form.Label>{t("Responsável")}</Form.Label>

                  <Form.Control
                    className="formInput"
                    type={"text"}
                    value={responsavel}
                    onChange={(e) => {
                      setResponsavel(e.target.value);
                    }}
                  />
                </Col>

                <Col sm={6}>
                  <Form.Label>{t("Instituição")}</Form.Label>
                  <Form.Control
                    className="formInput"
                    type={"text"}
                    value={instituicao}
                    onChange={(e) => {
                      setInstituicao(e.target.value);
                    }}
                  />
                </Col>

              </Row>
            </Col>
            <Col sm={12} className="mt-4 mb-4">
              <Row>
                <Col sm={6}>
                  <Form.Label>{t("Prazo")}</Form.Label>

                  <Form.Control
                    className="formInput"
                    type="date"
                    value={prazo}
                    onChange={(e) => {
                      setPrazo(e.target.value);
                    }}
                  />
                </Col>

                <Col sm={6}>
                  <Form.Label>{t("Solicitação")}</Form.Label>

                  <Form.Control
                    className="formInput"
                    type="date"
                    value={solicitacao}
                    onChange={(e) => {
                      setSolicitacao(e.target.value);
                    }}
                  />
                </Col>

              </Row>
            </Col>
            <Col sm={12} className="mt-4 mb-4">
              <Row>
                <Col sm={6}>
                  <Form.Label>{t("Previsão")}</Form.Label>

                  <Form.Control
                    className="formInput"
                    type="date"
                    value={previsao}
                    onChange={(e) => {
                      setPrevisao(e.target.value);
                    }}
                  />
                </Col>

                <Col sm={6}>
                  <Form.Label>{t("Resposta")}</Form.Label>

                  <Form.Control
                    className="formInput"
                    type="date"
                    value={resposta}
                    onChange={(e) => {
                      setResposta(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={12} className="mt-4 mb-4">
              <Row>
                <Col sm={6}>
                  <Form.Label>{t("Conclusão")}</Form.Label>

                  <Form.Control
                    className="formInput"
                    type="date"
                    value={conclusao}
                    onChange={(e) => {
                      setConclusao(e.target.value);
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <Button onClick={handleOpenModal} style={{ width: "100%", marginTop: "5%" }}>Adicionar Coordenadas</Button>
                  <MapModal
                    show={isModalOpen}
                    onClose={handleCloseModal}
                    onCoordinatesSelected={handleCoordinatesSelected}
                  />
                </Col>
                {/* <Col sm={4}>
                  <Form.Check
                    className="formSwitch"
                    type="switch"
                    label={t("Salvar com Stakeholder ?")}
                    checked={salvarComoStakeHolder}
                    onChange={(e) => {
                      setSalvarComoStakeHolder(e.target.checked);
                    }}
                  />
                </Col> */}
              </Row>
            </Col>
            <Col sm={12} className="mt-4 mb-4">
              <Row>
                <Col sm={6} className="labelFotos p-2">
                  <Form.Label>
                    {t(
                      "Foto (Use arquivos .jpg, jpeg, png, e webp com no máximo 2048 kB)"
                    )}
                  </Form.Label>
                </Col>
                <Col sm={4} style={{ marginLeft: "2%" }}>
                  <Form.Check
                    className="formSwitch"
                    type="switch"
                    label={t("Incidente Potencial ?")}
                    checked={incidentePotencial}
                    onChange={(e) => {
                      setIncidentePotencial(e.target.checked);
                    }}
                  />
                </Col>
              </Row>
              <Col sm={6} className="cadastroFotos">
                <Form.Control
                  type="file"
                  accept="image/*"
                  name="avatar"
                  multiple
                  onChange={(e: any) => {
                    setRegistroFotografico(
                      e.target.files[0] ? e.target.files[0] : null
                    );
                  }}
                />
              </Col>
            </Col>

            <Col sm={12}>
              <Row>
                <Col sm={6}>
                  {permissoesUser.atendimentos_create ? (
                    <button
                      className="botao-grid"
                      type="button"
                      onClick={handleOpenModalRegister}
                    >
                      {t("Registrar")}
                    </button>
                  ) : (
                    <button
                      className="botao-grid"
                      onClick={() =>
                        toast.error(
                          "Você não tem permissão para criar um novo Atendimento!"
                        )
                      }
                    >
                      {t("Registrar")}
                    </button>
                  )}
                </Col>
                <Col sm={6}>
                  <button
                    className="botao-grid"
                    type="reset"
                  >
                    {t("Limpar")}
                  </button>
                </Col>
              </Row>
            </Col>
          </Form>

          <Modal
            show={showModalRegister}
            onHide={handleCloseModalRegister}
            centered={true}
          >
            <Modal.Header closeButton></Modal.Header>

            <Modal.Body>
              <div id="modalLogin">
                <form className="form">
                  <p className="titleForgotPassword">
                    {t("Deseja enviar o cadastro?")}
                  </p>
                  {loadingButton ? (
                    <button className="botao-grid" onClick={handleSubmit} type="button" disabled>
                      Cadastrando...
                    </button>
                  ) : (
                    <button className="botao-grid" onClick={handleSubmit} type="button">
                      Sim
                    </button>
                  )}
                  <button className="botao-grid" type="button" onClick={handleCloseModalRegister}>
                    {t("Não")}
                  </button>
                </form>
              </div>
            </Modal.Body>
          </Modal>
        </Col>
      </div>
    </div>
  );
}

export default Atendimentos;

import { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../config/api";
import { Col, Form, Modal, Row, Button } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import Select from "react-select";
import { IoIosAdd } from "react-icons/io";
import "./styles.css";

interface Acao {
    acao: string;
    responsavel: string;
    status: string;
    dataPrazo: Date | undefined;
}

interface Oficio {
    oficioEntrada: string;
    oficioSaida: string;
}

function DemandaInterna({
    listaEstados,
    listaMunicipios,
    listaStakeholders,
    config,
}: {
    listaEstados: any;
    listaMunicipios: any;
    listaStakeholders: any;
    config: any;
}) {
    const { t } = useTranslation();

    const [showModalStake, setShowModalStake] = useState<boolean>(false);
    const [showModalEngajamento, setShowModalEngajamento] =
        useState<boolean>(false);
    const [AllStakeholders, setAllStakeholder] = useState<[]>([]);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [cadastroOficios, setCadastroOficios] = useState<boolean>(false);
    const [oficios, setOficios] = useState([
        { oficioEntrada: "", oficioSaida: "" },
    ]);

    //   ***---  INPUTS DEMANDA INTERNA   ---***
    //inpu
    const [acoes, setAcoes] = useState<Acao[]>([
        { acao: "", responsavel: "", status: "", dataPrazo: undefined },
    ]);

    const [fotoDemandaInterna, setFotoDemandaInterna] = useState<string | null>(
        null
    );
    const [fotoDemandaInterna2, setFotoDemandaInterna2] = useState<
        string | null
    >(null);
    const [categoriaStakeholderTexto, setCategoriaStakeholderTexto] =
        useState<string>("");
    const [tratativa, setTratativa] = useState<string>("");
    const [statusAtendido, setStatusAtendido] = useState<string>("");
    //Select
    const [origem, setOrigem] = useState<string>("");
    const [origemTexto, setOrigemTexto] = useState<string>("");
    const [stakeholderDemandaInterna, setStakeholderDemandaInterna] = useState<
        string | undefined
    >("");
    const [stakeholdersDemandaInterna, setStakeholdersDemandaInterna] =
        useState<any>([{ id: 1, value: "" }]);
    const [estadoDemandaInterna, setEstadoDemandaInterna] = useState<
        string | undefined
    >("");
    const [municipioDemandaInterna, setMunicipioDemandaInterna] = useState<
        string | undefined
    >("");
    const [categoriaStakeholder, setCategoriaStakeholder] =
        useState<string>("");
    const [formaContato, setFormaContato] = useState<string>("");
    const [formaContatoTexto, setFormaContatoTexto] = useState<string>("");
    const [categoriaTema, setCategoriaTema] = useState<string>("");
    const [categoriaTemaTexto, setCategoriaTemaTexto] = useState<string>("");
    const [criticidadeTema, setCriticidadeTema] = useState<string>("");
    const [prioridadeTemaString, setPrioridadeTemaString] =
        useState<string>("");
    const [prioridadeTemaDataString, setPrioridadeTemaDataString] =
        useState<string>("");
    const [descricaoTema, setDescricaoTema] = useState<string>("");
    const [relevancia, setRelevancia] = useState<string>("");
    //Switch
    const [planoAcao, setPlanoAcao] = useState<boolean>(false);
    const [atendida, setAtendida] = useState<boolean>(false);
    //Date
    const [dataEntrada, setDataEntrada] = useState<any>(undefined);
    const [prioridadeTemaDataBanco, setPriotidadeTemaDataBanco] =
        useState<Date | null>();
    const [dataConclusao, setDataConclusao] = useState<Date | null>();
    //Check
    const [areasEnvolvidas, setAreasEnvolvidas] = useState<any>([]);
    const [keyForSelect, setKeyForSelect] = useState<any>();

    const [showModalRegister, setShowModalRegister] = useState<boolean>(false);
    //   ***----------------------------------------***

    //   ***---  INPUTS MODAL REGISTRO DE STAKEHOLDER  ---***
    const [pontosDeInterface, setPontosDeInterface] = useState<string>("");
    const [representacao, setRepresentacao] = useState<string>("");
    const [comentarios, setComentarios] = useState<string>("");
    const [cargo, setCargo] = useState<string>("");
    const [postura, setPostura] = useState<string>("");
    const [influencia, setInfluencia] = useState<string>("");
    const [grauImpacto, setGrauImpacto] = useState<string>("");
    const [criticidade, setCriticidade] = useState<string>("");
    const [categoriaStake, setCategoriaStake] = useState<string>("");
    const [categoriaStakeText, setCategoriaStakeText] = useState<string>("");
    const [nomeStake, setNomeStake] = useState<string>("");

    const [fotoStake, setFotoStake] = useState(null);
    const [interlocutores, setInterlocutores] = useState<string>("");

    //contato
    const [numeroCelular, setNumeroCelular] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [endereco, setEndereco] = useState<string>("");

    const [estadoStake, setEstadoStake] = useState<string>("");
    const [municipioStake, setMunicipioStake] = useState<string>("");
    const [atualizadoEm, setAtualizadoEm] = useState("");
    const [pais, setPais] = useState<string>("");

    const [esferaDemanda, setEsferaDemanda] = useState<string>("");
    const [esferaStake, setEsferaStake] = useState<string>("");
    const [esferaEngaja, setEsferaEngaja] = useState<string>("");

    const [origemEngaja, setOrigemEngaja] = useState<string>();
    const [temaEngaja, setTemaEngaja] = useState<string>();

    //   ***----------------------------------------***

    //   ***---  INPUTS MODAL REGISTRO DE ENGAJAMENTO  ---***
    const [engajementosSalvos, setEngajementosSalvos] = useState([{}]);
    const [assunto, setAssunto] = useState<string>("");
    const [stakeholdersEngajamento, setStakeholdersEngajamento] = useState([
        { id: 1, value: "" },
    ]);
    const [descricao, setDescricao] = useState<string>("");
    const [dataEngajamento, setDataEngajamento] = useState<Date>();
    const [formaDeContato, setFormaDeContato] = useState<string>("");
    const [formaDeContatoText, setFormaDeContatoText] = useState<string>("");
    const [estadoEngajamento, setEstadoEngajamento] = useState<string>("");
    const [municipioEngajamento, setMunicipioEngajamento] =
        useState<string>("");
    const [participantesMosaicEngajamento, setParticipantesMosaicEngajamento] =
        useState<string>("");
    const [dataPrevisaoConclusao, setDataPrevisaoConclusao] =
        useState<Date | null>();
    const [participantesMosaicList, setParticipantesMosaicList] = useState([
        {
            id: 1,
            value: "",
        },
    ]);
    const [
        participantesExternosEngajamento,
        setParticipantesExternosEngajamento,
    ] = useState<string>("");
    const [participantesExternosList, setParticipantesExternosList] = useState([
        {
            id: 1,
            value: "",
        },
    ]);
    const [autorOrigem, setAutorOrigem] = useState<string>("");
    const [acaoEngajamento, setAcaoEngajamento] = useState<string>("");
    const [responsaveisEngajamento, setResponsaveisEngajamentoEngajamento] =
        useState<string>("");
    const [dataPrazoEngajamento, setDataPrazoEngajamento] = useState<Date>();

    const [planoAcaoEngajamento, setPlanoAcaoEngajamento] = useState<boolean>();

    const [fotoEngajamento, setFotoEngajamento] = useState<any>(null);

    // PLANO DE CAAO ENGAJAMENTO
    const [planoAcaoEngaja, setPlanoAcaoEngaja] = useState<boolean>(false);
    const [areasEnvolvidasEngaja, setAreasEnvolvidasEngaja] = useState<any>([]);
    const [acoesEngaja, setAcoesEngaja] = useState<Acao[]>([
        { acao: "", responsavel: "", status: "", dataPrazo: undefined },
    ]);

    //   ***----------------------------------------***

    const handleOpenModalStake = () => {
        setShowModalStake(true);
    };

    const handleCloseModalStake = () => {
        setShowModalStake(false);
    };

    const handleOpenModalEngajamento = () => {
        setShowModalEngajamento(true);
    };

    const handleCloseModalEngajamento = () => {
        setShowModalEngajamento(false);
    };

    const handleOpenModalRegister = () => {
        // VALIDAÇÃO PRA VER SE OS CAMPOS FORAM PREENCHIDOS
        switch (true) {
            case !dataEntrada:
                toast.error("Data de Entrada é obrigatória!");
                break;
            case prioridadeTemaString === "":
                toast.error("Prioridade do Tema é obrigatória!");
                break;
            case !origem:
                toast.error("Campo de Origem é obrigatório!");
                break;
            case !categoriaTema:
                toast.error("Categoria do Tema é obrigatório!");
                break;
            case !estadoDemandaInterna:
                toast.error("Estado é obrigatório!");
                break;
            case !municipioDemandaInterna:
                toast.error("Município é obrigatório!");
                break;
            // case stakeholdersDemandaInterna.length === 1 &&
            //   stakeholdersDemandaInterna[0].id === 1 &&
            //   stakeholdersDemandaInterna[0].value === "":
            //   toast.error("Stakeholder(s) são obrigatórios!");
            //   break;
            case !categoriaStakeholder:
                toast.error("Categoria do(s) Stakeholder(s) é obrigatória!");
                break;
            case !criticidadeTema:
                toast.error("Criticidade do Tema é obrigatória!");
                break;
            case !relevancia:
                toast.error("Relevância é obrigatória!");
                break;
            // case !statusAtendido:
            //   toast.error("Status Atendido é obrigatório!");
            //   break;
            case !descricaoTema:
                toast.error("Tema é obrigatório!");
                break;
            case !tratativa:
                toast.error("Tratativa é obrigatória!");
                break;
            case !dataPrevisaoConclusao:
                toast.error("Data de previsão de conclusão obrigatorio!");
                break;

            default:
                setShowModalRegister(true);
                break;
        }
    };

    const handleCloseModalRegister = () => {
        setShowModalRegister(false);
    };

    const handleSubmitForm = async (event: any) => {
        event.preventDefault();
        setLoadingButton(true);

        const formData = new FormData();
        if (fotoDemandaInterna !== null) {
            formData.append("avatar", fotoDemandaInterna[0]);
            formData.append("avatar2", fotoDemandaInterna[1]);
            formData.append("avatar3", fotoDemandaInterna[2]);
            formData.append("avatar4", fotoDemandaInterna[3]);
        }
        formData.append(
            "dataEntrada",
            dataEntrada ? dataEntrada.toISOString() : ""
        );
        formData.append("origem", origem);
        formData.append(
            "dataPrevisao",
            dataPrevisaoConclusao ? String(dataPrevisaoConclusao) : ""
        );

        const stakeholdersDemandaInternaValues = JSON.stringify(
            stakeholdersDemandaInterna.map(({ value }: { value: any }) => {
                return value;
            })
        );

        if (stakeholdersDemandaInternaValues)
            formData.append(
                "stakeholderDemandaInterna",
                stakeholdersDemandaInternaValues
            );

        if (estadoDemandaInterna)
            formData.append("estadoDemandaInterna", estadoDemandaInterna);
        if (municipioDemandaInterna)
            formData.append("municipioDemandaInterna", municipioDemandaInterna);
        formData.append("categoriaStakeholder", categoriaStakeholder);
        formData.append("categoriaTema", categoriaTema);
        formData.append("criticidadeTema", criticidadeTema);
        formData.append("prioridadeTemaString", prioridadeTemaString);
        formData.append("descricaoTema", descricaoTema);
        formData.append("esfera", esferaDemanda);
        formData.append("atendida", String(atendida));
        formData.append("planoAcao", String(planoAcao));
        formData.append(
            "statusAtendimento",
            typeof dataConclusao === "object" ? "Atendida" : "Em andamento"
        );

        const areasEnvolvidasValue = JSON.stringify([
            areasEnvolvidas.map(({ value }: { value: any }) => {
                return value;
            }),
        ]);
        formData.append("areasEnvolvidas", areasEnvolvidasValue);
        formData.append("acoes", JSON.stringify(acoes));
        formData.append("tratativa", tratativa);
        formData.append("autorOrigem", autorOrigem);
        formData.append(
            "dataConclusao",
            dataConclusao ? dataConclusao.toISOString() : ""
        );

        if (origem === "outros") {
            formData.delete("origem");
            formData.append("origem", origemTexto);
        }
        if (categoriaTema === "outros") {
            formData.delete("categoriaTema");
            formData.append("categoriaTema", categoriaTemaTexto);
        }
        if (categoriaStakeholder === "outros") {
            formData.delete("categoriaStakeholder");
            formData.append("categoriaStakeholder", categoriaStakeholderTexto);
        }
        formData.append("relevancia", relevancia);
        formData.append("engajamentos", JSON.stringify(engajementosSalvos));
        if (fotoEngajamento !== null) {
            formData.append("avatarEngajamento", fotoEngajamento);
        }

        oficios.forEach((oficio, index) => {
            if (oficio?.oficioEntrada && oficio.oficioEntrada !== "") {
                formData.append(
                    `oficio_entrada${index + 1}`,
                    oficio.oficioEntrada
                );
            }

            if (oficio?.oficioSaida && oficio.oficioSaida !== "") {
                formData.append(
                    `oficio_resposta${index + 1}`,
                    oficio.oficioSaida
                );
            }
        });

        await Api.PostDemandaInterna(formData, config)
            .then((res) => {
                toast.success(t("Cadastro feito com sucesso!"));
                if (res) setLoadingButton(false);
            })
            .catch((err) => {
                toast.error(t("Não foi possível fazer o cadastro!"));
                if (err) setLoadingButton(false);
            });

        clearInputsFormDemanda();
        handleCloseModalRegister();
    };

    const inputFileRef = useRef<HTMLInputElement | null>(null);

    const clearInputsFormDemanda = () => {
        setEngajementosSalvos([{}]);
        setDescricao("");
        setAcoes([
            { acao: "", responsavel: "", status: "", dataPrazo: undefined },
        ]);
        setFotoDemandaInterna(null);
        if (inputFileRef.current) {
            inputFileRef.current.value = "";
        }
        setStakeholdersDemandaInterna([{ id: 1, value: "" }]);
        setCategoriaStakeholderTexto("");
        setCategoriaStakeholder("");
        setOrigem("");
        setOrigemTexto("");
        setEsferaDemanda("");
        setStakeholderDemandaInterna("");
        setEstadoDemandaInterna("");
        setMunicipioDemandaInterna("");
        setFormaContato("");
        setFormaContatoTexto("");
        setCategoriaTema("");
        setCategoriaTemaTexto("");
        setCriticidadeTema("");
        setPrioridadeTemaString("Selecione");
        setPrioridadeTemaDataString("");
        setDescricaoTema("");
        setTratativa("");
        setStatusAtendido("");
        setRelevancia("");
        setPlanoAcao(false);
        setDataEntrada(null);
        setPriotidadeTemaDataBanco(null);
        setDataConclusao(null);
        setAreasEnvolvidas([]);
        setFotoEngajamento(null);
        setKeyForSelect(new Date().getTime());
        setDataPrevisaoConclusao(null);
    };

    const handleSubmitFormStake = async (event: any) => {
        event.preventDefault();

        const categoriaValue =
            categoriaStake === "outros" && categoriaStakeText.trim() !== ""
                ? categoriaStakeText
                : categoriaStake;

        const formData = new FormData();
        if (fotoStake !== null) {
            formData.append("avatar", fotoStake);
        }

        formData.append("esfera", esferaStake);
        formData.append("pais", pais);
        formData.append("estado", estadoStake);
        formData.append("municipio", municipioStake);
        formData.append("categoriaStake", categoriaValue);
        formData.append("stakeholder", nomeStake);
        formData.append("cargo", cargo);
        formData.append("contato", numeroCelular);
        formData.append("email", email);
        formData.append("endereco", endereco);
        formData.append("interlocutoresMosaic", interlocutores);
        formData.append("postura", postura);
        formData.append("influencia", influencia);
        formData.append("impacto", grauImpacto);
        formData.append("criticidade", criticidade);
        formData.append("comentarios", comentarios);

        const clearInputs = () => {
            setCategoriaStake("");
            setCategoriaStakeText("");
            setCargo("");
            setPostura("");
            setInfluencia("");
            setEsferaStake("");
            setPais("");
            setGrauImpacto("");
            setCriticidade("");
            setNomeStake("");
            setFotoStake(null);
            setInterlocutores("");
            setNumeroCelular("");
            setEmail("");
            setEndereco("");
            setEstadoStake("");
            setMunicipioStake("");
            setComentarios("");
        };

        await Api.PostStakeholder(formData, config)
            .then((res) => {
                toast.success(t("Cadastro feito com sucesso!"));
                clearInputs();
                handleCloseModalStake();
                atualizarStakeholder();
            })
            .catch((err) => {
                toast.error(t("Não foi possível fazer o cadastro!"));
            });
    };

    const handleSubmitFormEngajamento = async (event: any) => {
        event.preventDefault();

        const participantesMosaicStringfy = JSON.stringify(
            participantesMosaicList.map(({ value }: { value: any }) => {
                return value;
            })
        );

        const participantesExternosStringfy = JSON.stringify(
            participantesExternosList.map(({ value }: { value: any }) => {
                return value;
            })
        );

        const obj = {
            estado: estadoEngajamento,
            municipio: municipioEngajamento,
            data: dataEngajamento,
            stakeholder: stakeholdersEngajamento.map((stake) => stake.value),
            contato:
                formaDeContato === "Outros"
                    ? formaDeContatoText
                    : formaDeContato,
            participantesMosaic: participantesMosaicStringfy,
            participantesExternos: participantesExternosStringfy,
            descricao: descricao,
            planoAcao: planoAcaoEngaja,
            areasEnvolvidas: areasEnvolvidasEngaja,
            acoes: acoesEngaja,
            esfera: esferaEngaja,
            tema: temaEngaja,
            origem: origemEngaja
        };

        setEngajementosSalvos((prevEngajementos) => {
            const novoArray = [...prevEngajementos, obj];
            return novoArray;
        });

        toast.success(t("Engajamento salvo com sucesso!"));

        const clearInputs = () => {
            setEstadoEngajamento("");
            setMunicipioEngajamento("");
            setEsferaStake("");
            setDataEngajamento(undefined);
            setStakeholdersEngajamento([{ id: 1, value: "" }]);
            setFormaDeContato("");
            setFormaDeContatoText("");
            setParticipantesMosaicEngajamento("");
            setParticipantesExternosEngajamento("");
            setDescricao("");
            setAssunto("");
            setOrigemEngaja("");
            setTemaEngaja("");
            setParticipantesMosaicList([
                {
                    id: 1,
                    value: "",
                },
            ]);
            setParticipantesExternosList([
                {
                    id: 1,
                    value: "",
                },
            ]);
            setPlanoAcaoEngaja(false);
            setAreasEnvolvidasEngaja([]);
            setAcoesEngaja([
                { acao: "", responsavel: "", status: "", dataPrazo: undefined },
            ]);
        };

        clearInputs();
    };

    const atualizarStakeholder = async () => {
        const stakeAtualizado = await Api.GetStakeholders(config);
        setAllStakeholder(stakeAtualizado);
    };

    useEffect(() => {
        atualizarStakeholder();
    }, [config]);

    const handleCancelar = (event: any) => {
        event.preventDefault();
        toast.error(t("Cadastro cancelado!"));
        setTimeout(() => {
            window.location.href = "/cadastro_relacionamento";
        }, 500);
    };

    useEffect(() => {
        const calculatePrioridade = (prioridade: string, dataEntrada: any) => {
            let diaDataEntrada = new Date(dataEntrada) || new Date();

            switch (prioridade) {
                case "Urgente":
                    diaDataEntrada.setDate(diaDataEntrada.getDate() + 1);
                    break;
                case "Importante":
                    diaDataEntrada.setDate(diaDataEntrada.getDate() + 7);
                    break;
                case "Média":
                    diaDataEntrada.setDate(diaDataEntrada.getDate() + 15);
                    break;
                case "Baixa":
                    diaDataEntrada.setDate(diaDataEntrada.getDate() + 30);
                    break;
                default:
                    if (!isNaN(diaDataEntrada.getTime())) {
                        diaDataEntrada = new Date();
                    } else {
                        diaDataEntrada = new Date();
                    }
                    break;
            }

            let dia = {
                string: diaDataEntrada
                    ? diaDataEntrada.toLocaleDateString()
                    : "",
                date: diaDataEntrada,
            };

            return dia;
        };

        const { date, string } = calculatePrioridade(
            prioridadeTemaString,
            dataEntrada
        );

        setPriotidadeTemaDataBanco(date);
        setPrioridadeTemaDataString(string);
    }, [prioridadeTemaString]);

    const handleAddStakeholderDemandaInterna = () => {
        const newStakeholder = {
            id: stakeholdersDemandaInterna.length + 1,
            value: "",
        };
        setStakeholdersDemandaInterna([
            ...stakeholdersDemandaInterna,
            newStakeholder,
        ]);
    };

    const handleChangeStakeholderDemandaInterna = (id: any, value: any) => {
        const updatedStakeholders = stakeholdersDemandaInterna.map(
            (stakeholder: any) =>
                stakeholder.id === id ? { ...stakeholder, value } : stakeholder
        );

        setStakeholdersDemandaInterna(updatedStakeholders);
    };

    const handleAddStakeholder = () => {
        const newStakeholder = {
            id: stakeholdersEngajamento.length + 1,
            value: "",
        };
        setStakeholdersEngajamento([
            ...stakeholdersEngajamento,
            newStakeholder,
        ]);
    };

    const handleRemoveStakeholder = (id: any) => {
        const updatedStakeholders = stakeholdersEngajamento.filter(
            (stakeholder: any) => stakeholder.id !== id
        );
        setStakeholdersEngajamento(updatedStakeholders);
    };

    const handleChangeStakeholder = (id: any, value: any) => {
        const updatedStakeholders = stakeholdersEngajamento.map((stakeholder) =>
            stakeholder.id === id ? { ...stakeholder, value } : stakeholder
        );
        setStakeholdersEngajamento(updatedStakeholders);
    };

    const handleRemoveStakeholderDemanda = (id: any) => {
        const updatedStakeholders = stakeholdersDemandaInterna.filter(
            (stakeholder: any) => stakeholder.id !== id
        );
        setStakeholdersDemandaInterna(updatedStakeholders);
    };

    const handleOpemCadastroOficio = () => {
        setCadastroOficios(true);
    };

    const adicionarCampoAcao = () => {
        setAcoes([
            ...acoes,
            { acao: "", responsavel: "", status: "", dataPrazo: undefined },
        ]);
    };

    const removerCampoAcao = (index: number) => {
        const novasAcoes = [...acoes];
        novasAcoes.splice(index, 1);
        setAcoes(novasAcoes);
    };

    const handleChangeAcoes = (
        index: number,
        field: keyof Acao,
        value: string | Date
    ) => {
        const novasAcoes = [...acoes];

        if (field === "dataPrazo") {
            novasAcoes[index][field] =
                typeof value === "string"
                    ? new Date(value + "T00:00:00")
                    : (value as Date);
        } else {
            novasAcoes[index][field] = value as string;
        }

        setAcoes(novasAcoes);
    };

    const handleAddParticipanteMosaic = () => {
        const newParticipanteMosaic = {
            id: participantesMosaicList.length + 1,
            value: participantesMosaicEngajamento,
        };
        setParticipantesMosaicList([
            ...participantesMosaicList,
            newParticipanteMosaic,
        ]);
        setParticipantesMosaicEngajamento("");
    };

    const handleRemoveParticipanteMosaic = (id: number) => {
        const updatedParticipantesMosaic = participantesMosaicList.filter(
            (participanteMosaic) => participanteMosaic.id !== id
        );
        setParticipantesMosaicList(updatedParticipantesMosaic);
    };

    const handleAddParticipanteExternos = () => {
        const newParticipanteExternos = {
            id: participantesExternosList.length + 1,
            value: participantesExternosEngajamento,
        };
        setParticipantesExternosList([
            ...participantesExternosList,
            newParticipanteExternos,
        ]);
        setParticipantesExternosEngajamento("");
    };

    const handleRemoveParticipanteExternos = (id: number) => {
        const updatedParticipantesExternos = participantesExternosList.filter(
            (participanteExternos) => participanteExternos.id !== id
        );
        setParticipantesExternosList(updatedParticipantesExternos);
    };

    const formatDate = (date: any) => {
        if (!date) return "";

        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");

        return `${year}-${month}-${day}`;
    };

    const generateOptionsStakeholders = useCallback(() => {
        if (Array.isArray(AllStakeholders)) {
            return [
                { value: "", label: "Selecione Stakeholder..." },
                ...AllStakeholders.filter(
                    (stakeholder: any) =>
                        municipioDemandaInterna &&
                        stakeholder.municipio === municipioDemandaInterna
                ).map(
                    ({
                        stakeholder,
                        estado,
                        municipio,
                    }: {
                        stakeholder: any;
                        estado: any;
                        municipio: any;
                    }) => ({
                        value: stakeholder,
                        label: `${stakeholder} - ${municipio}`,
                    })
                ),
            ];
        }

        return [];
    }, [AllStakeholders, municipioDemandaInterna]);

    const optionsStakeholders = useMemo(
        () => generateOptionsStakeholders(),
        [generateOptionsStakeholders]
    );

    const optionsStakeholdersEngaja = AllStakeholders.map((stak: any) => {
        return {
            value: stak.stakeholder,
            label: `${stak.stakeholder} - ${stak.municipio}`,
        };
    });

    const optionsEstados = listaEstados.map((nome: any) => ({
        value: nome.sigla,
        label: nome.nome,
    }));

    const optionsAreas = [
        { value: "Operações", label: "Operações" },
        { value: "Projetos", label: "Projetos" },
        { value: "EHS", label: "EHS" },
        { value: "Jurídico", label: "Jurídico" },
        { value: "Tributário", label: "Tributário" },
        {
            value: "Relações com Comunidades",
            label: "Relações com Comunidades",
        },
        { value: "Financeiro", label: "Financeiro" },
        { value: "CSC", label: "CSC" },
        { value: "RH", label: "RH" },
        { value: "Comunicação", label: "Comunicação" },
        { value: "Relações Institucionais", label: "Relações Institucionais" },
        { value: "Relações Governamentais", label: "Relações Governamentais" },
        { value: "Instituto Mosaic", label: "Instituto Mosaic" },
        { value: "Gestão Fundiaria", label: "Gestão Fundiaria" },
    ];

    const handleChangeOficios = (
        index: number,
        field: keyof Oficio,
        value: string | Date
    ) => {
        const novosOficios = [...oficios];
        novosOficios[index][field] = value as string;
        setOficios(novosOficios);
    };

    const removerCampoOficio = (index: number) => {
        const novosOficios = [...oficios];
        novosOficios.splice(index, 1);
        setOficios(novosOficios);
    };

    const adicionarCampoOficio = () => {
        setOficios([...oficios, { oficioEntrada: "", oficioSaida: "" }]);
    };

    const handleChangeAcoesEngaja = (
        index: number,
        field: keyof Acao,
        value: string | Date
    ) => {
        const novasAcoes = [...acoesEngaja];

        if (field === "dataPrazo") {
            novasAcoes[index][field] =
                typeof value === "string"
                    ? new Date(value + "T00:00:00")
                    : (value as Date);
        } else {
            novasAcoes[index][field] = value as string;
        }

        setAcoesEngaja(novasAcoes);
    };

    const adicionarCampoAcaoEngaja = () => {
        if (acoesEngaja.length >= 4) {
            toast.error(
                "Limite de ações por engajamento atingido, maximo 4 ações"
            );
        } else {
            setAcoesEngaja([
                ...acoesEngaja,
                { acao: "", responsavel: "", status: "", dataPrazo: undefined },
            ]);
        }
    };

    const removerCampoAcaoEngaja = (index: number) => {
        const novasAcoes = [...acoesEngaja];
        novasAcoes.splice(index, 1);
        setAcoesEngaja(novasAcoes);
    };

    useEffect(() => {
        if (
            (postura === "Favorável" && influencia === "Média") ||
            (postura === "Favorável" && influencia === "Baixo") ||
            (postura === "Neutro" && influencia === "Baixo")
        ) {
            setCriticidade("Baixo");
        } else if (
            (postura === "Neutro" && influencia === "Alto") ||
            (postura === "Neutro" && influencia === "Média") ||
            (postura === "Contrário" && influencia === "Baixo")
        ) {
            setCriticidade("Médio");
        } else if (
            (postura === "Favorável" && influencia === "Alto") ||
            (postura === "Contrário" && influencia === "Alto") ||
            (postura === "Contrário" && influencia === "Média")
        ) {
            setCriticidade("Alto");
        } else {
            setCriticidade("");
        }
    }, [postura, influencia]);

    return (
        <div className={"atendimentosPage"}>
            {/* {formVisible && ( */}
            <Form
                onSubmit={handleSubmitForm}
                id="atendimentosPageControllers"
                encType="multipart/form-data"
            >
                <Col sm={12}>
                    <Col sm={12} className="mt-4 mb-4">
                        <Row>
                            <Col sm={4}>
                                <Form.Label htmlFor="data-entrada">
                                    {t("Data de entrada")}
                                </Form.Label>
                                <Form.Control
                                    required
                                    value={formatDate(dataEntrada)}
                                    type="date"
                                    id="data-entrada"
                                    onChange={(e) => {
                                        setDataEntrada(
                                            new Date(
                                                e.target.value + " 00:00:00"
                                            )
                                        );
                                    }}
                                />
                            </Col>
                            <Col sm={4}>
                                <Form.Label>
                                    {t("Prioridade do Tema")}
                                </Form.Label>
                                <Form.Select
                                    required
                                    className="formSelect"
                                    value={prioridadeTemaString}
                                    onChange={(event) => {
                                        setPrioridadeTemaString(
                                            event.target.value
                                        );
                                    }}
                                >
                                    <option value="">
                                        {t("Selecione Prioridade")}
                                    </option>
                                    <option value="Urgente">
                                        {t("Urgente (1 dia)")}
                                    </option>
                                    <option value="Importante">
                                        {t("Importante (7 dias)")}
                                    </option>
                                    <option value="Média">
                                        {t("Média (15 dias)")}
                                    </option>
                                    <option value="Baixa">
                                        {t("Baixa (30 dias)")}
                                    </option>
                                </Form.Select>
                            </Col>
                            <Col sm={4}>
                                <Form.Label htmlFor="previsao-conclusao">
                                    {t("Previsão de conclusão")}
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    id="previsao-conclusao"
                                    placeholder="Previsão de conclusão"
                                    onChange={(e) => {
                                        setDataPrevisaoConclusao(
                                            new Date(
                                                e.target.value + " 00:00:00"
                                            )
                                        );
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} className="mt-4 mb-4">
                        <Row>
                            <Col sm={2}>
                                <Form.Label>
                                    {t("Esfera")}
                                </Form.Label>
                                <Form.Select
                                    value={esferaDemanda}
                                    onChange={(e) => {
                                        setEsferaDemanda(e.target.value);
                                    }}
                                >
                                    <option value={""}>
                                        {t("Selecione a Esfera")}
                                    </option>
                                    <option value={"Federal"}>
                                        {t("Federal")}
                                    </option>
                                    <option value={"Estadual"}>
                                        {t("Estadual")}
                                    </option>
                                    <option value={"Municipal"}>
                                        {t("Municipal")}
                                    </option>
                                </Form.Select>
                            </Col>
                            <Col sm={3}>
                                <Form.Label>{t("Estado")}</Form.Label>
                                <Select
                                    key={keyForSelect}
                                    required
                                    onChange={(e: any) => {
                                        setEstadoDemandaInterna(e.value);
                                    }}
                                    value={optionsEstados.find(
                                        (option: any) =>
                                            option.value ===
                                            estadoDemandaInterna
                                    )}
                                    options={optionsEstados}
                                    isSearchable={true}
                                    placeholder="Selecione Estado"
                                />
                            </Col>
                            <Col sm={4}>
                                <Form.Label>{t("Municipio")}</Form.Label>
                                <Select
                                    key={keyForSelect}
                                    required
                                    onChange={(e: any) => {
                                        setMunicipioDemandaInterna(e.value);
                                    }}
                                    value={listaMunicipios[
                                        estadoDemandaInterna
                                            ? estadoDemandaInterna
                                            : ""
                                    ]?.find(
                                        (municipio: any) =>
                                            municipio.value ===
                                            municipioDemandaInterna
                                    )}
                                    options={(
                                        listaMunicipios[
                                        estadoDemandaInterna
                                            ? estadoDemandaInterna
                                            : ""
                                        ] || []
                                    ).map((municipio: any) => ({
                                        value: municipio,
                                        label: municipio,
                                    }))}
                                    isSearchable={true}
                                    placeholder="Selecione Municipio"
                                />
                            </Col>
                            <Col sm={3}>
                                <Form.Label>{t("Origem")}</Form.Label>
                                <Form.Select
                                    required
                                    value={origem}
                                    className="formSelect"
                                    onChange={(e) => {
                                        setOrigem(e.target.value);
                                    }}
                                >
                                    <option value={""}>
                                        {t("Selecione Origem")}
                                    </option>
                                    <option value={"Operações"}>
                                        {t("Operações")}
                                    </option>
                                    <option value={"Projetos"}>
                                        {t("Projetos")}
                                    </option>
                                    <option value={"EHS"}>{t("EHS")}</option>
                                    <option value={"Juridico"}>
                                        {t("Juridico")}
                                    </option>
                                    <option value={"Tributário"}>
                                        {t("Tributário")}
                                    </option>
                                    <option value={"Financeiro"}>
                                        {t("Financeiro")}
                                    </option>
                                    <option value={"CSC"}>{t("CSC")}</option>
                                    <option value={"RH"}>{t("RH")}</option>
                                    <option value={"Comunicação"}>
                                        {t("Comunicação")}
                                    </option>
                                    <option value={"Relações Institucionais"}>
                                        {t("Relações Institucionais")}
                                    </option>
                                    <option value={"Instituto Mosaic"}>
                                        {t("Instituto Mosaic")}
                                    </option>
                                    <option value={"Relações com Comunidades"}>
                                        {t("Relações com Comunidades")}
                                    </option>
                                    <option value={"Relações Governamentais"}>
                                        {t("Relações Governamentais")}
                                    </option>
                                    <option value={"Licenciamento"}>
                                        {t("Licenciamento")}
                                    </option>
                                    <option value={"outros"}>
                                        {t("Outros")}
                                    </option>
                                </Form.Select>
                            </Col>
                            {origem === "outros" && (
                                <Col sm={3} className="mt-4 mb-4">
                                    <Row>
                                        <Col>
                                            <Form.Control
                                                required
                                                className="formInput"
                                                type={"text"}
                                                placeholder="Digite Origem"
                                                value={origemTexto}
                                                onChange={(e) => {
                                                    setOrigemTexto(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col sm={12} className="mt-4 mb-4">
                        <Row>
                            <Col sm={4}>
                                <Form.Label>{t("Autor Origem")}</Form.Label>
                                <Form.Control
                                    className="formInput"
                                    type={"text"}
                                    placeholder="Digite Autor Origem"
                                    value={autorOrigem}
                                    onChange={(e) => {
                                        setAutorOrigem(e.target.value);
                                    }}
                                />
                            </Col>
                            <Col sm={4}>
                                <Form.Label>
                                    {t("Categoria do Tema")}
                                </Form.Label>
                                <Form.Select
                                    required
                                    value={categoriaTema}
                                    className="formSelect"
                                    onChange={(event) => {
                                        setCategoriaTema(event.target.value);
                                    }}
                                >
                                    <option value="">
                                        {t("Selecione Categoria")}
                                    </option>
                                    <option value="Questões Tributárias">
                                        {t("Questões Tributárias")}
                                    </option>
                                    <option value="Questões Ambientais">
                                        {t("Questões Ambientais")}
                                    </option>
                                    <option value="Questões Fundiárias">
                                        {t("Questões Fundiárias")}
                                    </option>
                                    <option value="Licenciamento">
                                        {t("Licenciamento")}
                                    </option>
                                    <option value="Continuidade Operacional">
                                        {t("Continuidade Operacional")}
                                    </option>
                                    <option value="Infraestrutura">
                                        {t("Infraestrutura")}
                                    </option>
                                    <option value="Empregabilidade e Capacitação">
                                        {t("Empregabilidade e Capacitação")}
                                    </option>
                                    <option value="Programa Instituto Mosaic">
                                        {t("Programa Instituto Mosaic")}
                                    </option>
                                    <option value="Relacionamento Institucional">
                                        {t("Relacionamento Institucional")}
                                    </option>
                                    <option value="Doações">
                                        {t("Doações")}
                                    </option>
                                    <option value="Patrocínio">
                                        {t("Patrocínio")}
                                    </option>
                                    <option value="outros">
                                        {t("Outros")}
                                    </option>
                                </Form.Select>
                            </Col>
                            <Col sm={4}>
                                <Form.Label>{t("Tema")}</Form.Label>
                                <Form.Control
                                    required
                                    className="formInput"
                                    type={"text"}
                                    value={descricaoTema}
                                    onChange={(e) => {
                                        setDescricaoTema(e.target.value);
                                    }}
                                />
                            </Col>
                            {categoriaTema === "outros" && (
                                <Col sm={6} className="mt-4 mb-4">
                                    <Row>
                                        <Col>
                                            <Form.Control
                                                required
                                                className="formInput"
                                                type={"text"}
                                                placeholder="Digite a Categoria do Tema"
                                                value={categoriaTemaTexto}
                                                onChange={(e) => {
                                                    setCategoriaTemaTexto(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </Col>

                    <Col sm={12}>
                        <Row>
                            <Col sm={6}>
                                <Form.Label>
                                    {t("Criticidade do Tema")}
                                </Form.Label>
                                <Form.Select
                                    required
                                    className="formSelect"
                                    value={criticidadeTema}
                                    onChange={(event) => {
                                        setCriticidadeTema(event.target.value);
                                    }}
                                >
                                    <option value="">
                                        {t("Selecione Criticidade")}
                                    </option>
                                    <option value="Alta">{t("Alto")}</option>
                                    <option value="Média">{t("Médio")}</option>
                                    <option value="Baixa">{t("Baixo")}</option>
                                </Form.Select>
                            </Col>
                            <Col sm={6}>
                                <Form.Label>{t("Relevância")}</Form.Label>
                                <Form.Select
                                    required
                                    value={relevancia}
                                    className="formSelect"
                                    onChange={(event) => {
                                        setRelevancia(event.target.value);
                                    }}
                                >
                                    <option value="">
                                        {t("Seleciona Relevância")}
                                    </option>
                                    <option value="Favorável">
                                        {t("Favorável")}
                                    </option>
                                    <option value="Requer Atenção">
                                        {t("Requer Atenção")}
                                    </option>
                                    <option value="Neutro">
                                        {t("Neutro")}
                                    </option>
                                    <option value="Contrário">
                                        {t("Contrário")}
                                    </option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12} className="mt-3">
                        <Form.Label>{t("Tratativa")}</Form.Label>
                        <Form.Control
                            required
                            className="formInput"
                            type={"text"}
                            as="textarea"
                            value={tratativa}
                            onChange={(e) => {
                                setTratativa(e.target.value);
                            }}
                        />
                    </Col>

                    <Col sm={12} className="mt-4">
                        <Row>
                            <Col sm={4}>
                                <Form.Label>
                                    {t("Categoria do(s) stakeholder(s)")}
                                </Form.Label>
                                <Form.Select
                                    required
                                    value={categoriaStakeholder}
                                    className="formSelect"
                                    onChange={(event) => {
                                        setCategoriaStakeholder(
                                            event.target.value
                                        );
                                    }}
                                >
                                    <option value={""}>
                                        {t("Selecione Categoria")}
                                    </option>

                                    <option
                                        value={
                                            "Poder Público Municipal - Secretaria"
                                        }
                                    >
                                        {t(
                                            "Poder Público Municipal - Secretaria"
                                        )}
                                    </option>
                                    <option
                                        value={
                                            "Poder Público Municipal - Prefeitura"
                                        }
                                    >
                                        {t(
                                            "Poder Público Municipal - Prefeitura"
                                        )}
                                    </option>
                                    <option
                                        value={
                                            "Poder Público Municipal - Autarquia"
                                        }
                                    >
                                        {t(
                                            "Poder Público Municipal - Autarquia"
                                        )}
                                    </option>
                                    <option
                                        value={
                                            "Poder Público Municipal - Câmara de Vereadores"
                                        }
                                    >
                                        {t(
                                            "Poder Público Municipal - Câmara de Vereadores"
                                        )}
                                    </option>
                                    <option value={"Poder Público Estadual"}>
                                        {t("Poder Público Estadual")}
                                    </option>
                                    <option value={"Poder Público Federal"}>
                                        {t("Poder Público Federal")}
                                    </option>
                                    <option value={"Ministério Público"}>
                                        {t("Ministério Público")}
                                    </option>
                                    <option value={"Entidades Setoriais"}>
                                        {t("Entidades Setoriais")}
                                    </option>
                                    <option
                                        value={"Entidades do Terceiro Setor"}
                                    >
                                        {t("Entidades do Terceiro Setor")}
                                    </option>
                                    <option value={"Setor Privado"}>
                                        {t("Setor Privado")}
                                    </option>
                                    <option value={"Academia"}>
                                        {t("Academia")}
                                    </option>
                                    <option value={"outros"}>
                                        {t("Outros")}
                                    </option>
                                </Form.Select>
                            </Col>
                            <Col sm={4}>
                                <Form.Label>Stakeholder</Form.Label>
                                {Array.isArray(stakeholdersDemandaInterna) &&
                                    stakeholdersDemandaInterna.map(
                                        (stakeholder: any, index: any) => (
                                            <div
                                                key={stakeholder.id}
                                                className="mb-2"
                                            >
                                                {index > 0 ? (
                                                    <Row>
                                                        <Col
                                                            sm={10}
                                                            className="pr-0"
                                                        >
                                                            <Select
                                                                onChange={(
                                                                    e: any
                                                                ) =>
                                                                    handleChangeStakeholderDemandaInterna(
                                                                        stakeholder.id,
                                                                        e.value
                                                                    )
                                                                }
                                                                options={
                                                                    optionsStakeholdersEngaja
                                                                }
                                                                value={optionsStakeholders.find(
                                                                    (
                                                                        option: any
                                                                    ) =>
                                                                        option.value ===
                                                                        stakeholder.value
                                                                )}
                                                                isSearchable={
                                                                    true
                                                                }
                                                                placeholder="Selecione Stakeholder"
                                                            />
                                                        </Col>
                                                        <Col
                                                            sm={2}
                                                            className="pl-1"
                                                        >
                                                            <button
                                                                type="button"
                                                                className="button-excluir-stake"
                                                                onClick={() =>
                                                                    handleRemoveStakeholderDemanda(
                                                                        stakeholder.id
                                                                    )
                                                                }
                                                            >
                                                                <FaTimes />
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col
                                                            sm={12}
                                                            className="pr-0"
                                                        >
                                                            <Select
                                                                onChange={(
                                                                    e: any
                                                                ) =>
                                                                    handleChangeStakeholderDemandaInterna(
                                                                        stakeholder.id,
                                                                        e.value
                                                                    )
                                                                }
                                                                options={
                                                                    optionsStakeholdersEngaja
                                                                }
                                                                value={optionsStakeholders.find(
                                                                    (
                                                                        option: any
                                                                    ) =>
                                                                        option.value ===
                                                                        stakeholder.value
                                                                )}
                                                                isSearchable={
                                                                    true
                                                                }
                                                                placeholder="Selecione Stakeholder"
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                                {/* <Row>
                                                    <Col sm={10}>
                                                        <Select
                                                        onChange={(e: any) => {
                                                            handleChangeStakeholderDemandaInterna(
                                                            stakeholder.id,
                                                            e.value
                                                            );
                                                        }}
                                                        options={optionsStakeholders}
                                                        value={optionsStakeholders.find(
                                                            (option: any) =>
                                                            option.value === stakeholder.value
                                                        )}
                                                        isSearchable={true}
                                                        placeholder="Selecione Stakeholder"
                                                        />
                                                    </Col>
                                                    <Col sm={2}>
                                                        {index > 0 && (
                                                        <button
                                                            type="button"
                                                            className="button-excluir-stake"
                                                            onClick={() =>
                                                            handleRemoveStakeholderDemanda(stakeholder.id)
                                                            }
                                                        >
                                                            <FaTimes />
                                                        </button>
                                                        )}
                                                    </Col>
                                                    </Row> */}
                                            </div>
                                        )
                                    )}
                            </Col>
                            <Col sm={1} className="margin-buttons mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={{ width: "100%" }}
                                    onClick={handleAddStakeholderDemandaInterna}
                                >
                                    <IoIosAdd />
                                </button>
                            </Col>
                            <Col sm={3} className="margin-buttons mt-4">
                                {/* <Form.Label>{t("Cadastrar")}</Form.Label> */}
                                <div>
                                    <button
                                        className="btn btn-primary"
                                        style={{ width: "100%" }}
                                        type="button"
                                        onClick={handleOpenModalStake}
                                    >
                                        {t("Cadastrar Stakeholder")}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} className="mb-4">
                        <Row>
                            {categoriaStakeholder === "outros" && (
                                <Col sm={12} className="mb-4">
                                    <Row>
                                        <Col>
                                            <Form.Control
                                                required
                                                className="formInput"
                                                type={"text"}
                                                placeholder="Digite categoria de stakeholder"
                                                value={
                                                    categoriaStakeholderTexto
                                                }
                                                onChange={(e) => {
                                                    setCategoriaStakeholderTexto(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </Col>

                    <Col sm={12} className="mb-4">
                        <Row>
                            <button
                                type="button"
                                className="btn btn-primary"
                                style={{ width: "100%" }}
                                onClick={handleOpemCadastroOficio}
                            >
                                Cadastrar Oficio
                            </button>
                        </Row>
                    </Col>

                    {cadastroOficios && (
                        <div>
                            {oficios.map((oficio, index) => (
                                <div key={index}>
                                    <Row className="mt-3">
                                        <Col sm={6}>
                                            <Form.Label>{`Ofício de Entrada ${index + 1
                                                }:`}</Form.Label>
                                            <div className="cadastroFotos">
                                                <Form.Control
                                                    type="file"
                                                    name={`oficio_entrada_${index}`}
                                                    multiple
                                                    className="inputFoto "
                                                    onChange={(e: any) => {
                                                        handleChangeOficios(
                                                            index,
                                                            "oficioEntrada",
                                                            e.target.files[0]
                                                                ? e.target
                                                                    .files[0]
                                                                : null
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Label>{`Ofício de Resposta ${index + 1
                                                }:`}</Form.Label>
                                            <div className="cadastroFotos">
                                                <Form.Control
                                                    type="file"
                                                    name={`oficio_resposta_${index}`}
                                                    multiple
                                                    className="inputFoto"
                                                    onChange={(e: any) => {
                                                        handleChangeOficios(
                                                            index,
                                                            "oficioSaida",
                                                            e.target.files[0]
                                                                ? e.target
                                                                    .files[0]
                                                                : null
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Col>
                                        {index > 0 && (
                                            <Row>
                                                <Col sm={12}>
                                                    <Button
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        variant="danger mt-3 btn-sm"
                                                        onClick={() =>
                                                            removerCampoOficio(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        Remover ofício
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                </div>
                            ))}
                            <Col sm={12} className="mt-3">
                                <Button
                                    className="btn btn-primary btn-sm"
                                    style={{ width: "100%" }}
                                    onClick={adicionarCampoOficio}
                                >
                                    Adicionar ofício
                                </Button>
                            </Col>
                        </div>
                    )}

                    <Col sm={12} className="mt-4 mb-4">
                        <Row>
                            <Col sm={4}>
                                <Form.Label>
                                    {t("Gerar Engajamento?")}
                                </Form.Label>
                                <div>
                                    <button
                                        className="btn btn-primary"
                                        style={{ width: "100%" }}
                                        type="button"
                                        onClick={handleOpenModalEngajamento}
                                    >
                                        {t("Gerar Engajamento")}
                                    </button>
                                </div>
                                <Form.Label>
                                    <strong>
                                        {engajementosSalvos.length - 1}{" "}
                                        Engajamento(s) salvos
                                    </strong>
                                </Form.Label>
                            </Col>
                            <Col sm={5}>
                                <Form.Label>
                                    {t("Data de Conclusão")}
                                </Form.Label>
                                <Form.Control
                                    className="formInput"
                                    type="date"
                                    onChange={(e) => {
                                        setDataConclusao(
                                            new Date(e.target.value)
                                        );
                                    }}
                                />
                            </Col>
                            <Col sm={3}>
                                <Row>
                                    <Form.Check
                                        className="mt-2"
                                        type="switch"
                                        id="oficioSwitch"
                                        style={{ marginLeft: "5%" }}
                                        label="Demanda atendida?"
                                        checked={atendida}
                                        onChange={() => setAtendida(!atendida)}
                                    />
                                </Row>
                                <Row>
                                    <Form.Check
                                        className="formSwitch mt-2"
                                        type="switch"
                                        style={{ marginLeft: "5%" }}
                                        label={t("Plano de Ação?")}
                                        checked={planoAcao}
                                        onChange={(e) => {
                                            setPlanoAcao(e.target.checked);
                                        }}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    {planoAcao && (
                        <div>
                            <Col sm={12} className="mt-4 mb-4">
                                <Row>
                                    <Col sm={12}>
                                        <Form.Label>
                                            {t("Áreas Envolvidas")}
                                        </Form.Label>
                                        <Form.Group as={Row}>
                                            <Col>
                                                <Row>
                                                    <Select
                                                        isMulti
                                                        options={optionsAreas}
                                                        value={areasEnvolvidas}
                                                        onChange={(e) => {
                                                            setAreasEnvolvidas(
                                                                e
                                                            );
                                                        }}
                                                        placeholder="Selecione"
                                                    />
                                                </Row>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12} className="mt-4 mb-4">
                                <Row>
                                    {acoes.map((acao, index) => (
                                        <div key={index}>
                                            <Col sm={12} className="mt-4 mb-4">
                                                <Row>
                                                    <Col sm={6}>
                                                        <Form.Label>
                                                            {t(
                                                                `Ação ${index + 1
                                                                }`
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="formInput"
                                                            type="text"
                                                            value={acao.acao}
                                                            onChange={(e) =>
                                                                handleChangeAcoes(
                                                                    index,
                                                                    "acao",
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Form.Label>
                                                            {t("Responsável")}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="formInput"
                                                            type="text"
                                                            value={
                                                                acao.responsavel
                                                            }
                                                            onChange={(e) =>
                                                                handleChangeAcoes(
                                                                    index,
                                                                    "responsavel",
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={12} className="mt-4 mb-4">
                                                <Row>
                                                    <Col sm={6}>
                                                        <Form.Label>
                                                            {t("Prazo")}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="formInput"
                                                            type="date"
                                                            value={
                                                                (acao.dataPrazo
                                                                    ? new Date(
                                                                        acao.dataPrazo
                                                                    )
                                                                        .toISOString()
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                    : "") || ""
                                                            }
                                                            onChange={(e) =>
                                                                handleChangeAcoes(
                                                                    index,
                                                                    "dataPrazo",
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Form.Label>
                                                            {t("Status")}
                                                        </Form.Label>
                                                        <Form.Select
                                                            className="formSelect"
                                                            value={acao.status}
                                                            onChange={(e) =>
                                                                handleChangeAcoes(
                                                                    index,
                                                                    "status",
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            <option>
                                                                {t(
                                                                    "Selecionar Status"
                                                                )}
                                                            </option>
                                                            <option>
                                                                {t(
                                                                    "Não Iniciado"
                                                                )}
                                                            </option>
                                                            <option>
                                                                {t(
                                                                    "Em Andamento"
                                                                )}
                                                            </option>
                                                            <option>
                                                                {t("Concluído")}
                                                            </option>
                                                        </Form.Select>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={12}>
                                                {index > 0 && (
                                                    <Row>
                                                        <Col sm={12}>
                                                            <Button
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                variant="danger mt-2 mb-3"
                                                                onClick={() =>
                                                                    removerCampoAcao(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                Remover Ação
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Col>
                                        </div>
                                    ))}
                                </Row>
                                <Col sm={12}>
                                    <button
                                        className="btn btn-primary"
                                        style={{ width: "100%" }}
                                        type="button"
                                        onClick={adicionarCampoAcao}
                                    >
                                        {t("Adicionar Ação")}
                                    </button>
                                </Col>
                            </Col>
                        </div>
                    )}
                    <Col sm={12}>
                        <Col sm={12} className="labelFotos p-2">
                            <Form.Label>
                                {t(
                                    "Selecione até 4 documentos/Fotos, pressionando Ctrl e selecionando os aquivos desejados (Use arquivos .pdf, .jpg, jpeg, png, e webp com no máximo 2048 kB)"
                                )}
                            </Form.Label>
                        </Col>
                        <Col
                            sm={12}
                            className="cadastroFotos"
                            style={{ padding: "1%" }}
                        >
                            <Form.Control
                                type="file"
                                name="avatar"
                                multiple
                                className="inputFoto"
                                style={{ fontSize: "120%" }}
                                onChange={(e: any) => {
                                    setFotoDemandaInterna(
                                        e.target.files ? e.target.files : null
                                    );
                                }}
                                ref={inputFileRef}
                            />
                        </Col>
                    </Col>
                    <Col sm={12}>
                        <Row>
                            <Col sm={4}>
                                <button
                                    className="botao-grid"
                                    type="button"
                                    onClick={handleOpenModalRegister}
                                >
                                    {t("Registrar")}
                                </button>
                            </Col>
                            <Col sm={4}>
                                <button
                                    className="botao-grid"
                                    type="button"
                                    onClick={clearInputsFormDemanda}
                                >
                                    {t("Limpar")}
                                </button>
                            </Col>
                            <Col sm={4}>
                                <button
                                    className="botao-grid"
                                    type="button"
                                    onClick={handleCancelar}
                                >
                                    {t("Cancelar")}
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Col>
            </Form>

            {/* MODAL CONFIRMACÇÂO DE REGISTRO */}
            <Modal
                show={showModalRegister}
                onHide={handleCloseModalRegister}
                centered={true}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div id="modalLogin">
                        <form className="form">
                            <p className="titleForgotPassword">
                                {t("Deseja Registrar Demanda?")}
                            </p>

                            <button
                                className="botao-grid"
                                type="button"
                                disabled={loadingButton}
                                onClick={handleSubmitForm}
                            >
                                {loadingButton ? "Cadastrando..." : t("Sim")}
                            </button>
                            <button
                                className="botao-grid"
                                type="button"
                                onClick={handleCloseModalRegister}
                            >
                                {t("Não")}
                            </button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            {/* MODAL STAKEHOLDER */}
            <Modal
                show={showModalStake}
                onHide={handleCloseModalStake}
                className="modal-lg"
                dialogClassName="modal-rollover"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Cadastrar Stakeholder")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        onSubmit={handleSubmitFormStake}
                        encType="multipart/form-data"
                    >
                        <Col sm={12} className="mt-3 mb-3">
                            <Row>
                                <Col sm={6}>
                                    <Form.Label style={{ fontSize: "85%" }}>
                                        {t("Esfera")}
                                    </Form.Label>
                                    <Form.Select
                                        value={esferaStake}
                                        onChange={(e) => {
                                            setEsferaStake(e.target.value);
                                        }}
                                    >
                                        <option value={""}>
                                            {t("Selecione a Esfera")}
                                        </option>
                                        <option value={"Federal"}>
                                            {t("Federal")}
                                        </option>
                                        <option value={"Estadual"}>
                                            {t("Estadual")}
                                        </option>
                                        <option value={"Municipal"}>
                                            {t("Municipal")}
                                        </option>
                                    </Form.Select>
                                </Col>
                                <Col sm={6}>
                                    <Form.Label style={{ fontSize: "85%" }}>
                                        {t("País")}
                                    </Form.Label>
                                    <Form.Select
                                        value={pais}
                                        onChange={(e) => {
                                            setPais(e.target.value);
                                        }}
                                    >
                                        <option value={""}>
                                            {t("Selecione o país")}
                                        </option>
                                        <option value={"Brasil"}>
                                            {t("Brasil")}
                                        </option>
                                        <option value={"Paraguai"}>
                                            {t("Paraguai")}
                                        </option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} className="mt-3 mb-3">
                            <Row>
                                <Col sm={6}>
                                    <Form.Label>{t("Estado")}</Form.Label>
                                    <Form.Select
                                        value={estadoStake}
                                        onChange={(e: any) => {
                                            setEstadoStake(e.target.value);
                                        }}
                                    >
                                        <option value={""}>
                                            {t("Selecione o estado")}
                                        </option>
                                        {listaEstados?.map(
                                            (estado: any, i: any) => {
                                                return (
                                                    <option
                                                        key={i}
                                                        value={estado.sigla}
                                                    >
                                                        {estado.nome}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Form.Select>
                                </Col>
                                <Col sm={6}>
                                    <Form.Label style={{ fontSize: "85%" }}>
                                        {t("Municipio")}
                                    </Form.Label>
                                    <Form.Select
                                        value={municipioStake}
                                        onChange={(e) => {
                                            setMunicipioStake(e.target.value);
                                        }}
                                    >
                                        <option value={""}>
                                            {t("Selecione o municipio")}
                                        </option>
                                        {estadoStake &&
                                            listaMunicipios[estadoStake]?.map(
                                                (municipio: any) => (
                                                    <option
                                                        key={municipio}
                                                        value={municipio}
                                                    >
                                                        {municipio}
                                                    </option>
                                                )
                                            )}
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Col>
                        {categoriaStake === "outros" ? (
                            <Col sm={12} className="mt-3 mb-3">
                                <Row>
                                    <Col sm={6}>
                                        <Form.Label style={{ fontSize: "85%" }}>
                                            {t("Categoria do Stakeholder")}
                                        </Form.Label>
                                        <Form.Select
                                            className="formSelect"
                                            value={categoriaStake}
                                            onChange={(e) => {
                                                setCategoriaStake(
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option value="">
                                                {t("Selecione a Categoria")}
                                            </option>
                                            <option
                                                value={
                                                    "Poder Público Municipal - Secretaria"
                                                }
                                            >
                                                {t(
                                                    "Poder Público Municipal - Secretaria"
                                                )}
                                            </option>
                                            <option
                                                value={
                                                    "Poder Público Municipal - Prefeitura"
                                                }
                                            >
                                                {t(
                                                    "Poder Público Municipal - Prefeitura"
                                                )}
                                            </option>
                                            <option
                                                value={
                                                    "Poder Público Municipal - Autarquia"
                                                }
                                            >
                                                {t(
                                                    "Poder Público Municipal - Autarquia"
                                                )}
                                            </option>
                                            <option
                                                value={
                                                    "Poder Público Municipal - Câmara de Vereadores"
                                                }
                                            >
                                                {t(
                                                    "Poder Público Municipal - Câmara de Vereadores"
                                                )}
                                            </option>

                                            <option value="Poder Público Estadual - Executivo">
                                                {t(
                                                    "Poder Público Estadual - Executivo"
                                                )}
                                            </option>
                                            <option value="Poder Público Estadual - Legislativo">
                                                {t(
                                                    "Poder Público Estadual - Legislativo"
                                                )}
                                            </option>
                                            <option value="Poder Público Estadual - Judiciário">
                                                {t(
                                                    "Poder Público Estadual - Judiciário"
                                                )}
                                            </option>
                                            <option
                                                value={
                                                    "Poder Público Federal- Executivo"
                                                }
                                            >
                                                {t(
                                                    "Poder Público Federal- Executivo"
                                                )}
                                            </option>
                                            <option
                                                value={
                                                    "Poder Público Federal - Legislativo"
                                                }
                                            >
                                                {t(
                                                    "Poder Público Federal - Legislativo"
                                                )}
                                            </option>
                                            <option
                                                value={
                                                    "Poder Público Federal - Judiciário"
                                                }
                                            >
                                                {t(
                                                    "Poder Público Federal - Judiciário"
                                                )}
                                            </option>
                                            <option value="Ministério Público">
                                                {t("Ministério Público")}
                                            </option>
                                            <option value="Entidades Filantrópicas">
                                                {t("Entidades Filantrópicas")}
                                            </option>
                                            <option
                                                value={
                                                    "Órgão de Defesa e Emergência"
                                                }
                                            >
                                                {t(
                                                    "Órgão de Defesa e Emergência"
                                                )}
                                            </option>
                                            <option
                                                value={
                                                    "Entidades Setoriais e Associações"
                                                }
                                            >
                                                {t(
                                                    "Entidades Setoriais e Associações"
                                                )}
                                            </option>
                                            <option value="Cooperativas">
                                                {t("Cooperativas")}
                                            </option>
                                            <option
                                                value={
                                                    "Entidades do Terceiro Setor"
                                                }
                                            >
                                                {t(
                                                    "Entidades do Terceiro Setor"
                                                )}
                                            </option>
                                            <option value={"Setor Privado"}>
                                                {t("Setor Privado")}
                                            </option>
                                            <option value={"Academia"}>
                                                {t("Academia")}
                                            </option>
                                            <option value={"outros"}>
                                                {t("Outros")}
                                            </option>
                                        </Form.Select>
                                    </Col>

                                    <Col sm={6}>
                                        <Form.Label style={{ fontSize: "85%" }}>
                                            {t("Digite a Categoria")}
                                        </Form.Label>
                                        <Form.Control
                                            className="formInput"
                                            type="text"
                                            placeholder="Digite a Categoria"
                                            value={categoriaStakeText}
                                            onChange={(e) => {
                                                setCategoriaStakeText(
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Col>

                                    <Col sm={6}>
                                        <Form.Label style={{ fontSize: "85%" }}>
                                            {t("Nome")}
                                        </Form.Label>
                                        <Form.Control
                                            className="formInput"
                                            type="text"
                                            placeholder="Digite o Nome"
                                            value={nomeStake}
                                            onChange={(e) => {
                                                setNomeStake(e.target.value);
                                            }}
                                        />
                                    </Col>

                                    <Col sm={6}>
                                        <Form.Label style={{ fontSize: "85%" }}>
                                            {t("Cargo")}
                                        </Form.Label>
                                        <Form.Control
                                            className="formInput"
                                            type="text"
                                            placeholder="Digite o Cargo"
                                            value={cargo}
                                            onChange={(e) => {
                                                setCargo(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        ) : (
                            <Col sm={12} className="mt-3 mb-3">
                                <Row>
                                    <Col sm={4}>
                                        <Form.Label style={{ fontSize: "85%" }}>
                                            {t("Categoria do Stakeholder")}
                                        </Form.Label>
                                        <Form.Select
                                            className="formSelect"
                                            value={categoriaStake}
                                            onChange={(e) => {
                                                setCategoriaStake(
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option value="">
                                                {t("Selecione a Categoria")}
                                            </option>
                                            <option
                                                value={
                                                    "Poder Público Municipal - Secretaria"
                                                }
                                            >
                                                {t(
                                                    "Poder Público Municipal - Secretaria"
                                                )}
                                            </option>
                                            <option
                                                value={
                                                    "Poder Público Municipal - Prefeitura"
                                                }
                                            >
                                                {t(
                                                    "Poder Público Municipal - Prefeitura"
                                                )}
                                            </option>
                                            <option
                                                value={
                                                    "Poder Público Municipal - Autarquia"
                                                }
                                            >
                                                {t(
                                                    "Poder Público Municipal - Autarquia"
                                                )}
                                            </option>
                                            <option
                                                value={
                                                    "Poder Público Municipal - Câmara de Vereadores"
                                                }
                                            >
                                                {t(
                                                    "Poder Público Municipal - Câmara de Vereadores"
                                                )}
                                            </option>

                                            <option value="Poder Público Estadual - Executivo">
                                                {t(
                                                    "Poder Público Estadual - Executivo"
                                                )}
                                            </option>
                                            <option value="Poder Público Estadual - Legislativo">
                                                {t(
                                                    "Poder Público Estadual - Legislativo"
                                                )}
                                            </option>
                                            <option value="Poder Público Estadual - Judiciário">
                                                {t(
                                                    "Poder Público Estadual - Judiciário"
                                                )}
                                            </option>
                                            <option
                                                value={
                                                    "Poder Público Federal- Executivo"
                                                }
                                            >
                                                {t(
                                                    "Poder Público Federal- Executivo"
                                                )}
                                            </option>
                                            <option
                                                value={
                                                    "Poder Público Federal - Legislativo"
                                                }
                                            >
                                                {t(
                                                    "Poder Público Federal - Legislativo"
                                                )}
                                            </option>
                                            <option
                                                value={
                                                    "Poder Público Federal - Judiciário"
                                                }
                                            >
                                                {t(
                                                    "Poder Público Federal - Judiciário"
                                                )}
                                            </option>
                                            <option value="Ministério Público">
                                                {t("Ministério Público")}
                                            </option>
                                            <option value="Entidades Filantrópicas">
                                                {t("Entidades Filantrópicas")}
                                            </option>
                                            <option
                                                value={
                                                    "Órgão de Defesa e Emergência"
                                                }
                                            >
                                                {t(
                                                    "Órgão de Defesa e Emergência"
                                                )}
                                            </option>
                                            <option
                                                value={
                                                    "Entidades Setoriais e Associações"
                                                }
                                            >
                                                {t(
                                                    "Entidades Setoriais e Associações"
                                                )}
                                            </option>
                                            <option value="Cooperativas">
                                                {t("Cooperativas")}
                                            </option>
                                            <option
                                                value={
                                                    "Entidades do Terceiro Setor"
                                                }
                                            >
                                                {t(
                                                    "Entidades do Terceiro Setor"
                                                )}
                                            </option>
                                            <option value={"Setor Privado"}>
                                                {t("Setor Privado")}
                                            </option>
                                            <option value={"Academia"}>
                                                {t("Academia")}
                                            </option>
                                            <option value={"outros"}>
                                                {t("Outros")}
                                            </option>
                                        </Form.Select>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Label style={{ fontSize: "85%" }}>
                                            {t("Nome")}
                                        </Form.Label>
                                        <Form.Control
                                            className="formInput"
                                            type="text"
                                            placeholder="Digite o Nome"
                                            value={nomeStake}
                                            onChange={(e) => {
                                                setNomeStake(e.target.value);
                                            }}
                                        />
                                    </Col>

                                    <Col sm={4}>
                                        <Form.Label style={{ fontSize: "85%" }}>
                                            {t("Cargo")}
                                        </Form.Label>
                                        <Form.Control
                                            className="formInput"
                                            type="text"
                                            placeholder="Digite o Cargo"
                                            value={cargo}
                                            onChange={(e) => {
                                                setCargo(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        )}

                        <Col sm={12}>
                            <span style={{ fontSize: "95%" }}>
                                {t("Contato")}
                            </span>
                            <Row className="mt-2">
                                <Col sm={4}>
                                    <Form.Control
                                        className="formInput"
                                        type="text"
                                        placeholder="Número de celular"
                                        value={numeroCelular}
                                        onChange={(e) => {
                                            setNumeroCelular(e.target.value);
                                        }}
                                    />
                                </Col>

                                <Col sm={4}>
                                    <Form.Control
                                        className="formInput"
                                        type="text"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                    />
                                </Col>

                                <Col sm={4}>
                                    <Form.Control
                                        className="formInput"
                                        type="text"
                                        placeholder="Endereço"
                                        value={endereco}
                                        onChange={(e) => {
                                            setEndereco(e.target.value);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col sm={12} className="mt-3 mb-3">
                            <Row>
                                <Col sm={6}>
                                    <Form.Label style={{ fontSize: "85%" }}>
                                        {t("Interlocutores Mosaic")}
                                    </Form.Label>
                                    <Form.Control
                                        className="formInput"
                                        type="text"
                                        placeholder="Interlocutores Mosaic"
                                        value={interlocutores}
                                        onChange={(e) => {
                                            setInterlocutores(e.target.value);
                                        }}
                                    />
                                </Col>

                                <Col sm={6}>
                                    <Form.Label style={{ fontSize: "85%" }}>
                                        {t("Selecionar Foto")}
                                    </Form.Label>
                                    <div className="cadastroFotos">
                                        <Form.Control
                                            type="file"
                                            name="avatar"
                                            accept="image/*"
                                            multiple
                                            className="inputFoto"
                                            onChange={(e: any) => {
                                                setFotoStake(
                                                    e.target.files[0]
                                                        ? e.target.files[0]
                                                        : null
                                                );
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col sm={12} className="mt-3 mb-3">
                            <Row>
                                <Col sm={4}>
                                    <Form.Label style={{ fontSize: "85%" }}>
                                        {t("Postura")}
                                    </Form.Label>
                                    <Form.Select
                                        value={postura}
                                        onChange={(event) => {
                                            setPostura(event.target.value);
                                        }}
                                    >
                                        <option value="">{t("Postura")}</option>
                                        <option value="Favorável">
                                            {t("Favorável")}
                                        </option>
                                        <option value="Neutro">
                                            {t("Neutro")}
                                        </option>
                                        <option value="Contrário">
                                            {t("Contrário")}
                                        </option>
                                    </Form.Select>
                                </Col>
                                <Col sm={4}>
                                    <Form.Label style={{ fontSize: "85%" }}>
                                        {t("Grau de Influência")}
                                    </Form.Label>
                                    <Form.Select
                                        value={influencia}
                                        onChange={(event) => {
                                            setInfluencia(event.target.value);
                                        }}
                                    >
                                        <option value="">
                                            {t("Grau de Influência")}
                                        </option>
                                        <option value="Alto">
                                            {t("Alto")}
                                        </option>
                                        <option value="Média">
                                            {t("Média")}
                                        </option>
                                        <option value="Baixo">
                                            {t("Baixo")}
                                        </option>
                                    </Form.Select>
                                </Col>
                                <Col sm={4}>
                                    <Form.Label style={{ fontSize: "85%" }}>
                                        {t("Criticidade")}
                                    </Form.Label>
                                    <Form.Select
                                        disabled
                                        value={criticidade}
                                        onChange={(event) => {
                                            setCriticidade(event.target.value);
                                        }}
                                    >
                                        <option value="">
                                            {t("Criticidade")}
                                        </option>
                                        <option value="Alto">
                                            {t("Alto")}
                                        </option>
                                        <option value="Médio">
                                            {t("Médio")}
                                        </option>
                                        <option value="Baixo">
                                            {t("Baixo")}
                                        </option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Col>

                        <Col sm={12} className="mt-3 mb-3">
                            <Form.Label
                                className="mt-4"
                                style={{ fontSize: "85%" }}
                            >
                                {t("Comentarios")}
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Aqui você pode colocar suas impressões, principais temas e histórico do stakeholder"
                                className="formInput"
                                value={comentarios}
                                onChange={(e) => {
                                    setComentarios(e.target.value);
                                }}
                            />
                        </Col>

                        <Col sm={12}>
                            <div
                                style={{
                                    display: "flex",
                                    alignContent: "space-",
                                }}
                            >
                                <Col sm={6}>
                                    <button
                                        className="btn btn-primary"
                                        type={"submit"}
                                        style={{ width: "90%" }}
                                    >
                                        {t("Cadastrar")}
                                    </button>
                                </Col>
                                <Col sm={6}>
                                    <button
                                        className="btn btn-danger"
                                        type="button"
                                        style={{ width: "100%" }}
                                        onClick={handleCloseModalStake}
                                    >
                                        {t("Cancelar")}
                                    </button>
                                </Col>
                            </div>
                        </Col>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* MODAL ENGAJAMENTO */}
            <Modal
                show={showModalEngajamento}
                onHide={handleCloseModalEngajamento}
                className="modal-lg"
                dialogClassName="modal-rollover"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Cadastrar Engajamento")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="content-grid">
                        <Form
                            onSubmit={handleSubmitFormEngajamento}
                            encType="multipart/form-data"
                        >
                            <Col sm={12} className="mt-4 mb-4">
                                <Row>
                                    <Col sm={12}>
                                        <Form.Label>{t("Data")}</Form.Label>
                                        <Form.Control
                                            required
                                            className="formInput"
                                            type={"date"}
                                            value={
                                                dataEngajamento
                                                    ? dataEngajamento
                                                        .toISOString()
                                                        .split("T")[0]
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setDataEngajamento(
                                                    new Date(
                                                        e.target.value +
                                                        " 00:00:00"
                                                    )
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col sm={12} className="mt-4">
                                        <Row>
                                            <Col sm={4}>
                                                <Form.Label style={{ fontSize: "85%" }}>
                                                    {t("Esfera")}
                                                </Form.Label>
                                                <Form.Select
                                                    value={esferaEngaja}
                                                    onChange={(e) => {
                                                        setEsferaEngaja(e.target.value);
                                                    }}
                                                >
                                                    <option value={""}>
                                                        {t("Selecione a Esfera")}
                                                    </option>
                                                    <option value={"Federal"}>
                                                        {t("Federal")}
                                                    </option>
                                                    <option value={"Estadual"}>
                                                        {t("Estadual")}
                                                    </option>
                                                    <option value={"Municipal"}>
                                                        {t("Municipal")}
                                                    </option>
                                                </Form.Select>
                                            </Col>

                                            <Col sm={4}>
                                                <Form.Label>
                                                    {t("Estado")}
                                                </Form.Label>
                                                <Form.Select
                                                    value={estadoEngajamento}
                                                    onChange={(e) => {
                                                        setEstadoEngajamento(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value={""}>
                                                        {t(
                                                            "Selecione o estado"
                                                        )}
                                                    </option>
                                                    {listaEstados?.map(
                                                        (
                                                            estado: any,
                                                            i: any
                                                        ) => {
                                                            return (
                                                                <option
                                                                    key={i}
                                                                    value={
                                                                        estado.sigla
                                                                    }
                                                                >
                                                                    {
                                                                        estado.nome
                                                                    }
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </Form.Select>
                                            </Col>

                                            <Col sm={4}>
                                                <Form.Label>
                                                    {t("Municipio")}
                                                </Form.Label>
                                                <Form.Select
                                                    value={municipioEngajamento}
                                                    onChange={(e) => {
                                                        setMunicipioEngajamento(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value={""}>
                                                        {t(
                                                            "Selecione o municipio"
                                                        )}
                                                    </option>
                                                    {estadoEngajamento &&
                                                        listaMunicipios[
                                                            estadoEngajamento
                                                        ]?.map(
                                                            (
                                                                municipio: any
                                                            ) => (
                                                                <option
                                                                    key={
                                                                        municipio
                                                                    }
                                                                    value={
                                                                        municipio
                                                                    }
                                                                >
                                                                    {municipio}
                                                                </option>
                                                            )
                                                        )}
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col sm={12} className="mt-4 mb-4">
                                <Row>
                                    <Col sm={6}>
                                        <Form.Label>{t("Órgão/Instituição")}</Form.Label>

                                        <Form.Control
                                            required
                                            className="formInput"
                                            type={"text"}
                                            value={origemEngaja}
                                            onChange={(e) => {
                                                setOrigemEngaja(e.target.value);
                                            }}
                                        >

                                        </Form.Control>

                                    </Col>
                                    <Col sm={6}>
                                        <Form.Label>{t("Tema")}</Form.Label>
                                        <Form.Control
                                            required
                                            className="formInput"
                                            type={"text"}
                                            value={temaEngaja}
                                            onChange={(e) => {
                                                setTemaEngaja(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12}>
                                <Form.Label>
                                    {t("Participantes Mosaic")}
                                </Form.Label>
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Control
                                                required
                                                placeholder="Participantes Mosaic"
                                                className="formInput"
                                                value={
                                                    participantesMosaicList[0]
                                                        ?.value || ""
                                                }
                                                type="text"
                                                onChange={(event) => {
                                                    const updatedList = [
                                                        ...participantesMosaicList,
                                                    ];
                                                    updatedList[0] = {
                                                        ...updatedList[0],
                                                        value: event.target
                                                            .value,
                                                    };
                                                    setParticipantesMosaicList(
                                                        updatedList
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <Button
                                                type="button"
                                                className="btn btn-primary"
                                                style={{ width: "100%" }}
                                                onClick={
                                                    handleAddParticipanteMosaic
                                                }
                                            >
                                                {t(
                                                    "Adicionar Participantes Mosaic"
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                {participantesMosaicList
                                    .slice(1)
                                    .map((participanteMosaic, index) => (
                                        <div
                                            key={participanteMosaic.id}
                                            className="mt-2 mb-2"
                                        >
                                            <Row>
                                                <Col sm={11}>
                                                    <Form.Control
                                                        placeholder="Participantes Mosaic"
                                                        className="formInput"
                                                        value={
                                                            participanteMosaic.value
                                                        }
                                                        type="text"
                                                        onChange={(event) => {
                                                            const updatedList =
                                                                participantesMosaicList.map(
                                                                    (item) =>
                                                                        item.id ===
                                                                            participanteMosaic.id
                                                                            ? {
                                                                                ...item,
                                                                                value: event
                                                                                    .target
                                                                                    .value,
                                                                            }
                                                                            : item
                                                                );
                                                            setParticipantesMosaicList(
                                                                updatedList
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm={1}>
                                                    <button
                                                        type="button"
                                                        className="button-excluir-stake"
                                                        onClick={() =>
                                                            handleRemoveParticipanteMosaic(
                                                                participanteMosaic.id
                                                            )
                                                        }
                                                    >
                                                        <FaTimes />
                                                    </button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                            </Col>

                            <Col sm={12} className="mt-4">
                                <Form.Label>
                                    {t("Participantes Externos")}
                                </Form.Label>
                                <Col sm={12}>
                                    <Row className="mb-2">
                                        <Col sm={6}>
                                            <Form.Control
                                                placeholder="Participantes Externos"
                                                className="formInput"
                                                value={
                                                    participantesExternosList[0]
                                                        ?.value || ""
                                                }
                                                type="text"
                                                onChange={(event) => {
                                                    const updatedList = [
                                                        ...participantesExternosList,
                                                    ];
                                                    updatedList[0] = {
                                                        ...updatedList[0],
                                                        value: event.target
                                                            .value,
                                                    };
                                                    setParticipantesExternosList(
                                                        updatedList
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <Button
                                                type="button"
                                                className="btn btn-primary"
                                                style={{ width: "100%" }}
                                                onClick={
                                                    handleAddParticipanteExternos
                                                }
                                            >
                                                {t(
                                                    "Adicionar Participantes Externos"
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                {participantesExternosList
                                    .slice(1)
                                    .map((participanteExternos, index) => (
                                        <div
                                            key={participanteExternos.id}
                                            className="mt-2 mb-2"
                                        >
                                            <Row>
                                                <Col sm={11}>
                                                    <Form.Control
                                                        placeholder="Participantes Externos"
                                                        className="formInput"
                                                        value={
                                                            participanteExternos.value
                                                        }
                                                        type="text"
                                                        onChange={(event) => {
                                                            const updatedList =
                                                                participantesExternosList.map(
                                                                    (item) =>
                                                                        item.id ===
                                                                            participanteExternos.id
                                                                            ? {
                                                                                ...item,
                                                                                value: event
                                                                                    .target
                                                                                    .value,
                                                                            }
                                                                            : item
                                                                );
                                                            setParticipantesExternosList(
                                                                updatedList
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm={1}>
                                                    <button
                                                        type="button"
                                                        className="button-excluir-stake"
                                                        onClick={() =>
                                                            handleRemoveParticipanteExternos(
                                                                participanteExternos.id
                                                            )
                                                        }
                                                    >
                                                        <FaTimes />
                                                    </button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                            </Col>

                            {formaDeContato === "Outros" ? (
                                <div>
                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            <Col sm={6}>
                                                <Form.Label>
                                                    {t("Forma de engajamento")}
                                                </Form.Label>
                                                <Form.Select
                                                    required
                                                    className="inputSelect"
                                                    onChange={(event) => {
                                                        setFormaDeContato(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="">
                                                        {t("Selecione Formato")}
                                                    </option>
                                                    <option value="Virtual">
                                                        {t("Virtual")}
                                                    </option>
                                                    <option value="Presencial">
                                                        {t("Presencial")}
                                                    </option>
                                                    <option value="Ligação Telefônica">
                                                        {t(
                                                            "Ligação Telefônica"
                                                        )}
                                                    </option>
                                                    <option value="Outros">
                                                        {t("Outros")}
                                                    </option>
                                                </Form.Select>
                                            </Col>

                                            <Col sm={6}>
                                                <Form.Label>
                                                    {t("Digite")}
                                                </Form.Label>
                                                <Form.Control
                                                    required
                                                    value={formaDeContatoText}
                                                    className="formInput"
                                                    type="text"
                                                    placeholder="forma de contato"
                                                    onChange={(e) => {
                                                        setFormaDeContatoText(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} className="mt-3">
                                                <Form.Label>
                                                    {t(
                                                        "Selecione foto/documento"
                                                    )}
                                                </Form.Label>
                                                <div
                                                    className="cadastroFotos"
                                                    style={{ padding: "1%" }}
                                                >
                                                    <Form.Control
                                                        type="file"
                                                        name="avatar"
                                                        multiple
                                                        className="inputFoto"
                                                        style={{
                                                            fontSize: "100%",
                                                        }}
                                                        onChange={(e: any) => {
                                                            setFotoEngajamento(
                                                                e.target
                                                                    .files[0]
                                                                    ? e.target
                                                                        .files[0]
                                                                    : null
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>
                            ) : (
                                <Col sm={12} className="mt-4 mb-4">
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Label>
                                                {t("Forma de engajamento")}
                                            </Form.Label>
                                            <Form.Select
                                                required
                                                className="inputSelect"
                                                value={formaDeContato}
                                                onChange={(event) => {
                                                    setFormaDeContato(
                                                        event.target.value
                                                    );
                                                }}
                                            >
                                                <option value="">
                                                    {t("Selecione Formato")}
                                                </option>
                                                <option value="Virtual">
                                                    {t("Virtual")}
                                                </option>
                                                <option value="Presencial">
                                                    {t("Presencial")}
                                                </option>
                                                <option value="Ligação Telefônica">
                                                    {t("Ligação Telefônica")}
                                                </option>
                                                <option value="Outros">
                                                    {t("Outros")}
                                                </option>
                                            </Form.Select>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Label>
                                                {t("Selecione foto/documento")}
                                            </Form.Label>
                                            <div
                                                className="cadastroFotos"
                                                style={{ padding: "1%" }}
                                            >
                                                <Form.Control
                                                    type="file"
                                                    name="avatarEngajamento"
                                                    multiple
                                                    className="inputFoto"
                                                    style={{ fontSize: "100%" }}
                                                    onChange={(e: any) => {
                                                        setFotoEngajamento(
                                                            e.target.files[0]
                                                                ? e.target
                                                                    .files[0]
                                                                : null
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                            <Col sm={12} className="mb-3">
                                <Row>
                                    <Col sm={8}>
                                        <Form.Label>
                                            {t("Resumo Engajamento")}
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            placeholder="Resumo sobre Engajamento"
                                            className="formInput"
                                            maxLength={200}
                                            as="textarea"
                                            type={"text"}
                                            value={descricao}
                                            onChange={(e) => {
                                                setDescricao(e.target.value);
                                            }}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Check
                                            className="formSwitch"
                                            type="switch"
                                            style={{
                                                marginTop: "6%",
                                                marginLeft: "5%",
                                            }}
                                            label={t("Plano de Ação?")}
                                            checked={planoAcaoEngaja}
                                            onChange={(e) => {
                                                setPlanoAcaoEngaja(
                                                    e.target.checked
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            {planoAcaoEngaja && (
                                <div>
                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            <Col sm={12}>
                                                <Form.Label>
                                                    {t("Áreas Envolvidas")}
                                                </Form.Label>
                                                <Form.Group as={Row}>
                                                    <Col>
                                                        <Row>
                                                            <Select
                                                                isMulti
                                                                options={
                                                                    optionsAreas
                                                                }
                                                                value={
                                                                    areasEnvolvidasEngaja
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setAreasEnvolvidasEngaja(
                                                                        e
                                                                    );
                                                                }}
                                                                placeholder="Selecione"
                                                            />
                                                        </Row>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            {acoesEngaja.map((acao, index) => (
                                                <div key={index}>
                                                    <Col
                                                        sm={12}
                                                        className="mt-4 mb-4"
                                                    >
                                                        <Row>
                                                            <Col sm={6}>
                                                                <Form.Label>
                                                                    {t(
                                                                        `Ação ${index +
                                                                        1
                                                                        }`
                                                                    )}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="formInput"
                                                                    type="text"
                                                                    value={
                                                                        acao.acao
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeAcoesEngaja(
                                                                            index,
                                                                            "acao",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col sm={6}>
                                                                <Form.Label>
                                                                    {t(
                                                                        "Responsável"
                                                                    )}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="formInput"
                                                                    type="text"
                                                                    value={
                                                                        acao.responsavel
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeAcoesEngaja(
                                                                            index,
                                                                            "responsavel",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col
                                                        sm={12}
                                                        className="mt-4 mb-4"
                                                    >
                                                        <Row>
                                                            <Col sm={6}>
                                                                <Form.Label>
                                                                    {t("Prazo")}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="formInput"
                                                                    type="date"
                                                                    required
                                                                    value={
                                                                        (acao.dataPrazo
                                                                            ? new Date(
                                                                                acao.dataPrazo
                                                                            )
                                                                                .toISOString()
                                                                                .split(
                                                                                    "T"
                                                                                )[0]
                                                                            : "") ||
                                                                        ""
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeAcoesEngaja(
                                                                            index,
                                                                            "dataPrazo",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col sm={6}>
                                                                <Form.Label>
                                                                    {t(
                                                                        "Status"
                                                                    )}
                                                                </Form.Label>
                                                                <Form.Select
                                                                    className="formSelect"
                                                                    value={
                                                                        acao.status
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeAcoesEngaja(
                                                                            index,
                                                                            "status",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                >
                                                                    <option>
                                                                        {t(
                                                                            "Selecionar Status"
                                                                        )}
                                                                    </option>
                                                                    <option>
                                                                        {t(
                                                                            "Não Iniciado"
                                                                        )}
                                                                    </option>
                                                                    <option>
                                                                        {t(
                                                                            "Em Andamento"
                                                                        )}
                                                                    </option>
                                                                    <option>
                                                                        {t(
                                                                            "Concluído"
                                                                        )}
                                                                    </option>
                                                                </Form.Select>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm={12}>
                                                        {index > 0 && (
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <Button
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                        variant="danger mt-2 mb-3 btn-sm"
                                                                        onClick={() =>
                                                                            removerCampoAcaoEngaja(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        Remover
                                                                        Ação
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Col>
                                                </div>
                                            ))}
                                        </Row>
                                        <Col sm={12}>
                                            <button
                                                className="btn btn-primary btn-sm"
                                                style={{ width: "100%" }}
                                                type="button"
                                                onClick={
                                                    adicionarCampoAcaoEngaja
                                                }
                                            >
                                                {t("Adicionar Ação")}
                                            </button>
                                        </Col>
                                    </Col>
                                </div>
                            )}

                            <Col sm={12}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignContent: "space-",
                                    }}
                                >
                                    <Col sm={6}>
                                        <button
                                            className="btn btn-primary"
                                            style={{ width: "90%" }}
                                            type="submit"
                                        >
                                            {t("Salvar")}
                                        </button>
                                    </Col>
                                    <Col sm={6}>
                                        <button
                                            className="btn btn-danger"
                                            type="reset"
                                            style={{ width: "95%" }}
                                            onClick={
                                                handleCloseModalEngajamento
                                            }
                                        >
                                            {t("Cancelar")}
                                        </button>
                                    </Col>
                                </div>
                            </Col>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default DemandaInterna;

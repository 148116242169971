import { useMemo, useState } from "react";
import "./styles.css";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useTranslation } from "react-i18next";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     "pdfjs-dist/build/pdf.worker.min.mjs",
//     import.meta.url
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


function PdfThumbnail({ file }: { file: string }) {
    const { t } = useTranslation();

    const [pages, setPages] = useState(0);

    const openPdf = () => {
        const iframe = document.createElement("iframe");
        iframe.src = file;
        iframe.height = "100%";
        iframe.width = "100%";
        iframe.style.margin = "0px";
        iframe.style.padding = "0px";
        iframe.style.border = "0px";
        iframe.style.outline = "0px";
        iframe.style.boxSizing = "border-box";

        const newTab = window.open();
        if (!newTab) return;

        const doc = newTab.document;
        doc.body.style.margin = "0px";
        doc.body.style.padding = "0px";
        doc.body.style.width = "100vw";
        doc.body.style.height = "100vh";

        newTab.document.head.title = "Preview Document";
        newTab.document.body.append(iframe);
    };

    const source = useMemo(
        () => (file.startsWith("https://") || file.startsWith("http://") ? { url: file } : file),
        [file]
    );

    const options = useMemo(
        () => ({
            httpHeaders: {
                "Access-Control-Allow-Headers": ["*"],
                "Access-Control-Allow-Methods": ["*"],
                "Access-Control-Allow-Origin": ["*"],
            },
        }),
        []
    );

    return (
        <button className="PdfThumbnailDocumentHolder" onClick={openPdf} type="button">
            <Document
                options={options}
                file={source}
                className={"PdfThumbnailDocument"}
                onLoadSuccess={({ numPages }) => setPages(numPages)}
            >
                <Page pageIndex={0} />
            </Document>

            <span className="PdfThumbnailDocumentPagination">
                {t("Página 1 de pages", { pages })}
            </span>

            <div className="PdfThumbnailDocumentHover">{t("Abrir em uma nova janela")}</div>
        </button>
    );
}

export default PdfThumbnail;

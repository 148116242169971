import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Container, Row, Col, Modal, Card, ListGroup, Form } from 'react-bootstrap';
import Api from "../../config/api";
import { ArrowDownIcon } from "@heroicons/react/24/outline";

interface Comunidade {
    id: number;
    comunidade: string;
    lat: number;
    lng: number;
    unidade: string;
    icone: string;
}

interface Atendimento {
    id: number;
    autor_pedido: string;
    categoria: string;
    complexidade: string;
    comunidade: string;
    contato: string;
    descricao: string;
    dt_conclusao: string | null;
    dt_prazo: string | null;
    dt_prazo_conclusao_filantropia: string | null;
    dt_prazo_conclusao_padrao: string | null;
    dt_prazo_resposta_prioritario: string | null;
    dt_previsao: string | null;
    dt_resposta: string | null;
    dt_solicitacao: string;
    estado: string;
    foto: string;
    incidente_potencial: boolean;
    instituicao: string | null;
    latitude: string | null;
    longitude: string | null;
    municipio: string;
    notificacao_enviada: boolean | null;
    plano_acao: string;
    responsavel: string;
    stakeholder: string;
    status: string;
    subclasse: string;
    tipo_atendimento: string;
    tratativa: string;
    GT?: string | null;
}

interface Coordinates {
    lat: number;
    lng: number;
}

const containerStyle = {
    width: "100%",
    height: "500px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const center = {
    lat: -18.171909,
    lng: -47.777259,
};

const infoWindowStyle = {
    fontSize: '15px',
    maxWidth: '150px',
};

const GOOGLE_API_KEY = "AIzaSyCysGhoOi0XTrKJVPqSfJNZ_04CEOu-a0Y";

const houseIconBase64 = "data:image/svg+xml;base64," + btoa(`
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#00FF00" class="size-6">
  <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
  <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
</svg>
`);

const redPinIconBase64 = "data:image/svg+xml;base64," + btoa(`
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FF0000" class="size-6">
  <path d="M12 2C8.134 2 5 5.134 5 9c0 4 7 13 7 13s7-9 7-13c0-3.866-3.134-7-7-7zm0 11.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z"/>
</svg>
`);

const factoryIconBase64 = "data:image/svg+xml;base64," + btoa(`
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>factory</title><path fill="#FFFF00" d="M4,18V20H8V18H4M4,14V16H14V14H4M10,18V20H14V18H10M16,14V16H20V14H16M16,18V20H20V18H16M2,22V8L7,12V8L12,12V8L17,12L18,2H21L22,12V22H2Z" /></svg>
`);

const buildingIconBase64 = "data:image/svg+xml;base64," + btoa(`
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#00BFFF" class="size-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
</svg>
`);

const icons = [
    { src: houseIconBase64, alt: "Casa" },
    { src: factoryIconBase64, alt: "Fábrica" },
    { src: buildingIconBase64, alt: "Edifício" }
];


interface MapAtendimentosProps {
    onCoordinatesSelected: (coordinates: Coordinates) => void;
}

const MapAtendimentos: React.FC<MapAtendimentosProps> = ({ onCoordinatesSelected }) => {
    const [comunidades, setComunidades] = useState<Comunidade[]>([]);
    const [expandedUnidades, setExpandedUnidades] = useState<Record<string, boolean>>({});
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [addingMarker, setAddingMarker] = useState<boolean>(false);
    const [markingCoordinates, setMarkingCoordinates] = useState<boolean>(false);
    const [mapLoaded, setMapLoaded] = useState<boolean>(false);
    const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
    const [activeMarker, setActiveMarker] = useState<number | null>(null);
    const [savedCoordinates, setSavedCoordinates] = useState<Coordinates | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [communityToDelete, setCommunityToDelete] = useState<number | null>(null);
    const [atendimentos, setAtendimentos] = useState<Atendimento[]>([]);


    // Estados para o modal de adicionar comunidade
    const [showAddCommunityModal, setShowAddCommunityModal] = useState<boolean>(false);
    const [selectedUnidade, setSelectedUnidade] = useState<string>("");
    const [newCommunityName, setNewCommunityName] = useState<string>("");
    const [newUnidade, setNewUnidade] = useState<string>("");
    const [selectedIcon, setSelectedIcon] = useState<string>(houseIconBase64);


    // Estado para coordenadas marcadas
    const [markedCoordinates, setMarkedCoordinates] = useState<Coordinates | null>(null);

    const handleMapClick = (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();

            if (addingMarker) {
                setMarkedCoordinates({ lat, lng });
                setShowAddCommunityModal(true);
                setAddingMarker(false);
            } else if (markingCoordinates) {
                setSavedCoordinates({ lat, lng });
                onCoordinatesSelected({ lat, lng });
                setMarkingCoordinates(false);
            }
        }
    };

    const loadComunidadesAndAtendimentos = async () => {
        try {
            const comunidadesSalvas = await Api.GetMapCoordenadaComunidade();
            setComunidades(comunidadesSalvas);

            const atendimentos = await Api.GetAtendimentosByLocation();
            console.log("atendimentos", atendimentos)
            setAtendimentos(atendimentos);
        } catch (error) {
            console.error("Erro ao carregar comunidades:", error);
        }
    };

    const saveComunidade = async () => {
        if (!markedCoordinates || !selectedUnidade || !newCommunityName) return;

        const novaComunidade: Omit<Comunidade, 'id'> = {
            comunidade: newCommunityName,
            lat: markedCoordinates.lat,
            lng: markedCoordinates.lng,
            unidade: selectedUnidade === "novo" ? newUnidade : selectedUnidade,
            icone: selectedIcon
        };

        try {
            await Api.PostMapCoordenadaComunidade(novaComunidade);
            loadComunidadesAndAtendimentos();
            setShowAddCommunityModal(false);
            setMarkedCoordinates(null);
            alert("Comunidade salva com sucesso!");
        } catch (error) {
            console.error("Erro ao salvar comunidade:", error);
        }
    };

    const deleteComunidade = async (id: number) => {
        setIsDeleting(true);
        try {
            await Api.DeleteMapCoordenadaComunidade(id);
            loadComunidadesAndAtendimentos();
            setShowDeleteModal(false);
        } catch (error) {
            console.error("Erro ao deletar comunidade:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    const confirmDelete = (id: number) => {
        setCommunityToDelete(id);
        setShowDeleteModal(true);
    };

    useEffect(() => {
        loadComunidadesAndAtendimentos();
    }, []);

    const onMapClick = (event: google.maps.MapMouseEvent) => {
        handleMapClick(event);
    };

    const handleMapLoad = useCallback((map: google.maps.Map) => {
        setMapInstance(map);
        setMapLoaded(true);
    }, []);

    const handleMarkerClick = (index: number) => {
        setActiveMarker(index);
    };

    const centerMap = (lat: number, lng: number) => {
        if (mapInstance) {
            mapInstance.panTo({ lat, lng });
            mapInstance.setZoom(15);
        }
    };

    const handleCommunityClick = (lat: number, lng: number) => {
        centerMap(lat, lng);
    };

    const handleUnidadeClick = (unidade: string) => {
        setExpandedUnidades(prev => ({
            ...prev,
            [unidade]: !prev[unidade]
        }));
    };

    const groupedComunidades = comunidades.reduce<Record<string, Comunidade[]>>((acc, comunidade) => {
        if (!acc[comunidade.unidade]) {
            acc[comunidade.unidade] = [];
        }
        acc[comunidade.unidade].push(comunidade);
        return acc;
    }, {});

    return (
        <Container fluid>
            <Row className="my-3">
                <Col md={8} className="mb-4">
                    <LoadScript googleMapsApiKey={GOOGLE_API_KEY}>
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={12}
                            mapTypeId="satellite"
                            onClick={onMapClick}
                            onLoad={handleMapLoad}
                        >
                            {mapLoaded && savedCoordinates && (
                                <Marker
                                    position={savedCoordinates}
                                    icon={{
                                        url: redPinIconBase64,
                                        scaledSize: new google.maps.Size(30, 30),
                                    }}
                                />
                            )}

                            {mapLoaded && comunidades.map((comunidade, index) => (
                                <Marker
                                    key={index}
                                    position={{ lat: comunidade.lat, lng: comunidade.lng }}
                                    icon={{
                                        url: comunidade.icone,
                                        scaledSize: new google.maps.Size(30, 30),
                                    }}
                                    onClick={() => handleMarkerClick(comunidade.lat)}
                                >
                                    {activeMarker === comunidade.lat && (
                                        <InfoWindow>
                                            <div style={infoWindowStyle}>
                                                <p>{comunidade.comunidade}</p>
                                            </div>
                                        </InfoWindow>
                                    )}
                                </Marker>
                            ))}

                            {mapLoaded && atendimentos.map((atendimento, index) => (
                                <Marker
                                    key={index}
                                    position={{ lat: Number(atendimento.latitude), lng: Number(atendimento.longitude) }}
                                    icon={{
                                        url: redPinIconBase64,
                                        scaledSize: new google.maps.Size(30, 30),
                                    }}
                                    onClick={() => handleMarkerClick(index)}
                                >
                                    {activeMarker === index && (
                                        <InfoWindow>
                                            <div style={infoWindowStyle}>
                                                <p><strong>{atendimento.municipio}</strong></p>
                                                <p><strong>Categoria:</strong> {atendimento.categoria}</p>
                                                <p><strong>Sub-Categoria:</strong> {atendimento.subclasse}</p>

                                            </div>
                                        </InfoWindow>
                                    )}
                                </Marker>
                            ))}
                        </GoogleMap>
                    </LoadScript>
                </Col>
                <Col md={4}>
                    <div className="d-grid gap-2 mb-3">
                        <Button variant="primary" onClick={() => setAddingMarker(true)}>
                            Adicionar Comunidade
                        </Button>
                        <Button variant="success" onClick={() => setMarkingCoordinates(true)}>
                            Marcar Coordenadas
                        </Button>
                    </div>

                    {savedCoordinates && (
                        <Card className="mb-3 shadow-sm">
                            <Card.Body>
                                <Card.Title>Coordenada Marcada</Card.Title>
                                <Card.Text>
                                    <strong>Latitude:</strong> {savedCoordinates.lat.toFixed(6)}<br />
                                    <strong>Longitude:</strong> {savedCoordinates.lng.toFixed(6)}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    )}

                    <Card className="scrollable-list shadow-sm" style={{ maxHeight: "400px", overflowY: "auto" }}>
                        <Card.Body>
                            {Object.entries(groupedComunidades).map(([unidade, comunidades]) => (
                                <div key={unidade} className="mb-2">
                                    <Button
                                        variant="link"
                                        style={{
                                            textDecoration: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        onClick={() => handleUnidadeClick(unidade)}
                                    >
                                        <strong className="me-2">{unidade}</strong>
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                transform: expandedUnidades[unidade] ? 'rotate(360deg)' : 'rotate(270deg)',
                                                transition: 'transform 0.3s',
                                                minWidth: '20px',
                                            }}
                                        >
                                            <ArrowDownIcon
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                            />
                                        </span>
                                    </Button>
                                    {expandedUnidades[unidade] && (
                                        <ListGroup>
                                            {comunidades.map((comunidade) => (
                                                <ListGroup.Item
                                                    key={comunidade.id}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleCommunityClick(comunidade.lat, comunidade.lng)}
                                                >
                                                    {comunidade.comunidade}
                                                    <Button
                                                        variant="danger"
                                                        size="sm"
                                                        onClick={() => confirmDelete(comunidade.id)}
                                                        className="ml-2 float-end"
                                                    >
                                                        Excluir
                                                    </Button>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    )}
                                </div>
                            ))}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Modal para Adicionar Comunidade */}
            <Modal show={showAddCommunityModal} onHide={() => setShowAddCommunityModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Comunidade</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formUnidade">
                            <Form.Label>Unidade</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedUnidade}
                                onChange={(e) => setSelectedUnidade(e.target.value)}
                            >
                                <option value="">Selecione a Unidade</option>
                                {Object.entries(groupedComunidades).map(([unidade], index) => (
                                    <option key={index} value={unidade}>{unidade}</option>
                                ))}
                                <option value="novo">Criar Nova Unidade</option>
                            </Form.Control>
                        </Form.Group>

                        {selectedUnidade === "novo" && (
                            <Form.Group controlId="formCommunityName" className="mt-3">
                                <Form.Label>Nome da Nova Unidade</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Digite o nome da Unidade"
                                    value={newUnidade}
                                    onChange={(e) => setNewUnidade(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>
                        )}

                        <Form.Group controlId="formCommunityName" className="mt-3">
                            <Form.Label>Nome da Comunidade</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Digite o nome da comunidade"
                                value={newCommunityName}
                                onChange={(e) => setNewCommunityName(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formIconSelect" className="mt-3">
                            <Form.Label>Selecione o Ícone</Form.Label>
                            <div className="d-flex mt-2">
                                {icons.map(icon => (
                                    <button
                                        key={icon.alt}
                                        type="button"
                                        onClick={() => setSelectedIcon(icon.src)}
                                        style={{
                                            background: 'transparent',
                                            cursor: 'pointer',
                                            marginLeft: '3%',
                                            padding: '10px',
                                            position: 'relative',
                                            transform: selectedIcon === icon.src ? 'scale(1.1)' : 'scale(1)',
                                            border: selectedIcon === icon.src ? '2px solid red' : 'none',
                                            borderRadius: '5px',
                                            transition: 'transform 0.2s, border 0.2s',
                                        }}
                                        onMouseEnter={e => {
                                            if (selectedIcon === icon.src) {
                                                e.currentTarget.style.borderColor = 'darkred';
                                            }
                                        }}
                                        onMouseLeave={e => {
                                            if (selectedIcon === icon.src) {
                                                e.currentTarget.style.borderColor = 'red';
                                            }
                                        }}
                                    >
                                        <img src={icon.src} alt={icon.alt} width="30" height="30" />
                                    </button>
                                ))}
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAddCommunityModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={saveComunidade}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>Tem certeza de que deseja excluir esta comunidade?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={() => deleteComunidade(communityToDelete!)} disabled={isDeleting}>
                        {isDeleting ? "Excluindo..." : "Excluir"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default MapAtendimentos;

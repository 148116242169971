import React, { useState, useEffect, useCallback } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import Api from "../../config/api";
import "./styles.css";
import BotaoVoltar from "../../components/buttonReturnPage";

const GOOGLE_API_KEY = "AIzaSyCysGhoOi0XTrKJVPqSfJNZ_04CEOu-a0Y";

function removerAcentos(municipio: string): string {
  return (
    municipio && municipio.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  );
}

function findLocationMunicipio(
  demanda: any,
  listaMunicipiosLocations: any[]
): SelectedMarker | null {
  const municipioSemAcento = removerAcentos(demanda.municipio);
  const municipioMaisculo =
    municipioSemAcento && municipioSemAcento.toUpperCase();

  const municipioEncontrado = listaMunicipiosLocations.find((munic: any) => {
    return munic.municipio === municipioMaisculo;
  });

  if (municipioEncontrado) {
    return {
      demandaCompleta: demanda,
      municipioLocation: municipioEncontrado,
    };
  }

  return null; // Retorna null explicitamente quando nenhum município é encontrado
}

interface SelectedMarker {
  demandaCompleta?: {
    latitude: number;
    longitude: number;
    municipio: string;
    origem: string;
  };
  municipioLocation?: {
    latitude: number;
    longitude: number;
    municipio: string;
  };
  contDemandas?: number;
}


const Maps: React.FC = () => {
  const tokenUsuario = sessionStorage.getItem("tokenUsuario");
  let config = { headers: { authorization: tokenUsuario } };

  const [municipios, setMunicipios] = useState([{}]);
  const [demandasExternas, setDemandasExternas] = useState([]);
  const [demandasInternas, setDemandasInternas] = useState([]);
  const [filterButton, setFilterButton] = useState("externa");
  const [selectedMarker, setSelectedMarker] = useState<SelectedMarker | null>(
    null
  );

  const centerMapInitialize = {
    lat: -19.748,
    lng: -47.936,
  };

  const containerStyle: React.CSSProperties = {
    width: "80%",
    height: "420px",
    padding: "5%",
  };

  const getApi = async () => {
    const resultMunicip = await Api.getAllMunicipiosLocations();
    setMunicipios(resultMunicip);

    await Api.GetDemandaExterna(config).then((data) => setDemandasExternas(data));

    await Api.GetDemandaInterna(config).then((data) => setDemandasInternas(data));
  };

  useEffect(() => {
    getApi();
  }, []);

  const onMarkerClick = (marker: any) => {
    setSelectedMarker(marker);
  };

  const closeInfoWindow = () => {
    setSelectedMarker(null);
  };
  // Criando array ordenado de locations por municipio demanda Externa
  let arrayComLocationsDemandasExternas: (SelectedMarker | null)[] = [];
  if (demandasExternas && demandasExternas.length > 3) {
    demandasExternas.forEach((demanda: any) => {
      if (demanda.municipio) {
        const obj = findLocationMunicipio(demanda, municipios);
        arrayComLocationsDemandasExternas.push(obj);
      }
    });
  }

  //ARRAY DE DEMANDAS eXTERNOS AGRUPADO
  const municipiosAgrupadosDemandasExternas: { [key: string]: any[] } = {};
  arrayComLocationsDemandasExternas.forEach((obj: any) => {
    if (obj && obj.municipioLocation) {
      const nomeMunicipio = obj.municipioLocation.municipio;
      if (!municipiosAgrupadosDemandasExternas[nomeMunicipio]) {
        municipiosAgrupadosDemandasExternas[nomeMunicipio] = [];
      }
      municipiosAgrupadosDemandasExternas[nomeMunicipio].push(obj);
    }
  });

  //Criando array ordenado de locations por municipio demanda Interna
  const arrayComLocationsDemandasInterna =
    demandasInternas &&
    demandasInternas.map((demanda: any) => {
      if (demanda.municipio) {
        return findLocationMunicipio(demanda, municipios);
      }
      return {};
    });

  //ARRAY DE DEMANDAS INTERNAS AGRUPADO
  const municipiosAgrupadosDemandasInternas: { [key: string]: any[] } = {};
  arrayComLocationsDemandasInterna.forEach((obj: any) => {
    if (obj && obj.municipioLocation) {
      const nomeMunicipio = obj.municipioLocation.municipio;
      if (!municipiosAgrupadosDemandasInternas[nomeMunicipio]) {
        municipiosAgrupadosDemandasInternas[nomeMunicipio] = [];
      }
      municipiosAgrupadosDemandasInternas[nomeMunicipio].push(obj);
    }
  });

  const buttonFilterMarkExterna = () => {
    setFilterButton("externa");
  };

  const buttonFilterMarkInterna = () => {
    setFilterButton("interna");
  };

  const markDemandaExterna = () => {
    return Object.entries(municipiosAgrupadosDemandasExternas).map(
      ([nomeMunicipio, demandasDoMunicipio]: [string, SelectedMarker[]], index: number) => {
        return demandasDoMunicipio.map((objeto: SelectedMarker | null, subIndex: number) => {
          if (objeto && objeto.municipioLocation) {
            const obj = {
              demandaCompleta: demandasDoMunicipio,
              municipioLocation: objeto.municipioLocation,
              contDemandas: demandasDoMunicipio.length
            }
            return (
              <Marker
                key={`${index}-${subIndex}`}
                position={{
                  lat: objeto.municipioLocation.latitude,
                  lng: objeto.municipioLocation.longitude,
                }}
                title={objeto.municipioLocation.municipio}
                onClick={() => onMarkerClick(obj)}
              />
            );
          }
          return null;
        });
      }
    );
  };

  const markDemandaInterna = () => {
    return Object.entries(municipiosAgrupadosDemandasInternas).map(
      ([nomeMunicipio, demandasDoMunicipio]: [string, SelectedMarker[]], index: number) => {
        return demandasDoMunicipio.map((objeto: SelectedMarker | null, subIndex: number) => {
          if (objeto && objeto.municipioLocation) {
            const obj = {
              demandaCompleta: demandasDoMunicipio,
              municipioLocation: objeto.municipioLocation,
              contDemandas: demandasDoMunicipio.length
            };
            return (
              <Marker
                key={`${index}-${subIndex}`}
                position={{
                  lat: objeto.municipioLocation.latitude,
                  lng: objeto.municipioLocation.longitude,
                }}
                title={objeto.municipioLocation.municipio}
                onClick={() => onMarkerClick(obj)}
              />
            );
          }
          return null;
        });
      }
    );
  };

  return (
    <div className="container-pai">
      <div className="map-container">
        <div className="container-title-maps mb-2">
          <h1 className="title-maps">Mapa Institucional</h1>
        </div>
        <div className="container-fixes-map-and-buttons">

        </div>
        <div className="container-buttons-filters mb-1">
          <button
            type="button"
            className="button-filter-map"
            id="button-externa"
            style={{ width: "10%" }}
            onClick={buttonFilterMarkExterna}
          >
            Externas
          </button>
          <button
            type="button"
            className="button-filter-map"
            id="button-interna"
            style={{ width: "10%" }}
            onClick={buttonFilterMarkInterna}
          >
            Internas
          </button>
        </div>
        <div className="map-info-container">
          <LoadScript googleMapsApiKey={GOOGLE_API_KEY}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={centerMapInitialize}
              zoom={10}
              mapTypeId="hybrid"
              tilt={45}
            >
              {filterButton === "externa" && markDemandaExterna()}
              {filterButton === "interna" && markDemandaInterna()}
              {selectedMarker && (
                <InfoWindow
                  position={
                    selectedMarker && selectedMarker.municipioLocation
                      ? {
                        lat: selectedMarker.municipioLocation.latitude,
                        lng: selectedMarker.municipioLocation.longitude,
                      }
                      : { lat: 0, lng: 0 }
                  }
                  onCloseClick={closeInfoWindow}
                >
                  <div>
                    <h5>{selectedMarker.demandaCompleta && Array.isArray(selectedMarker.demandaCompleta) ? selectedMarker.demandaCompleta[0].demandaCompleta.municipio : 'Sem município'}</h5>
                    <p style={{ fontSize: '110%' }}>
                      <b>{`Demandas em ${Array.isArray(selectedMarker.demandaCompleta) && selectedMarker.demandaCompleta[0].demandaCompleta.municipio}:`}</b> {selectedMarker.contDemandas}
                    </p>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          </LoadScript>
        </div>
      </div>
      {selectedMarker && (
        <div className="info-container">
          <h2>Detalhes</h2>
          <h5><b>Municipio: </b>{Array.isArray(selectedMarker.demandaCompleta) && selectedMarker.demandaCompleta[0].demandaCompleta.municipio}</h5>
          <div>
            {selectedMarker && Array.isArray(selectedMarker.demandaCompleta) && selectedMarker.demandaCompleta.map((demanda: any, i: any) => {
              return (
                <div key={i} className="card-info">
                  <p><b>Demanda - {i + 1}</b></p>
                  <hr></hr>
                  <p><b>Origem:</b>{demanda.demandaCompleta.origem}</p>
                  <p><b>Relevância:</b>{demanda.demandaCompleta.relevancia}</p>
                  <p><b>Status do atendimento:</b>{demanda.demandaCompleta.status_atendimento}</p>
                  <p><b>Prioridade do tema:</b>{demanda.demandaCompleta.prioridade_tema}</p>
                  <p><b>Descrição do tema:</b>{demanda.demandaCompleta.descricao_tema}</p>
                  <hr></hr>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Maps;

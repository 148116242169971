import { BrowserRouter, Route, Routes } from "react-router-dom";
import BaseHome from "../pages/baseHome";
import EsquecerSenha from "../pages/esquecerSenha";
import HomeComunidades from "../pages/homeComunidades";
import Indicadores from "../pages/indicadores";
import Login from "../pages/login";
import Manual from "../pages/manual";
import Mapa from "../pages/mapa";
import Perfil from "../pages/perfil";
import BaseLogin from "../pages/baseLogin";
import RelacionamentoInstitucional from "../pages/relacionamentoInstituional";
import Cadastro from "../pages/cadastro";
import NewPortal from "../pages/newPortal";
import HomeCadastroRelacionamento from "../pages/relacionamentoInstituional/HomeCadastroRelacionamento";
import HomeCadastroGovernamental from "../pages/relacionamentoGovernamental/HomeCadastroGovernamental";
import Permissoes from "../pages/permissoes";
import IndicadoresPainelSocial from "../pages/indicadores/painelSocial";
import IndicadoresComunidade from "../pages/indicadores/comunidades";
import IndicadoresInstitucional from "../pages/indicadores/institucional";
import IndicadoresGovernamental from "../pages/indicadores/governamental";
import IndicadoresDesenvolvimentoSocial from "../pages/indicadores/desenvolvimentoSocial";
import IndicadoresPainelSocialTelevisao from "../pages/indicadores/painelSocialTv";
import Whatsapp from "../pages/whatsapp";
import RelacionamentoGovernamental from "../pages/relacionamentoGovernamental";

function RouterManager() {

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<BaseLogin />}>
          <Route path="/login" element={<Login />} />
          <Route path="/esquecerSenha" element={<EsquecerSenha />} />
        </Route>
        <Route path="/portais" element={<NewPortal />} />
        <Route
          path="/indicadores-painel-social-televisao"
          element={<IndicadoresPainelSocialTelevisao />}
        />
        <Route element={<BaseHome />}>
          <Route path="/" element={<HomeComunidades />} />
          <Route path="/cadastro/:gerarAtendimento?" element={<Cadastro />} />
          <Route
            path="/relacionamento_institucional"
            element={<RelacionamentoInstitucional />}
          />
          <Route
            path="/relacionamento_governamental"
            element={<RelacionamentoGovernamental />}
          />
          <Route
            path="/cadastro_relacionamento"
            element={<HomeCadastroRelacionamento />}
          />
          <Route
            path="/cadastro_governamental"
            element={<HomeCadastroGovernamental />}
          />
          <Route path="/indicadores" element={<Indicadores />} />
          <Route
            path="/indicadores_painelSocial"
            element={<IndicadoresPainelSocial />}
          />
          <Route
            path="/indicadores_comunidades"
            element={<IndicadoresComunidade />}
          />
          <Route
            path="/indicadores_institucional"
            element={<IndicadoresInstitucional />}
          />
          <Route
            path="/indicadores_governamental"
            element={<IndicadoresGovernamental />}
          />
          <Route
            path="/indicadores_desenvolvimentoSocial"
            element={<IndicadoresDesenvolvimentoSocial />}
          />
          <Route path="/mapa" element={<Mapa />} />
          <Route path="/comunicacao" element={<Whatsapp />} />
          <Route path="/manual" element={<Manual />} />
          <Route path="/permissoes" element={<Permissoes />} />
          <Route path="/perfil" element={<Perfil />} />
          <Route path="/perfil/:id" element={<Perfil />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default RouterManager;

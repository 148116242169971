import "./styles.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row, Image } from "react-bootstrap";
import ImagemHome from "../../assets/images/home.png";
import {
    FaPeopleArrows,
    FaHandHoldingWater,
    FaLeaf,
    FaChartBar,
} from "react-icons/fa";
import { MdNaturePeople } from "react-icons/md";

function NewPortal() {
    const { t } = useTranslation();

    const linksHome = [
        {
            icon: <FaPeopleArrows color="#165788" />,
            titulo: t("Relacionamento com Comunidades"),
            link: "/",
            buttonClass: "button-icon-relacionamento",
            click: sessionStorage.setItem("portalLogado", "portalComunidades"),
        },
        {
            icon: <FaHandHoldingWater color="#00544d" />,
            titulo: t("Relacionamento Institucional"),
            link: "/relacionamento_institucional",
            buttonClass: "button-icon-institucional",
            click: sessionStorage.setItem(
                "portalLogado",
                "portalInstitucional"
            ),
        },
        {
            icon: <MdNaturePeople color="#542400" />,
            titulo: t("Relacionamento Governamental"),
            link: "/relacionamento_governamental",
            buttonClass: "button-icon-governamental",
            click: sessionStorage.setItem(
                "portalLogado",
                "portalGovernamental"
            ),
        },
        {
            icon: <FaLeaf color="#e57200" />,
            titulo: t("Desenvolvimento Social"),
            link: "/",
            buttonClass: "button-icon-instituto",
        },
        {
            icon: <FaChartBar color="#fbaf17" />,
            titulo: t("Painel Social"),
            link: "/indicadores_painelSocial",
            buttonClass: "button-icon-indicadores",
        },
    ];

    return (
        <div id="portal">
            <div className="conteudo">
                <h5>
                    {t(
                        "Acreditamos que o crescimento e o sucesso que valem a pena são aqueles conquistados juntos e que podem ser compartilhados."
                    )}
                </h5>

                <div className="botoes">
                    {linksHome.map(
                        ({ icon, titulo, link, buttonClass, click }, i) => (
                            <Link
                                key={i}
                                to={link}
                                onClick={() => click}
                                className={`${buttonClass}`}
                            >
                                <span>{icon}</span>
                                <h3>{titulo}</h3>
                            </Link>
                        )
                    )}
                </div>
                <div className="imagem">
                    <Image src={ImagemHome}></Image>
                </div>
            </div>
        </div>
    );
}

export default NewPortal;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import Api, { linkBackend } from "../../../config/api";
import { useNavigate } from "react-router-dom";
import { Modal, Col, Row, Form, Card } from "react-bootstrap";

function GridPainelSocial({
    config,
    permissoesUser,
}: {
    config: any;
    permissoesUser: {
        painelSocial_view: boolean;
        painelSocial_edit: boolean;
    };
}) {
    const { t } = useTranslation();
    const navegar = useNavigate();
    const [datagridRows, setDatagridRows] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const [idPainelSocial, setIdPainelSocial] = useState<number>(0);

    const [unidade, setUnidade] = useState<string>("");
    const [periodo, setPeriodo] = useState<string>("");

    //Bloco Gestão de Relacionamento com Comunidades
    const [destaque, setDestaque] = useState<string>("");

    //Bloco Desenvolvimento Social
    const [desenvolvimentoTitulo1, setDesenvolvimentoTitulo1] = useState<string>("");
    const [desenvolvimentoSubtitulo1, setDesenvolvimentoSubtitulo1] = useState<string>("");
    const [agua, setAgua] = useState<boolean>(false);
    const [desenvolvimentoConteudo1, setDesenvolvimentoConteudo1] = useState<string>("");

    const [desenvolvimentoTitulo2, setDesenvolvimentoTitulo2] = useState<string>("");
    const [desenvolvimentoSubtitulo2, setDesenvolvimentoSubtitulo2] = useState<string>("");
    const [alimentacao, setAlimentacao] = useState<boolean>(false);
    const [desenvolvimentoConteudo2, setDesenvolvimentoConteudo2] = useState<string>("");

    const [desenvolvimentoTitulo3, setDesenvolvimentoTitulo3] = useState<string>("");
    const [desenvolvimentoSubtitulo3, setDesenvolvimentoSubtitulo3] = useState<string>("");
    const [desenvolvimentoLocal, setDesenvolvimentoLocal] = useState<boolean>(false);
    const [desenvolvimentoConteudo3, setDesenvolvimentoConteudo3] = useState<string>("");

    const [desenvolvimentoTitulo4, setDesenvolvimentoTitulo4] = useState<string>("");
    const [desenvolvimentoSubtitulo4, setDesenvolvimentoSubtitulo4] = useState<string>("");
    const [educacao, setEducacao] = useState<boolean>(false);
    const [desenvolvimentoConteudo4, setDesenvolvimentoConteudo4] = useState<string>("");

    //Bloco Gestão Preventiva de Conflitos
    const [gestaoTitulo1, setGestaoTitulo1] = useState<string>("");
    const [gestaoConteudo1, setGestaoConteudo1] = useState<string>("");
    const [gestaoTitulo2, setGestaoTitulo2] = useState<string>("");
    const [gestaoConteudo2, setGestaoConteudo2] = useState<string>("");
    const [gestaoTitulo3, setGestaoTitulo3] = useState<string>("");
    const [gestaoConteudo3, setGestaoConteudo3] = useState<string>("");
    const [gestaoTitulo4, setGestaoTitulo4] = useState<string>("");
    const [gestaoConteudo4, setGestaoConteudo4] = useState<string>("");

    //foto
    const [registroFotografico, setRegistroFotografico] = useState(null);
    const [fotoAgua, setfotoAgua] = useState(null);
    const [fotoAlimentacao, setFotoAlimentacao] = useState(null);
    const [fotoDevLocal, setFotoDevLocal] = useState(null);
    const [fotoEducacao, setFotoEducacao] = useState(null);

    //filtros
    const tiposFiltro = [t("Unidade"), t("Periodo")];
    const [openModalFiltro, setOpenModalFiltro] = useState(false);
    const [filtroAtivo, setFiltroAtivo] = useState<string | null>(null);
    const [filtroVazio, setFiltroVazio] = useState(true);
    const [filtroUnidade, setFiltroUnidade] = useState<string>("");
    const [filtroDataPeriodo, setFiltroDataPeriodo] = useState<string>("");

    const [filtroAtual, setFiltroAtual] = useState<string>("");
    const [dadosFiltrados, setDadosFiltrados] = useState<any[]>([]);

    const [toastExibido, setToastExibido] = useState(false);

    useEffect(() => {
        if (!permissoesUser.painelSocial_view && !toastExibido) {
            toast.error("Você não tem permissão para visualizar Notificações!");
            setToastExibido(true);
        }
    }, [permissoesUser.painelSocial_view, toastExibido]);

    useEffect(() => {
        if (selectedRowData) {
            setIdPainelSocial(selectedRowData.Id || 0);
            setUnidade(selectedRowData.Unidade || "");
            setPeriodo(inverterFormatoData(selectedRowData.DataPeriodo) || "");
            setDestaque(selectedRowData.Destaque || "");
            if (selectedRowData) {
                setAgua(selectedRowData.DesenvolvimentoConteudo1 !== "");
                setAlimentacao(selectedRowData.DesenvolvimentoConteudo2 !== "");
                setDesenvolvimentoLocal(selectedRowData.DesenvolvimentoConteudo3 !== "");
                setEducacao(selectedRowData.DesenvolvimentoConteudo4 !== "");
            }
            setDesenvolvimentoSubtitulo1(selectedRowData.DesenvolvimentoSubtitulo1 || "");
            setDesenvolvimentoConteudo1(selectedRowData.DesenvolvimentoConteudo1 || "");
            setDesenvolvimentoSubtitulo2(selectedRowData.DesenvolvimentoSubtitulo2 || "");
            setDesenvolvimentoConteudo2(selectedRowData.DesenvolvimentoConteudo2 || "");
            setDesenvolvimentoSubtitulo3(selectedRowData.DesenvolvimentoSubtitulo3 || "");
            setDesenvolvimentoConteudo3(selectedRowData.DesenvolvimentoConteudo3 || "");
            setDesenvolvimentoSubtitulo4(selectedRowData.DesenvolvimentoSubtitulo4 || "");
            setDesenvolvimentoConteudo4(selectedRowData.DesenvolvimentoConteudo4 || "");
            setGestaoTitulo1(selectedRowData.GestaoTitulo1 || "");
            setGestaoConteudo1(selectedRowData.GestaoConteudo1 || "");
            setGestaoTitulo2(selectedRowData.GestaoTitulo2 || "");
            setGestaoConteudo2(selectedRowData.GestaoConteudo2 || "");
            setGestaoTitulo3(selectedRowData.GestaoTitulo3 || "");
            setGestaoConteudo3(selectedRowData.GestaoConteudo3 || "");
            setGestaoTitulo4(selectedRowData.GestaoTitulo4 || "");
            setGestaoConteudo4(selectedRowData.GestaoConteudo4 || "");
            setRegistroFotografico(selectedRowData.Foto || null);
            setfotoAgua(selectedRowData.FotoAgua || null);
            setFotoAlimentacao(selectedRowData.FotoAlimentacao || null);
            setFotoDevLocal(selectedRowData.FotoDevLocal || null);
            setFotoEducacao(selectedRowData.FotoEducacao || null);
        }
    }, [selectedRowData]);

    const handleRowClick = (params: any) => {
        setSelectedRowData(params.row);
        console.log("params.row", selectedRowData);
        console.log("params.row", params.row);

        setIsModalOpen(true);
    };

    const handleCloseModalDelete = () => {
        setShowModalDelete(false);
    };

    const handleOpenModalDelete = () => {
        setShowModalDelete(true);
    };

    const [datagridColunas] = useState([
        {
            field: "Unidade",
            type: "string",
            headerName: t("Unidade"),
            width: 350,
        },
        {
            field: "DataPeriodo",
            type: "string",
            headerName: t("Periodo"),
            width: 350,
        },
    ]);

    const carregarDados = () => {
        Api.GetPaineisSociais(config)
            .then((data: any[]) => {
                const adaptedData = data.map((d: any, index: number) => ({
                    id: index + 1,
                    Id: d.id,
                    Unidade: d.unidade || "",
                    DataPeriodo: formatDateTable(d.dt_periodo || ""),
                    Destaque: d.destaque || "",
                    DesenvolvimentoSubtitulo1: d.desenvolvimento_subtitulo1 || "",
                    DesenvolvimentoConteudo1: d.desenvolvimento_conteudo1 || "",
                    DesenvolvimentoSubtitulo2: d.desenvolvimento_subtitulo2 || "",
                    DesenvolvimentoConteudo2: d.desenvolvimento_conteudo2 || "",
                    DesenvolvimentoSubtitulo3: d.desenvolvimento_subtitulo3 || "",
                    DesenvolvimentoConteudo3: d.desenvolvimento_conteudo3 || "",
                    DesenvolvimentoSubtitulo4: d.desenvolvimento_subtitulo4 || "",
                    DesenvolvimentoConteudo4: d.desenvolvimento_conteudo4 || "",
                    GestaoTitulo1: d.gestao_titulo1 || "",
                    GestaoConteudo1: d.gestao_conteudo1 || "",
                    GestaoTitulo2: d.gestao_titulo2 || "",
                    GestaoConteudo2: d.gestao_conteudo2 || "",
                    GestaoTitulo3: d.gestao_titulo3 || "",
                    GestaoConteudo3: d.gestao_conteudo3 || "",
                    GestaoTitulo4: d.gestao_titulo4 || "",
                    GestaoConteudo4: d.gestao_conteudo4 || "",
                    Foto: d.foto || "Não existe",
                    FotoAgua: d.foto_titulo1 || null,
                    FotoAlimentacao: d.foto_titulo2 || null,
                    FotoDevLocal: d.foto_titulo3 || null,
                    FotoEducacao: d.foto_titulo4 || null
                }));

                setDatagridRows(adaptedData);
                setDadosFiltrados(adaptedData);
            })
            .catch((err: any) => {
                if (typeof err === "string") {
                    toast.error(t(err));
                    navegar("/login");
                    return;
                }
                toast.error(t("Houve um erro ao carregar os paineis sociais"));
            });
    };

    useEffect(() => {
        carregarDados();
    }, []);

    function inverterFormatoData(dataString: string) {
        const partes = dataString.split("-");
        const novaDataString = `${partes[1]}-${partes[0]}`;

        return novaDataString;
    }

    // Função para formatar data para a tabela (mes-ano)
    function formatDateTable(dateString: string | Date) {
        const date = new Date(dateString);
        const localDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));

        const month = (localDate.getMonth() + 1).toString().padStart(2, "0");
        const year = localDate.getFullYear().toString().padStart(4, "0");

        return `${month}-${year}`;
    }

    useEffect(() => {
        if (filtroVazio) {
            carregarDados();
        } else {
            const dadosFiltrados = datagridRows.filter((item) => {
                const unidadeFiltro =
                    item.Unidade &&
                    item.Unidade.toLowerCase().includes(filtroUnidade.toLowerCase());
                const dataPeriodoFiltro = item.DataPeriodo;

                return unidadeFiltro && dataPeriodoFiltro;
            });
            setDatagridRows(dadosFiltrados);
        }
    }, [t, filtroVazio, filtroUnidade, filtroDataPeriodo, filtroAtivo]);

    const handleBuscar = () => {
        if (filtroAtual.trim() === "") {
            handleClearFilter();
        } else {
            const campoFiltravel = getCampoFiltravel(filtroAtivo);

            if (!campoFiltravel) {
                console.error(`Campo filtrável não encontrado para ${filtroAtivo}`);
                return;
            }

            const dadosFiltrados = datagridRows.filter(
                (item) =>
                    item[campoFiltravel] &&
                    item[campoFiltravel].toLowerCase().includes(filtroAtual.toLowerCase())
            );

            setDadosFiltrados(dadosFiltrados);
            setFiltroVazio(false);
        }
    };

    const getCampoFiltravel = (filtroAtivo: string | null): string => {
        switch (filtroAtivo) {
            case "Unidade":
                return "Unidade";
            case "Periodo":
                return "DataPeriodo";
            default:
                return "";
        }
    };

    const handleClearFilter = () => {
        setFiltroUnidade("");
        setFiltroDataPeriodo("");
        setFiltroVazio(true);
        // setRegistroFotografico(null);
        carregarDados();
    };

    const handleModalFiltros = (filtro: string) => {
        setFiltroAtivo(filtro);
        setOpenModalFiltro(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        // setIsModalOpen(false);
        // setAgua(false);
        // setAlimentacao(false);
        // setDesenvolvimentoLocal(false);
        // setEducacao(false);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        const formData = new FormData();

        formData.append("id", String(idPainelSocial));
        formData.append("unidade", unidade);
        formData.append("dt_periodo", periodo);
        formData.append("destaque", destaque);

        if (registroFotografico !== null) {
            formData.append("avatar", registroFotografico);
        }

        if (fotoAgua) {
            if(typeof(fotoAgua) === "string") {
                formData.append("caminhoFoto1", fotoAgua);
            } else { 
                formData.append("avatar1", fotoAgua);
            }
        }

        if (fotoAlimentacao) {
             if(typeof(fotoAlimentacao) === "string") {
                formData.append("caminhoFoto2", fotoAlimentacao);
            } else { 
                formData.append("avatar2", fotoAlimentacao);
            }
        }
        if (fotoDevLocal) {
            if(typeof(fotoDevLocal) === "string") {
                formData.append("caminhoFoto3", fotoDevLocal);
            } else {
                formData.append("avatar3", fotoDevLocal);
            }
        }
        if (fotoEducacao) {
            if(typeof(fotoEducacao) === "string") {
                formData.append("caminhoFoto4", fotoEducacao);
            } else {
                formData.append("avatar4", fotoEducacao);
            }
        }

        (desenvolvimentoSubtitulo1 || desenvolvimentoConteudo1) && formData.append("desenvolvimento_titulo1", "Água");
        (desenvolvimentoSubtitulo2 || desenvolvimentoConteudo2) && formData.append("desenvolvimento_titulo2", "Alimentação");
        (desenvolvimentoSubtitulo3 || desenvolvimentoConteudo3) && formData.append("desenvolvimento_titulo3", "Desenvolvimento Local");
        (desenvolvimentoSubtitulo4 || desenvolvimentoConteudo4) && formData.append("desenvolvimento_titulo4", "Educação");
        
        formData.append("desenvolvimento_subtitulo1", desenvolvimentoSubtitulo1);
        formData.append("desenvolvimento_conteudo1", desenvolvimentoConteudo1);
        formData.append("desenvolvimento_subtitulo2", desenvolvimentoSubtitulo2);
        formData.append("desenvolvimento_conteudo2", desenvolvimentoConteudo2);
        formData.append("desenvolvimento_subtitulo3", desenvolvimentoSubtitulo3);
        formData.append("desenvolvimento_conteudo3", desenvolvimentoConteudo3);
        formData.append("desenvolvimento_subtitulo4", desenvolvimentoSubtitulo4);
        formData.append("desenvolvimento_conteudo4", desenvolvimentoConteudo4);

        formData.append("gestao_titulo1", gestaoTitulo1);
        formData.append("gestao_conteudo1", gestaoConteudo1);
        formData.append("gestao_titulo2", gestaoTitulo2);
        formData.append("gestao_conteudo2", gestaoConteudo2);
        formData.append("gestao_titulo3", gestaoTitulo3);
        formData.append("gestao_conteudo3", gestaoConteudo3);
        formData.append("gestao_titulo4", gestaoTitulo4);
        formData.append("gestao_conteudo4", gestaoConteudo4);

        Api.PutPainelSocial(formData, config)
            .then((res) => {
                carregarDados();
                closeModal();
                toast.success("Alterações salvas com sucesso!");
            })
            .catch((err) => {
                toast.error("Não foi possível salvar as alterações!");
            });
    };

    const handleDelete = (event: any) => {
        event.preventDefault();
        Api.DeletePainelSocial(selectedRowData.Id, config)
            .then((res) => {
                carregarDados();
                handleCloseModalDelete();
                closeModal();
                toast.success("Registro deletado com sucesso!");
            })
            .catch((err) => {
                toast.error("Não foi possível deletar o registro!");
            });
    };

    const ExibirImagem = ({ imageUrl }: { imageUrl: string }) => {
        const handleViewImage = () => {
            const overlayElement = document.createElement("div");
            overlayElement.style.cssText = `
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  `;

            let imgElement: HTMLImageElement | null = document.createElement("img");

            imgElement.onload = () => {
                if (imgElement && imgElement.complete) {
                    overlayElement.appendChild(imgElement);
                } else {
                    showNoImageMessage();
                }
            };

            imgElement.onerror = () => {
                showNoImageMessage();
            };

            const formattedImageUrl = imageUrl && imageUrl.replace(/\\/g, "/");
            const linkConcat = `${linkBackend}${formattedImageUrl}`;
            const linkCompleto = linkConcat.replace(/\/\/public/, "/public");
            imgElement.src = linkCompleto;
            imgElement.alt = "Imagem";
            imgElement.style.cssText = `
          max-height: 80vh;
          max-width: 100%;
        `;

            const showNoImageMessage = () => {
                const noImageMessage = document.createElement("div");
                noImageMessage.textContent = "Nenhuma imagem disponível";
                noImageMessage.style.cssText = `
      color: white;
      font-size: 20px;
    `;

                overlayElement.appendChild(noImageMessage);
            };

            overlayElement.addEventListener("click", () => {
                document.body.removeChild(overlayElement);
            });

            document.body.appendChild(overlayElement);
        };

        return (
            <Col sm={12}>
                <Col sm={6}>
                    <button onClick={handleViewImage} className="botao-grid" type="button">
                        {t("Visualizar foto")}
                    </button>
                </Col>
            </Col>
        );
    };

    return (
        <Col sm={12}>
            <div className="grid-dados">
                <Col sm={12}>
                    <Row>
                        <Col sm={9}>
                            {filtroAtivo && (
                                <Col className="filtro-ativo">
                                    <Row>
                                        <Col sm={6}>
                                            {filtroAtivo === "Unidade" ? (
                                                <Form.Control
                                                    type="text"
                                                    placeholder={`Filtrar ${filtroAtivo}`}
                                                    value={filtroAtual}
                                                    onChange={(e) => {
                                                        setFiltroAtual(e.target.value);
                                                        handleBuscar();
                                                    }}
                                                    style={{ height: "65%" }}
                                                />
                                            ) : (
                                                <Form.Control
                                                    type="date"
                                                    placeholder={`Filtrar Data`}
                                                    value={filtroAtual}
                                                    onChange={(e) => {
                                                        setFiltroAtual(e.target.value);
                                                        handleBuscar();
                                                    }}
                                                    style={{ height: "65%" }}
                                                />
                                            )}
                                        </Col>

                                        <Col sm={3}>
                                            <button
                                                onClick={handleBuscar}
                                                className="botao-filtro mt-2"
                                            >
                                                {t("Buscar")}
                                            </button>
                                        </Col>
                                        <Col sm={3}>
                                            <button
                                                onClick={() => {
                                                    handleClearFilter();
                                                    setFiltroAtual("");
                                                }}
                                                className="botao-filtro mt-2"
                                            >
                                                {t("Limpar Filtro")}
                                            </button>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Col>
                        <Col sm={3}>
                            <button
                                className="botao-filtro mt-2"
                                // onClick={() => setOpenModalFiltro(true)}
                                onClick={() => setOpenModalFiltro(!openModalFiltro)}
                            >
                                {t("Filtros")}
                            </button>
                        </Col>
                    </Row>
                    {openModalFiltro && (
                        <Col sm={12} className="mb-2">
                            <Card>
                                <Card.Body>
                                    <Col sm={12} className="justify-content-center">
                                        <Row>
                                            {tiposFiltro.map((filtro) => (
                                                <Col sm={3}>
                                                    <button
                                                        className={`filtros-modal ${filtroAtivo === filtro ? "ativo" : ""
                                                            }`}
                                                        onClick={() => {
                                                            handleModalFiltros(filtro);
                                                            setOpenModalFiltro(false);
                                                        }}
                                                    >
                                                        {filtro}
                                                    </button>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Col>
                <div>
                    {!permissoesUser.painelSocial_view && (
                        <div>
                            <DataGrid rows={[]} columns={datagridColunas} />
                        </div>
                    )}
                    {permissoesUser.painelSocial_view && (
                        <DataGrid
                            rows={dadosFiltrados}
                            columns={datagridColunas}
                            onRowClick={handleRowClick}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                        />
                    )}
                </div>
            </div>

            {/* Modal */}
            <Modal
                show={isModalOpen}
                onHide={closeModal}
                className="modal-xl"
                dialogClassName="modal-rollover"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Detalhes do Painel Social")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedRowData && (
                        <div id="content-grid">
                            <Col sm={12} className="mb-2">
                                <Form onSubmit={handleSubmit}>
                                    <Col className="mt-4">
                                        <Form.Text className="">
                                            {t("Gestão de Relacionamento com Comunidades")}
                                        </Form.Text>
                                        <Col sm={12} className="mt-4 mb-4">
                                            <Row>
                                                <Col sm={6}>
                                                    <Form.Label>{t("Unidade")}</Form.Label>
                                                    <Form.Select
                                                        required
                                                        className="formSelect"
                                                        value={unidade}
                                                        onChange={(event) => {
                                                            setUnidade(event.target.value);
                                                            if (
                                                                event.target.value === "Araxá" ||
                                                                event.target.value === "Patrocínio"
                                                            ) {
                                                                setGestaoTitulo1(
                                                                    "Gestão da Propriedade"
                                                                );
                                                                setGestaoTitulo2(
                                                                    "Poeira e Emissões"
                                                                );
                                                                setGestaoTitulo3("Acessos");
                                                                setGestaoTitulo4(
                                                                    "Prevenção de Incêndios"
                                                                );
                                                            }
                                                            if (event.target.value === "Tapira") {
                                                                setGestaoTitulo1("Água");
                                                                setGestaoTitulo2(
                                                                    "Gestão da Propriedade"
                                                                );
                                                                setGestaoTitulo3(
                                                                    "Poeira e Emissões"
                                                                );
                                                                setGestaoTitulo4(
                                                                    "Prevenção de Incêndios"
                                                                );
                                                            }
                                                            if (event.target.value === "Uberaba") {
                                                                setGestaoTitulo1("Água");
                                                                setGestaoTitulo2(
                                                                    "Gestão da Propriedade"
                                                                );
                                                                setGestaoTitulo3(
                                                                    "Animais Domésticos"
                                                                );
                                                                setGestaoTitulo4(
                                                                    "Estradas, Paradas e Empregos"
                                                                );
                                                            }
                                                            if (event.target.value === "Cajati") {
                                                                setGestaoTitulo1(
                                                                    "Tráfego de Veículos"
                                                                );
                                                                setGestaoTitulo2(
                                                                    "Limpeza/Manutenção de Vias e do Entorno"
                                                                );
                                                                setGestaoTitulo3(
                                                                    "Poeira e Emissões"
                                                                );
                                                                setGestaoTitulo4(
                                                                    "Pontos de Encontro"
                                                                );
                                                            }
                                                            if (
                                                                event.target.value === "Paranaguá"
                                                            ) {
                                                                setGestaoTitulo1(
                                                                    "Tráfego de Veículos"
                                                                );
                                                                setGestaoTitulo2(
                                                                    "Limpeza/Manutenção de Vias e do Entorno"
                                                                );
                                                                setGestaoTitulo3(
                                                                    "Poeira e Emissões"
                                                                );
                                                                setGestaoTitulo4("Empregos");
                                                            }
                                                            if (
                                                                event.target.value === "Catalão" ||
                                                                event.target.value === "Sergipe"
                                                            ) {
                                                                setGestaoTitulo1("Água");
                                                                setGestaoTitulo2(
                                                                    "Gestão da Propriedade"
                                                                );
                                                                setGestaoTitulo3(
                                                                    "Poeira e Emissões"
                                                                );
                                                                setGestaoTitulo4(
                                                                    "Prevenção de Incêndios"
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <option value="">
                                                            {t("Selecione a Unidade")}
                                                        </option>
                                                        <option value="Araxá">{t("Araxá")}</option>
                                                        <option value="Tapira">
                                                            {t("Tapira")}
                                                        </option>
                                                        <option value="Cajati">
                                                            {t("Cajati")}
                                                        </option>
                                                        <option value="Catalão">
                                                            {t("Catalão")}
                                                        </option>
                                                        <option value="Patrocínio">
                                                            {t("Patrocínio")}
                                                        </option>
                                                        <option value="Uberaba">
                                                            {t("Uberaba")}
                                                        </option>
                                                        <option value="Paranaguá">
                                                            {t("Paranaguá")}
                                                        </option>
                                                        <option value="Sergipe">
                                                            {t("Sergipe")}
                                                        </option>
                                                    </Form.Select>
                                                </Col>
                                                <Col sm={6}>
                                                    <Form.Label>{t("Periodo")}</Form.Label>
                                                    <Form.Control
                                                        required
                                                        className="formInput"
                                                        type={"month"}
                                                        value={periodo}
                                                        onChange={(e) => {
                                                            setPeriodo(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Label>{t("Destaque: ")}</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                className="formInput"
                                                value={destaque}
                                                onChange={(e) => {
                                                    setDestaque(e.target.value);
                                                }}
                                            />
                                        </Col>
                                    </Col>
                                    <hr></hr>
                                    <Col className="mt-4">
                                        <Form.Text>{t("Desenvolvimento Social")}</Form.Text>
                                        <Col sm={12} className="mt-4 mb-4">
                                            <Row>
                                                <Col sm={3}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            gap: 10,
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <Form.Label>{t("Água")}</Form.Label>
                                                        <Form.Check
                                                            className="formSwitch"
                                                            type="switch"
                                                            checked={agua}
                                                            onChange={(e) => {
                                                                setAgua(e.target.checked);
                                                            }}
                                                        />
                                                    </div>
                                                    {agua === true ? (
                                                        <>
                                                            <Form.Control
                                                                placeholder={
                                                                    t("Digite um subtitulo") || ""
                                                                }
                                                                className="formInput mb-4"
                                                                type={"text"}
                                                                value={desenvolvimentoSubtitulo1}
                                                                onChange={(e) => {
                                                                    setDesenvolvimentoSubtitulo1(
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                            <Form.Control
                                                                placeholder={
                                                                    t("Escreva o conteudo: ") || ""
                                                                }
                                                                className="formInput"
                                                                as="textarea"
                                                                rows={3}
                                                                value={desenvolvimentoConteudo1}
                                                                onChange={(e) => {
                                                                    setDesenvolvimentoConteudo1(
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <Col sm={11} className="cadastroFotos mt-2">
                                                            <Form.Control
                                                                type="file"
                                                                accept="image/*"
                                                                name="avatar1"
                                                                onChange={(e: any) => {
                                                                    toast.info("Foto de água selecionada")
                                                                    setfotoAgua(
                                                                        e.target.files[0] ? e.target.files[0] : null
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                    )}
                                                </Col>
                                                <Col sm={3}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            gap: 10,
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <Form.Label>{t("Alimentação")}</Form.Label>
                                                        <Form.Check
                                                            className="formSwitch"
                                                            type="switch"
                                                            checked={alimentacao}
                                                            onChange={(e) => {
                                                                setAlimentacao(e.target.checked);
                                                            }}
                                                        />
                                                    </div>
                                                    {alimentacao === true ? (
                                                        <>
                                                            <Form.Control
                                                                placeholder={
                                                                    t("Digite um subtitulo") || ""
                                                                }
                                                                className="formInput mb-4"
                                                                type={"text"}
                                                                value={desenvolvimentoSubtitulo2}
                                                                onChange={(e) => {
                                                                    setDesenvolvimentoSubtitulo2(
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                            <Form.Control
                                                                placeholder={
                                                                    t("Escreva o conteudo: ") || ""
                                                                }
                                                                className="formInput"
                                                                as="textarea"
                                                                rows={3}
                                                                value={desenvolvimentoConteudo2}
                                                                onChange={(e) => {
                                                                    setDesenvolvimentoConteudo2(
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <Col sm={11} className="cadastroFotos mt-2">
                                                            <Form.Control
                                                                type="file"
                                                                accept="image/*"
                                                                name="avatar2"
                                                                onChange={(e: any) => {
                                                                    toast.info("Foto de Alimentação selecionada")
                                                                    setFotoAlimentacao(
                                                                        e.target.files[0] ? e.target.files[0] : null
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                    )}
                                                </Col>
                                                <Col sm={3}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            gap: 10,
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <Form.Label>{t("Dev. Local")}</Form.Label>
                                                        <Form.Check
                                                            className="formSwitch"
                                                            type="switch"
                                                            checked={desenvolvimentoLocal}
                                                            onChange={(e) => {
                                                                setDesenvolvimentoLocal(
                                                                    e.target.checked
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    {desenvolvimentoLocal === true ? (
                                                        <>
                                                            <Form.Control
                                                                placeholder={
                                                                    t("Digite um subtitulo") || ""
                                                                }
                                                                className="formInput mb-4"
                                                                type={"text"}
                                                                value={desenvolvimentoSubtitulo3}
                                                                onChange={(e) => {
                                                                    setDesenvolvimentoSubtitulo3(
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                            <Form.Control
                                                                placeholder={
                                                                    t("Escreva o conteudo: ") || ""
                                                                }
                                                                className="formInput"
                                                                as="textarea"
                                                                rows={3}
                                                                value={desenvolvimentoConteudo3}
                                                                onChange={(e) => {
                                                                    setDesenvolvimentoConteudo3(
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <Col sm={11} className="cadastroFotos mt-2">
                                                            <Form.Control
                                                                type="file"
                                                                accept="image/*"
                                                                name="avatar3"
                                                                onChange={(e: any) => {
                                                                    toast.info("Foto de desenvolvimento local selecionada")
                                                                    setFotoDevLocal(
                                                                        e.target.files[0] ? e.target.files[0] : null
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                    )}
                                                </Col>
                                                <Col sm={3}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            gap: 10,
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <Form.Label>{t("Educação")}</Form.Label>
                                                        <Form.Check
                                                            className="formSwitch"
                                                            type="switch"
                                                            checked={educacao}
                                                            onChange={(e) => {
                                                                setEducacao(e.target.checked);
                                                            }}
                                                        />
                                                    </div>
                                                    {educacao === true ? (
                                                        <>
                                                            <Form.Control
                                                                placeholder={
                                                                    t("Digite um subtitulo") || ""
                                                                }
                                                                className="formInput mb-4"
                                                                type={"text"}
                                                                value={desenvolvimentoSubtitulo4}
                                                                onChange={(e) => {
                                                                    setDesenvolvimentoSubtitulo4(
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                            <Form.Control
                                                                placeholder={
                                                                    t("Escreva o conteudo: ") || ""
                                                                }
                                                                className="formInput"
                                                                as="textarea"
                                                                rows={3}
                                                                value={desenvolvimentoConteudo4}
                                                                onChange={(e) => {
                                                                    setDesenvolvimentoConteudo4(
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <Col sm={11} className="cadastroFotos mt-2">
                                                            <Form.Control
                                                                type="file"
                                                                accept="image/*"
                                                                name="avatar4"
                                                                onChange={(e: any) => {
                                                                    toast.info("Foto de educação selecionada")
                                                                    setFotoEducacao(
                                                                        e.target.files[0] ? e.target.files[0] : null
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Col>
                                    <hr></hr>
                                    <Col className="mt-4">
                                        <Form.Text>{t("Gestão Preventiva de Conflitos")}</Form.Text>
                                        <Col sm={12} className="mt-4 mb-4">
                                            <Row>
                                                <Col sm={3}>
                                                    <Form.Control
                                                        disabled
                                                        placeholder={t("Digite um titulo") || ""}
                                                        className="formInput mb-4"
                                                        type={"text"}
                                                        value={gestaoTitulo1}
                                                        onChange={(e) => {
                                                            setGestaoTitulo1(e.target.value);
                                                        }}
                                                    />
                                                    <Form.Control
                                                        required
                                                        placeholder={t("Escreva o conteudo") || ""}
                                                        className="formInput"
                                                        as="textarea"
                                                        rows={3}
                                                        value={gestaoConteudo1}
                                                        onChange={(e) => {
                                                            setGestaoConteudo1(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Control
                                                        disabled
                                                        placeholder={t("Digite um titulo") || ""}
                                                        className="formInput mb-4"
                                                        type={"text"}
                                                        value={gestaoTitulo2}
                                                        onChange={(e) => {
                                                            setGestaoTitulo2(e.target.value);
                                                        }}
                                                    />
                                                    <Form.Control
                                                        required
                                                        placeholder={t("Escreva o conteudo") || ""}
                                                        className="formInput"
                                                        as="textarea"
                                                        rows={3}
                                                        value={gestaoConteudo2}
                                                        onChange={(e) => {
                                                            setGestaoConteudo2(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Control
                                                        disabled
                                                        placeholder={t("Digite um titulo") || ""}
                                                        className="formInput mb-4"
                                                        type={"text"}
                                                        value={gestaoTitulo3}
                                                        onChange={(e) => {
                                                            setGestaoTitulo3(e.target.value);
                                                        }}
                                                    />
                                                    <Form.Control
                                                        required
                                                        placeholder={t("Escreva o conteudo") || ""}
                                                        className="formInput"
                                                        as="textarea"
                                                        rows={3}
                                                        value={gestaoConteudo3}
                                                        onChange={(e) => {
                                                            setGestaoConteudo3(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Control
                                                        disabled
                                                        placeholder={t("Digite um titulo") || ""}
                                                        className="formInput mb-4"
                                                        type={"text"}
                                                        value={gestaoTitulo4}
                                                        onChange={(e) => {
                                                            setGestaoTitulo4(e.target.value);
                                                        }}
                                                    />
                                                    <Form.Control
                                                        required
                                                        placeholder={t("Escreva o conteudo") || ""}
                                                        className="formInput"
                                                        as="textarea"
                                                        rows={3}
                                                        value={gestaoConteudo4}
                                                        onChange={(e) => {
                                                            setGestaoConteudo4(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Col>
                                    <hr></hr>
                                    <Col sm={12} className="labelFotos mt-3 p-2">
                                        <Form.Label>
                                            Foto (Use arquivos .jpg, jpeg, png, e webp com no máximo
                                            2048 kB)
                                        </Form.Label>
                                    </Col>

                                    <Col sm={6} className="cadastroFotos mt-3">
                                        <Form.Control
                                            type="file"
                                            accept="image/*"
                                            name="avatar"
                                            multiple
                                            onChange={(e: any) => {
                                                setRegistroFotografico(
                                                    e.target.files[0] ? e.target.files[0] : null
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <Col sm={6}>
                                            <ExibirImagem imageUrl={selectedRowData.Foto} />
                                        </Col>
                                    </Col>
                                    <hr></hr>
                                    <Col sm={12}>
                                        <Row>
                                            <Col sm={6}>
                                                <button className="botao-grid" type="submit">
                                                    {t("Salvar")}
                                                </button>
                                            </Col>
                                            <Col sm={6}>
                                                <button
                                                    className="botao-grid"
                                                    onClick={handleOpenModalDelete}
                                                    type="button"
                                                >
                                                    {t("Excluir")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form>
                            </Col>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
            <Modal show={showModalDelete} onHide={handleCloseModalDelete} centered={true}>
                <Modal.Header closeButton></Modal.Header>

                <Modal.Body>
                    <div id="modalLogin">
                        <form className="form">
                            <p className="titleForgotPassword">
                                {t("Deseja excluir o registro ?")}
                            </p>

                            <button className="botao-grid" onClick={handleDelete}>
                                {t("Sim")}
                            </button>
                            <button className="botao-grid" onClick={handleCloseModalDelete}>
                                {t("Não")}
                            </button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </Col>
    );
}

export default GridPainelSocial;

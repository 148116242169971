import React, { useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { useEffect } from "react";
import { ClearSessionStorage } from "../../config/api";
import { useNavigate } from "react-router-dom";

function Indicadores() {
    const navegar = useNavigate();
    const { t } = useTranslation();
    const [activeDashboard, setActiveDashboard] =
        useState<string>("painelSocial");

    sessionStorage.setItem("portalLogado", "portalIndicadores");

    useEffect(() => {
        const expirationUsuario = sessionStorage.getItem("expirationUsuario");
        if (!expirationUsuario || new Date() > new Date(expirationUsuario)) {
            ClearSessionStorage();
            navegar("/login");
        }
    }, []);

    const handleDashboardChange = (dashboard: string) => {
        setActiveDashboard(dashboard);
    };

    function getDashboardId(dashboard: string): string {
        const dashboardIds: Record<string, string> = {
            painelSocial:
                "eyJrIjoiYjhhMTczMGMtNTMwNi00ZTYzLThkZjQtMDBjMWFhOGQ0ZDc1IiwidCI6ImJhMzg5NTgyLTA0M2ItNDBhYS04ZWJlLWNmYjBhZTI4MjgzMyJ9",
            atendimentos:
                "eyJrIjoiNDYyYjJiZjUtNGQ0OS00NzQ0LTk2NDItZWRjZDZkM2VkOTg3IiwidCI6ImJhMzg5NTgyLTA0M2ItNDBhYS04ZWJlLWNmYjBhZTI4MjgzMyJ9",
            engajamentos:
                "eyJrIjoiMTU1YThjMWEtZmNlZS00OWNmLTg1ZDgtNjc4NDQzYWMwYTA3IiwidCI6ImJhMzg5NTgyLTA0M2ItNDBhYS04ZWJlLWNmYjBhZTI4MjgzMyJ9",
            governamental:
                "eyJrIjoiM2NmNjk1OTEtNmM1MC00NjJjLTlkNTktNmNjNWJhNmIwYWM5IiwidCI6ImJhMzg5NTgyLTA0M2ItNDBhYS04ZWJlLWNmYjBhZTI4MjgzMyJ9",
            instituto:
                "eyJrIjoiMjFiODAzNDItMTU4ZC00ZjEyLWJlNDUtNWNhMTY1YTU5YTlkIiwidCI6ImJhMzg5NTgyLTA0M2ItNDBhYS04ZWJlLWNmYjBhZTI4MjgzMyJ9",
        };

        return dashboardIds[dashboard] || "";
    }

    return (
        <Col xs={12} id="indicadores">
            <Row>
                <Col sm={3}>
                    <div className="dashboard-buttons mt-3">
                        <button
                            className={`dashboard-button ${
                                activeDashboard === "painelSocial"
                                    ? "active"
                                    : ""
                            }`}
                            onClick={() =>
                                handleDashboardChange("painelSocial")
                            }
                        >
                            {t("Painel Social")}
                        </button>
                        <button
                            className={`dashboard-button ${
                                activeDashboard === "atendimentos"
                                    ? "active"
                                    : ""
                            }`}
                            onClick={() =>
                                handleDashboardChange("atendimentos")
                            }
                        >
                            {t("Comunidades")}
                        </button>
                        <button
                            className={`dashboard-button ${
                                activeDashboard === "engajamentos"
                                    ? "active"
                                    : ""
                            }`}
                            onClick={() =>
                                handleDashboardChange("engajamentos")
                            }
                        >
                            {t("Institucional")}
                        </button>
                        <button
                            className={`dashboard-button ${
                                activeDashboard === "governamental"
                                    ? "active"
                                    : ""
                            }`}
                            onClick={() =>
                                handleDashboardChange("governamental")
                            }
                        >
                            {t("Governamental")}
                        </button>
                        <button
                            className={`dashboard-button ${
                                activeDashboard === "instituto" ? "active" : ""
                            }`}
                            onClick={() => handleDashboardChange("instituto")}
                        >
                            {t("Desenvolvimento Social")}
                        </button>
                    </div>
                </Col>

                <Col sm={9} className="dashboard-indicadores">
                    <Container className="container-indicadores mb-3">
                        <iframe
                            title="Report Section"
                            src={`https://app.powerbi.com/view?r=${getDashboardId(
                                activeDashboard
                            )}`}
                            className="dashboard-instituto mb-5"
                        ></iframe>
                    </Container>
                </Col>
            </Row>
        </Col>
    );
}

export default Indicadores;

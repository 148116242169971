import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { BsCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import Api, { linkBackend } from "../../../../config/api";
import { useNavigate } from "react-router-dom";
import { Modal, Col, Row, Form, Card } from "react-bootstrap";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import DateRangeInput from "../../../../utils/DateRangeInput";
import { DateRange } from "react-day-picker";
import { convertDataProFusoCorreto } from "../../../../utils/datesUtils";


const UNDEFINED_COLOR = process.env.REACT_APP_UNDEFINED_COLOR;
const GREEN_COLOR = process.env.REACT_APP_GREEN_COLOR;

function GridOficios() {
  const { t } = useTranslation();
  const navegar = useNavigate();

  const [datagridRows, setDatagridRows] = useState<any[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openModalFiltro, setOpenModalFiltro] = useState(false);

  const [caminhoOficioEntrada, setCaminhoOficioEntrada] = useState<any>();
  const [caminhoOficioResposta, setCaminhoOficioResposta] = useState<any>();
  const [IdDemandaExterna, setIdDemandaExterna] = useState<any>();
  const [IdDemandaInterna, setIdDemandaInterna] = useState<any>();
  const [assunto, setAssunto] = useState<any>();
  const [data, setData] = useState<any>();
  const [estado, setEstado] = useState<any>();
  const [municipio, setMunicipio] = useState<any>();
  const [origem, setOrigem] = useState<any>();
  const [idOficio, setIdOficio] = useState<any>();

  

  const [opemModalOficios, setOpemModalOficios] = useState<boolean>(false);

  const handleOpemModalOficios = () => {
    setOpemModalOficios(true);
  };

  const handleCloseModalOficios = () => {
    setOpemModalOficios(false);
  };
  const updateDatagrid = useCallback(() => {
    if (!navegar || !t) return;

    Api.GetAllOficios()
      .then((data: any[]) => {
        // Mapeia os dados e converte para o formato desejado
        const mappedData = data.map((d: any, index: number) => {
          const dataEntradaAjustada = d.data
            ? convertDataProFusoCorreto(d.data)
            : null;

          return {
            ...d,
            data: dataEntradaAjustada,
          };
        });

        const sortedData = mappedData.sort((a, b) => b.id - a.id);
        setDatagridRows(sortedData);
      })
      .catch((err: any) => {
        if (typeof err === "string") {
          toast.error(t(err));
          navegar("/login");
          return;
        }

        toast.error(t("Houve um erro ao carregar os Ofícios"));
      });
  }, [navegar, t]);

  useEffect(updateDatagrid, [updateDatagrid]);

  const handleRowClick = (params: any) => {
    setSelectedRowData(params.row);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function formatDate(dateString: string | Date) {
    const dateOri = new Date(dateString);

    const date = new Date(
      dateOri.setHours(dateOri.getHours() + dateOri.getTimezoneOffset() / 60)
    );
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().padStart(4, "0");

    if (year === "1970") return "";
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    if (selectedRowData) {
      setIdOficio(selectedRowData.id);
      setCaminhoOficioEntrada(selectedRowData.caminho_oficio_entrada || "");
      setCaminhoOficioResposta(selectedRowData.caminho_oficio_resposta || "");
      setIdDemandaExterna(selectedRowData.id_demanda_externa || "");
      setIdDemandaInterna(selectedRowData.id_demanda_interna || "");
      setAssunto(selectedRowData.assunto || "");
      setData(formatDate(selectedRowData.data) || "");
      setEstado(selectedRowData.estado || "");
      setMunicipio(selectedRowData.municipio || "");
      setOrigem(selectedRowData.origem || "");
    }
  }, [selectedRowData]);

  const [filtroOrigem, setFiltroOrigem] = useState<string>();
  const [filtroEstado, setFiltroEstado] = useState<string>();
  const [filtroMunicipio, setFiltroMunicipio] = useState<string>();
  const [filtroTipoDemanda, setFiltroTipoDemanda] = useState<string>();
  const [filtroDataEntrada, setFiltroDataEntrada] = useState<DateRange>();

  const [showPickerData, setShowPickerData] = useState<string>();

  interface MunicipiosPorEstado {
    [estado: string]: string[];
  }
  const municipiosPorEstado: MunicipiosPorEstado = {};

  const [estadosExistentes, municipiosExistentes] = useMemo(() => {
    const estados: string[] = [];
    const municipios: string[] = [];

    datagridRows.forEach((r) => {
      r.estado && !estados.includes(r.estado) && estados.push(r.estado);
      r.municipio &&
        !municipios.includes(r.municipio) &&
        municipios.push(r.municipio);
    });

    return [
      estados.sort((a, b) => a.localeCompare(b)),
      municipios.sort((a, b) => a.localeCompare(b)),
    ];
  }, [datagridRows]);

  datagridRows.forEach((r) => {
    if (r.estado && r.municipio) {
      if (!municipiosPorEstado[r.estado]) {
        municipiosPorEstado[r.estado] = [];
      }

      if (!municipiosPorEstado[r.estado].includes(r.municipio)) {
        municipiosPorEstado[r.estado].push(r.municipio);
      }
    }
  });

  const municipiosFiltrados = useMemo(() => {
    return filtroEstado
      ? municipiosPorEstado[filtroEstado] || []
      : municipiosExistentes;
  }, [filtroEstado, municipiosPorEstado, municipiosExistentes]);

  const datagridColunas: readonly GridColDef<any>[] = [
    {
      field: "data",
      headerName: t("Data") as string,
      type: "date",
      width: 150,
      valueGetter: ({ row }: { row: any }) => {
        if (!row.data) {
          return null;
        }
        return new Date(row.data);
      },
      renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
        return params.value && params.value instanceof Date
          ? format(params.value, "dd/MM/yyyy", { locale: ptBR })
          : "";
      },
    },
    {
      field: "estado",
      headerName: t("header_Estado") as string,
      type: "select",
      width: 120,
    },
    {
      field: "municipio",
      headerName: t("header_Municipio") as string,
      type: "string",
      width: 120,
    },
    {
      field: "origem",
      headerName: t("Origem") as string,
      type: "string",
      width: 150,
    },
    {
      field: "assunto",
      headerName: t("Assunto") as string,
      type: "string",
      width: 150,
    },
    {
      field: "id_demanda_externa",
      headerName: t("Demanda Externa") as string,
      type: "string",
      width: 150,
      renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
        let icon;
        if (typeof params.value === "number") {
          icon = GREEN_COLOR;
        } else {
          icon = UNDEFINED_COLOR;
        }
        return (
          <span>
            <BsCircleFill color={icon} />
          </span>
        );
      },
    },
    {
      field: "id_demanda_interna",
      headerName: t("Demanda Interna") as string,
      type: "string",
      width: 150,
      renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
        let icon;
        if (typeof params.value === "number") {
          icon = GREEN_COLOR;
        } else {
          icon = UNDEFINED_COLOR;
        }
        return (
          <span>
            <BsCircleFill color={icon} />
          </span>
        );
      },
    },
  ];

  const tiposFiltro = [
    {
      value: "Data de Entrada",
      label: t("Data"),
      type: "date",
      filter: filtroDataEntrada,
      setFilter: setFiltroDataEntrada,
    },
    {
      value: "Origem",
      label: t("Origem"),
      type: "select",
      opts: [
        {
          label: t("Poder Público Municipal - Prefeitura"),
          value: "Poder Público Municipal - Prefeitura",
        },
        {
          label: t("Poder Público Municipal - Secretaria"),
          value: "Poder Público Municipal - Secretaria",
        },
        {
          label: t("Poder Público Municipal - Autarquia"),
          value: "Poder Público Municipal - Autarquia",
        },
        {
          label: t("Poder Público Municipal - Câmara de Vereadores"),
          value: "Poder Público Municipal - Câmara de Vereadores",
        },
        { label: t("Poder Público Estadual"), value: "Poder Público Estadual" },
        { label: t("Poder Público Federal"), value: "Poder Público Federal" },
        { label: t("Ministério Público"), value: "Ministério Público" },
        { label: t("Entidades Setoriais"), value: "Entidades Setoriais" },
        {
          label: t("Entidades do Terceiro Setor"),
          value: "Entidades do Terceiro Setor",
        },
        {
          label: t("Setor Privado"),
          value: "Setor Privado",
        },
        { label: t("Academia"), value: "Academia" },
        {
          label: t("Operações"),
          value: "Operações",
        },
        {
          label: t("Projetos"),
          value: "Projetos",
        },
        {
          label: t("EHS"),
          value: "EHS",
        },
        {
          label: t("Juridico"),
          value: "Juridico",
        },
        {
          label: t("Tributário"),
          value: "Tributário",
        },
        {
          label: t("Financeiro"),
          value: "Financeiro",
        },
        {
          label: t("CSC"),
          value: "CSC",
        },
        {
          label: t("RH"),
          value: "RH",
        },
        {
          label: t("Comunicação"),
          value: "Comunicação",
        },
        {
          label: t("Relações Institucionais"),
          value: "Relações Institucionais",
        },
        {
          label: t("Instituto Mosaic"),
          value: "Instituto Mosaic",
        },
        {
          label: t("Relações com Comunidades"),
          value: "Relações com Comunidades",
        },
        {
          label: t("Relações Governamentais"),
          value: "Relações Governamentais",
        },
      ],
      filter: filtroOrigem,
      setFilter: setFiltroOrigem,
    },
    {
      value: "Estado",
      label: t("Estado"),
      type: "select",
      opts: estadosExistentes,
      filter: filtroEstado,
      setFilter: setFiltroEstado,
    },
    {
      value: "Municipio",
      label: t("Municipio"),
      type: "select",
      opts: municipiosFiltrados,
      filter: filtroMunicipio,
      setFilter: setFiltroMunicipio,
    },
    {
      value: "Demanda",
      label: t("Tipo Demanda"),
      type: "select",
      opts: [
        {
          label: t("Externa"),
          value: "Externa",
        },
        {
          label: t("Interna"),
          value: "Interna",
        },
      ],
      filter: filtroTipoDemanda,
      setFilter: setFiltroTipoDemanda,
    },
  ];

  const [filtrosAtivos, setFiltroAtivo] = useState<string[]>([]);

  const dadosFiltrados = useMemo(
    () =>
      datagridRows.filter(
        (v) =>
          (!filtroOrigem ||
            v?.origem?.toLowerCase().includes(filtroOrigem?.toLowerCase())) &&
          (!filtroEstado ||
            v.estado?.toLowerCase().includes(filtroEstado?.toLowerCase())) &&
          (!filtroMunicipio ||
            v.municipio
              ?.toLowerCase()
              .includes(filtroMunicipio?.toLowerCase())) &&
          (!filtroDataEntrada ||
            (v.data >= (filtroDataEntrada.from || new Date()) &&
              v.data <=
              (filtroDataEntrada.to
                ? filtroDataEntrada.to
                : filtroDataEntrada.from || new Date()))) &&
          (!filtroTipoDemanda ||
            (filtroTipoDemanda.toLowerCase() === "externa" &&
              v.id_demanda_externa) ||
            (filtroTipoDemanda.toLowerCase() === "interna" &&
              v.id_demanda_interna))
      ),
    [
      datagridRows,
      filtroOrigem,
      filtroEstado,
      filtroMunicipio,
      filtroDataEntrada,
      filtroTipoDemanda,
    ]
  );

  const getRangeText = (range: DateRange): string => {
    if (!range?.from) return "";

    return (
      format(range.from, "dd/MM/yyyy", { locale: ptBR }) +
      " à " +
      format(range.to || range.from, "dd/MM/yyyy", { locale: ptBR })
    );
  };

  const handleModalFiltros = (filtro: string) => {
    setFiltroAtivo((filtros) => {
      const newFiltros = [...filtros];
      const fIndex = newFiltros.findIndex((f) => f === filtro);
      if (fIndex >= 0) {
        newFiltros.splice(fIndex, 1);
        tiposFiltro.find((f) => f.value === filtro)?.setFilter(undefined);
      } else {
        newFiltros.push(filtro);
      }
      return newFiltros;
    });
    setOpenModalFiltro(true);
  };

  const ExibirImagem = ({ imageUrl }: { imageUrl: string }) => {
    const handleViewImage = () => {
      const overlayElement = document.createElement("div");
      overlayElement.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
      `;

      let imgElement: HTMLImageElement | null = document.createElement("img");

      imgElement.onload = () => {
        if (imgElement && imgElement.complete) {
          overlayElement.appendChild(imgElement);
        } else {
          showNoImageMessage();
        }
      };

      imgElement.onerror = () => {
        showNoImageMessage();
      };

      const formattedImageUrl = imageUrl && imageUrl.replace(/\\/g, "/");
      const linkConcat = `${linkBackend}${formattedImageUrl}`;
      const linkCompleto = linkConcat.replace(/\/\/public/, "/public");
      imgElement.src = linkCompleto;
      imgElement.alt = "Imagem";
      imgElement.style.cssText = `
        max-height: 80vh;
        max-width: 100%;
      `;

      const showNoImageMessage = () => {
        const noImageMessage = document.createElement("div");
        noImageMessage.textContent = "Nenhuma imagem disponível";
        noImageMessage.style.cssText = `
          color: white;
          font-size: 20px;
        `;

        overlayElement.appendChild(noImageMessage);
      };

      overlayElement.addEventListener("click", () => {
        document.body.removeChild(overlayElement);
      });

      document.body.appendChild(overlayElement);
    };

    return (
      <Col sm={12}>
        <Col sm={12}>
          <button
            onClick={handleViewImage}
            className="btn btn-primary btn-sm"
            style={{ width: "100%" }}
            type="button"
          >
            {t("Visualizar Foto")}
          </button>
        </Col>
      </Col>
    );
  };

  const ExibirPDF = ({ pdfPath }: { pdfPath: string }) => {
    const handleViewPDF = () => {
      const pdfLink = getPdfLink(pdfPath);

      if (pdfLink) {
        window.open(pdfLink, "_blank");
      } else {
        toast.error("Não há PDF disponível.");
      }
    };

    const getPdfLink = (pdfPath: string): string | null => {
      if (pdfPath) {
        const formattedPdf = pdfPath && pdfPath.replace(/\\/g, "/");
        const linkConcat = `${linkBackend}${formattedPdf}`;
        const linkCompleto = linkConcat.replace(/\/\/public/, "/public");

        return linkCompleto;
      } else {
        return null;
      }
    };

    return (
      <Col sm={12}>
        <Col sm={12}>
          <button
            onClick={handleViewPDF}
            className="btn btn-primary btn-sm"
            style={{ width: "100%" }}
            type="button"
          >
            {t("Visualizar PDF")}
          </button>
        </Col>
      </Col>
    );
  };

  const handleDeleteOficio = async () => {
    try {
      await Api.DeleteOficio(idOficio);
      toast.success("Oficio deletado com sucesso!");
      closeModal();
      updateDatagrid();
    } catch (err) {
      toast.error("Erro ao deletar Oficio!");
    }
  }

  return (
    <Col sm={12}>
      <div className="grid-dados-relacionamento">
        <Col sm={12}>
          <Row>
            <Col sm={9}>
              {filtrosAtivos.map((filtro, i) => {
                const filterObj = tiposFiltro.find(
                  ({ value }: { value: any }) => value === filtro
                );
                if (!filterObj) return null;

                const { filter, setFilter, type, opts } = filterObj;

                return (
                  <Col className="filtro-ativo" key={i}>
                    <Row>
                      <Col sm={9}>
                        {type === "date" ? (
                          <>
                            <button
                              onClick={() => setShowPickerData(filtro)}
                              style={{
                                border: 0,
                                outline: 0,
                                backgroundColor: "#0000",
                                display: "block",
                                width: "100%",
                                height: "100%",
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              <Form.Control
                                type="text"
                                placeholder={`Filtrar ${filtro}`}
                                value={getRangeText(filter as any)}
                                style={{ height: "73%" }}
                                onFocus={(e) => e.target.blur()}
                                readOnly
                              />
                            </button>
                            {showPickerData === filtro && (
                              <DateRangeInput
                                selected={filter as any}
                                onChange={setFilter}
                                onFinish={() => setShowPickerData(undefined)}
                              />
                            )}
                          </>
                        ) : type === "select" ? (
                          <Form.Select
                            value={filter?.toString() || ""}
                            onChange={(e) => {
                              setFilter(e.target.value as any);
                            }}
                            style={{ height: "73%" }}
                          >
                            <option label={`Filtrar ${filtro}`} value={""} />
                            {opts?.map((opt, i) => {
                              const isString = typeof opt === "string";

                              return (
                                <option
                                  key={i}
                                  label={isString ? opt : opt.label}
                                  value={isString ? opt : opt.value}
                                />
                              );
                            })}
                          </Form.Select>
                        ) : (
                          <Form.Control
                            type="text"
                            placeholder={`Filtrar ${filtro}`}
                            value={filter?.toString() || ""}
                            onChange={(e) => {
                              setFilter(e.target.value as any);
                            }}
                            style={{ height: "73%" }}
                          />
                        )}
                      </Col>
                      <Col sm={3}>
                        <button
                          onClick={() => {
                            setFilter(undefined);
                          }}
                          className="botao-filtro mt-2"
                        >
                          {t("Limpar Filtro")}
                        </button>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Col>
            <Col sm={3}>
              <button
                className="botao-filtro mt-2"
                onClick={() => setOpenModalFiltro(!openModalFiltro)}
              >
                {t("Filtros")}
              </button>
            </Col>
          </Row>

          {openModalFiltro && (
            <Col sm={12} className="mb-2">
              <Card>
                <Card.Body>
                  <Col sm={12} className="justify-content-center">
                    <Row>
                      {tiposFiltro.map((filtro, i) => (
                        <Col sm={3} key={i}>
                          <button
                            className={`filtros-modal ${filtrosAtivos?.includes(filtro.value)
                                ? "ativo"
                                : ""
                              }`}
                            onClick={() => {
                              handleModalFiltros(filtro.value);
                            }}
                          >
                            {filtro.label}
                          </button>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Col>

        <div>
          <DataGrid
            rows={dadosFiltrados}
            columns={datagridColunas}
            onRowClick={handleRowClick}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
          />
        </div>
      </div>

      {/* MODAL OFICIOS */}
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        centered={true}
        size="xl"
        dialogClassName="modal-rollover"
      >
        <Modal.Header closeButton>
          <h3>
            {IdDemandaExterna
              ? "Oficio Demanda Externa"
              : "Oficio Demanda Interna"}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <Row sm={12}>
            <Col sm={4}>
              <Form.Label htmlFor="data-entrada">
                {t("Data de entrada")}
              </Form.Label>
              <Form.Control
                className="formInput"
                type="date"
                value={data}
                onChange={(e) => {
                  setData(e.target.value);
                }}
              />
            </Col>
            <Col sm={4}>
              <Form.Label>{t("Estado")}</Form.Label>
              <Form.Control
                className="formInput"
                type="text"
                value={estado}
                onChange={(e) => {
                  setEstado(e.target.value);
                }}
              />
            </Col>

            <Col sm={4}>
              <Form.Label>{t("Municipio")}</Form.Label>
              <Form.Control
                className="formInput"
                type="text"
                value={municipio}
                onChange={(e) => {
                  setMunicipio(e.target.value);
                }}
              />
            </Col>
          </Row>

          <Row sm={12} className="mt-3">
            <Col sm={6}>
              <Form.Label>{t("Origem")}</Form.Label>
              <Form.Control
                className="formInput"
                type="text"
                value={origem}
                onChange={(e) => {
                  setOrigem(e.target.value);
                }}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>{t("Assunto")}</Form.Label>
              <Form.Control
                className="formInput"
                type="text"
                value={assunto}
                onChange={(e) => {
                  setEstado(e.target.value);
                }}
              />
            </Col>
          </Row>

          <Row sm={12} className="mt-4">
            <Col sm={6}>
              <Form.Label>{t("Ofício de Entrada:")}</Form.Label>
              {/* <Form.Control
                  className="inputFoto"
                  type="file"
                  // value={CaminhoOficioEntrada}
                  onChange={(e) => {
                    setCaminhoOficioEntrada(e.target.value);
                  }}
                /> */}
            </Col>
            <Col sm={6}>
              <Form.Label>{t("Ofício de Resposta:")}</Form.Label>
              {/* <Form.Control
                  className="inputFoto"
                  type="file"
                  // value={CaminhoOficioResposta}
                  onChange={(e) => {
                    setCaminhoOficioResposta(e.target.value);
                  }}
                /> */}
            </Col>
          </Row>

          <Row sm={12}>
            <Col sm={6}>
              {caminhoOficioEntrada?.slice(-3) === "pdf" ? (
                <ExibirPDF pdfPath={caminhoOficioEntrada} />
              ) : (
                <ExibirImagem imageUrl={caminhoOficioEntrada} />
              )}
            </Col>
            <Col sm={6}>
              {caminhoOficioResposta?.slice(-3) === "pdf" ? (
                <ExibirPDF pdfPath={caminhoOficioResposta} />
              ) : (
                <ExibirImagem imageUrl={caminhoOficioResposta} />
              )}
            </Col>
          </Row>

          <Row sm={12} className="mt-3">
            <Col sm={12}>
              <button
                className="btn btn-danger"
                onClick={() => {
                  handleDeleteOficio();
                }}
              >
                Excluir Ofício
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Col>
  );
}

export default GridOficios;
